import { SupportedChains } from './types';

export const chainOptions = [
  {
    label: 'Solana',
    value: SupportedChains.SOLANA,
    nativeToken: 'SOL',
    nativeAddress: 'So11111111111111111111111111111111111111112'
  },
  {
    label: 'Ethereum',
    value: SupportedChains.ETHEREUM,
    nativeToken: 'ETH',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'Polygon',
    value: SupportedChains.POLYGON,
    nativeToken: 'MATIC',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'Binance',
    value: SupportedChains.BINANCE,
    nativeToken: 'BNB',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'Avalanche',
    value: SupportedChains.AVALANCHE,
    nativeToken: 'AVAX',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'Arbitrum',
    value: SupportedChains.ARBITRUM,
    nativeToken: 'ETH',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'Optimism',
    value: SupportedChains.OPTIMISM,
    nativeToken: 'ETH',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'Immutable',
    value: SupportedChains.IMMUTABLE,
    nativeToken: 'ETH',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'EosEvm',
    value: SupportedChains.EOSEVM,
    nativeToken: 'EOS',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'Base',
    value: SupportedChains.BASE,
    nativeToken: 'ETH',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'Flow',
    value: SupportedChains.FLOW,
    nativeToken: 'FLOW',
    nativeAddress: '0x1654653399040a61'
  },
  {
    label: 'Klaytn',
    value: SupportedChains.KLAYTN,
    nativeToken: 'KLAY',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'DFK',
    value: SupportedChains.DFK,
    nativeToken: 'JEWEL',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'Bitcoin',
    value: SupportedChains.BITCOIN,
    nativeToken: 'BTC',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'DOS',
    value: SupportedChains.DOS,
    nativeToken: 'DOS',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'SUI',
    value: SupportedChains.SUI,
    nativeToken: 'SUI',
    nativeAddress: '0x2'
  },
  {
    label: 'Skale (Europa)',
    value: SupportedChains.SKALE_EUROPA,
    nativeToken: 'sFUEL',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  },
  {
    label: 'Skale (Nebula)',
    value: SupportedChains.SKALE_NEBULA,
    nativeToken: 'sFUEL',
    nativeAddress: '0x0000000000000000000000000000000000000000'
  }
];
