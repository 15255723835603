import React, { ReactElement, useState, ChangeEvent, FormEvent } from 'react';
import { Button, Label, Tooltip } from 'flowbite-react';
import { toastError, toastSuccess } from '../../../utils/toastify';
import { CameraIcon, TrashIcon } from '@heroicons/react/20/solid';
import { useAppSelector } from '../../../redux/hooks';
import i18n from '../../../config/languageInternationalization';

export default function Profile(): ReactElement {
  const { user } = useAppSelector((state) => state);

  const [profileState, setProfileState] = useState({
    image: user.profilePicture
      ? user.profilePicture
      : 'https://via.placeholder.com/208x208',
    name: user.name ? user.name : 'Your name',
    email: user.email ? user.email : 'email@example.com',
    password: ''
  });
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfileState((prevProfileState) => ({
      ...prevProfileState,
      [name]: value
    }));
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      if (!file.type.startsWith('image/')) {
        return toastError(`${i18n.t('invalidImageFileType')}`);
      }

      const maxSize = 12 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        return toastError(`${i18n.t('imageSizeExceeded')}`);
      }

      setSelectedImage(file);
    }
  };

  const handleDeletePhoto = () => {
    if (selectedImage) return setSelectedImage(null);

    if (!selectedImage) {
      return toastError(`${i18n.t('noImageToRemove')}`);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return toastSuccess(`${i18n.t('changesSaved')}`);
  };

  return (
    <div className="bg-gray-800 rounded-lg mt-8 p-8 flex flex-col gap-6 justify-center align-middle items-center max-w-lg">
      {/* Img section */}
      <div className="flex flex-1 w-full h-52 flex-row gap-4">
        <img
          className="w-52 h-52 rounded-lg select-none"
          src={
            selectedImage
              ? URL.createObjectURL(selectedImage)
              : profileState.image
          }
          alt="Profile"
        />
        {/* buttons */}
        <div className="flex flex-1 flex-col gap-4">
          {/* add new photo */}
          <label
            htmlFor="profilePictureInput"
            className="w-full h-10 bg-transparent rounded-lg border border-gray-600 justify-center align-middle items-center flex gap-2 cursor-pointer"
          >
            <CameraIcon className="w-4 text-gray-400" />
            <p className="text-gray-400">{i18n.t('addNewPhoto')}</p>
          </label>
          <input
            type="file"
            accept="image/*"
            id="profilePictureInput"
            className="hidden"
            onChange={handleImageChange}
          />
          {/* Delete this photo */}
          <button
            onClick={handleDeletePhoto}
            className="w-full h-10 bg-transparent rounded-lg border border-gray-600 justify-center align-middle items-center flex gap-2"
          >
            <TrashIcon className="w-4 text-gray-400" />
            <p className="text-gray-400">{i18n.t('deletePhoto')}</p>
          </button>
        </div>
      </div>

      <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit}>
        {/* Name */}
        <div>
          <div className="mb-2 block ">
            <Label
              htmlFor="name1"
              value="Your name"
              className="text-white font-normal"
            />
          </div>
          <input
            name="name"
            className="bg-gray-800 text-white w-full p-2 rounded-lg"
            id="name1"
            type="text"
            placeholder={profileState.name}
            value={profileState.name}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Email */}
        <div>
          <div className="mb-2 block ">
            <Label
              htmlFor="email1"
              value="Your email"
              className="text-white font-normal"
            />
          </div>
          <input
            name="email"
            id="email1"
            type="email"
            placeholder={user.email ? user.email : 'email@example.com'}
            required
            className="bg-gray-800 text-white w-full p-2 rounded-lg"
            value={profileState.email}
            onChange={handleInputChange}
          />
        </div>

        {/* Button */}
        <div className="flex items-center gap-2"></div>
        <Button className="bg-blue-600" type="submit" disabled>
          <Tooltip content="Coming soon">Update</Tooltip>
        </Button>
      </form>
    </div>
  );
}
