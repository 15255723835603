import SphereOneLogoFull from './Sphere.svg';
import SphereIsotype from './SphereIsotype.svg';
import SphereOneLogoWhiteWithLetters from './SphereOneLogoWhiteWithLetters.png';
import LogoWhite from './LogoWhite.svg';

export default {
  SphereOneLogoFull,
  SphereIsotype,
  SphereOneLogoWhiteWithLetters,
  LogoWhite
};
