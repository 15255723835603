import React, { useEffect, useState } from 'react';
import MenuItem from './MenuItem';
import { Link } from 'react-router-dom';
import Button from '../ThemedComponents/Buttons/button';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setTestEnvironment } from '../../redux/slices/user';
import i18n from '../../config/languageInternationalization';
import { useWindowSize } from '../../hooks/useWindowsSize';
import { getUserBalances } from '../../redux/slices/userThunk';
const Sidebar = () => {
  const actualUrl: string = window.location.href;
  const sectionName = actualUrl.split('/')[3];
  const open = useAppSelector((state) => state.user.isSidebarOpen);
  const { testEnvironment, totalTestBalance, balanceTokensTestnet } =
    useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [active, setActive] = useState<string>(sectionName);
  const [width, height] = useWindowSize();

  const isSmallHeight = height < 800 || (width && width < 800);
  const margin = isSmallHeight ? '0.5rem' : '1.5rem';

  useEffect(() => {
    setActive(sectionName);
  }, [actualUrl]);

  const hideText = `${
    !open && 'opacity-0 -translate-x-56 overflow-hidden'
  } duration-1000 `;

  return (
    <aside
      className={`fixed top-0 left-0 z-40 h-screen pt-16 bg-gray-800 border-r border-r-gray-700 ${
        open ? 'w-64' : 'w-16'
      }`}
      data-test-id="side-bar"
    >
      <div className="flex flex-col h-full mb-4 py-4 px-3">
        <div className="flex h-full flex-col">
          <MenuItem
            margin={margin}
            active={active}
            setActive={setActive}
            open={open}
            hideText={hideText}
            isSmallHeight={isSmallHeight}
          />

          <div
            className={`relative sm:mb-0 ${isSmallHeight ? 'my-2' : 'my-6'}`}
          >
            <Button
              className={`w-[90%] my-2 ${
                !open && 'h-10 scale-75 -translate-x-2'
              } mr-4 transition-all duration-500 flex justify-center rounded-md bg-primary-700 ${
                testEnvironment && ' bg-red-800 hover:bg-red-700'
              } `}
              onClick={() => {
                dispatch(setTestEnvironment(!testEnvironment));
                if (
                  (!testEnvironment && !totalTestBalance) ||
                  !balanceTokensTestnet
                ) {
                  dispatch(
                    getUserBalances({ refreshCache: false, testnet: true })
                  );
                }
              }}
            >
              <span
                className={`align-middle relative ${
                  isSmallHeight ? 'text-xs' : 'text-sm'
                } flex items-center text-center align-middle self-center place-self-center justify-items-center`}
              >
                {open
                  ? !testEnvironment
                    ? i18n.t('enableTestMode')
                    : i18n.t('inTestMode')
                  : 'T'}
              </span>
            </Button>
            {testEnvironment && (
              <Link to="/createCharge">
                <Button
                  id="LinkCreateCharge"
                  className={`w-[90%] my-2 bg-primary-700 rounded-md ${
                    !open && 'h-10 scale-75 -translate-x-2'
                  } mr-4 transition-all duration-500 flex justify-center`}
                >
                  <span
                    className={`align-middle relative ${
                      isSmallHeight ? 'text-xs' : 'text-sm'
                    } flex items-center text-center align-middle self-center place-self-center justify-items-center`}
                  >
                    {open ? i18n.t('createCharge') : 'C'}
                  </span>
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
