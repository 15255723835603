import React from 'react';

import i18n from '../../config/languageInternationalization';
// ICONS
import {
  ChartPieIcon,
  CreditCardIcon,
  WalletIcon,
  UserGroupIcon,
  PaintBrushIcon,
  GiftIcon,
  Cog8ToothIcon,
  PhotoIcon
} from '@heroicons/react/20/solid';

interface Item {
  title?: string;
  icon?: React.ReactElement;
  link?: string;
  comingSoon?: boolean;
  separation?: boolean;
}

export const menuItem: Item[] = [
  { title: i18n.t('overview'), icon: <ChartPieIcon />, link: '/overview' },
  { title: i18n.t('payments'), icon: <CreditCardIcon />, link: '/payments' },
  { title: i18n.t('balances'), icon: <WalletIcon />, link: '/balances' },
  { title: i18n.t('customers'), icon: <UserGroupIcon />, link: '/customers' },
  { title: i18n.t('theme'), icon: <PaintBrushIcon />, link: '/theme' },
  {
    title: i18n.t('nftRewards'),
    icon: <PhotoIcon />,
    // link: "/rewards",
    comingSoon: true
  },
  {
    title: i18n.t('tokenLoyalty'),
    icon: <GiftIcon />,
    // link: "/loyalty",
    comingSoon: true
  },
  {
    separation: true
  },
  {
    title: i18n.t('settings'),
    icon: <Cog8ToothIcon />,
    link: '/settings'
  }
];
