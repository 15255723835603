import React, { useState } from 'react';
import i18n from '../config/languageInternationalization';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setIsPhoneAdded, setIsPinCodeSetup } from '../redux/slices/user';
import handlePhoneNumberChange from '../utils/formatePhoneNumber';
import { callFunction } from '../utils/server';
import AddPinCodeScreen, {
  PinCodePrimaryButton,
  PinCodeSecondaryButton,
  PinCodeSubtitle
} from './AddPinCode';
import { GreenCheckCircleIcon, PinCodeState } from './InsertPinCode';
import { Form, PasswordInput, Title } from './ThemedComponents/Flowbite/Form';

enum RecoveryState {
  SUCCESS_SMS,
  ADD_NEW_PIN_CODE
}

type RecoveryFlowParams = {
  phoneNumber: string;
  callback: (wrappedDek: string) => Promise<void>;
  target: string;
  closeModal: () => void;
  errorHandler?: (error: string) => void;
};

const RecoveryFlowPinCode: React.FC<RecoveryFlowParams> = ({
  phoneNumber,
  callback,
  target,
  closeModal,
  errorHandler
}: RecoveryFlowParams): React.ReactElement => {
  const [state, setState] = useState<PinCodeState | RecoveryState>(
    PinCodeState.INSERT_CODE
  );
  const [pinCode, setPinCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { isPhoneAdded, accessToken, isPinCodeSetup } = useAppSelector(
    (state) => state.user
  );
  const dispatch = useAppDispatch();

  if (state === PinCodeState.INSERT_CODE && !isPhoneAdded) {
    return (
      <Form
        title={i18n.t('confirmSMS')}
        handleSubmit={async (e) => {
          e.preventDefault();
          try {
            const phoneFormatted = handlePhoneNumberChange(phoneNumber);
            await callFunction({
              url: '/optverifier',
              method: 'POST',
              accessToken: accessToken as string,
              data: { phone: phoneFormatted, otpCode: pinCode }
            });
            setState(RecoveryState.SUCCESS_SMS);
            dispatch(setIsPhoneAdded());
          } catch (e: any) {
            if (e.message === 'otpCode not valid') {
              setError(i18n.t('invalidPinCode'));
            }
          } finally {
            setLoading(false);
          }
        }}
      >
        <PinCodeSubtitle text={i18n.t('insertOtpCodeText')} />
        <PasswordInput
          value={pinCode ?? ''}
          onChange={(e) => {
            e.preventDefault();
            if (
              e?.target?.value?.length <= 6 &&
              !isNaN(Number(e?.target?.value))
            ) {
              setPinCode(e.target.value);
            }
          }}
        />
        {error && <span className="text-red-600 text-center">{error}</span>}
        <PinCodePrimaryButton
          text={i18n.t('continue')}
          disabled={pinCode.length !== 6 || loading}
          isLoading={loading}
        />
        <PinCodeSecondaryButton
          text={i18n.t('back')}
          handleOnClick={() => {
            setState(PinCodeState.INSERT);
          }}
        />
      </Form>
    );
  }
  if (state === PinCodeState.INSERT_CODE && isPhoneAdded) {
    return (
      <Form
        title={i18n.t('confirmSMS')}
        handleSubmit={async (e) => {
          e.preventDefault();
          try {
            const phoneFormatted = handlePhoneNumberChange(phoneNumber);
            await callFunction({
              url: '/user/recovery/pin',
              method: 'POST',
              accessToken: accessToken as string,
              data: { phone: phoneFormatted, otpCode: pinCode }
            });
            setState(RecoveryState.SUCCESS_SMS);
            dispatch(setIsPinCodeSetup(false));
          } catch (e: any) {
            if (e.message === 'otpCode not valid') {
              setError(i18n.t('invalidPinCode'));
            }
          } finally {
            setLoading(false);
          }
        }}
      >
        <PinCodeSubtitle text={i18n.t('insertOtpCodeText')} />
        <PasswordInput
          value={pinCode ?? ''}
          onChange={(e) => {
            e.preventDefault();
            if (
              e?.target?.value?.length <= 6 &&
              !isNaN(Number(e?.target?.value))
            ) {
              setPinCode(e.target.value);
            }
          }}
        />
        {error && <span className="text-red-600 text-center">{error}</span>}
        <PinCodePrimaryButton
          text={i18n.t('continue')}
          disabled={pinCode.length !== 6 || loading}
          isLoading={loading}
        />
        <PinCodeSecondaryButton
          text={i18n.t('back')}
          handleOnClick={() => {
            setState(PinCodeState.INSERT);
          }}
        />
      </Form>
    );
  }
  if (state === RecoveryState.SUCCESS_SMS) {
    return (
      <Form
        style={{ gap: 24 }}
        handleSubmit={(e) => {
          e.preventDefault();
          if (isPinCodeSetup) {
            closeModal && closeModal();
          } else {
            setState(RecoveryState.ADD_NEW_PIN_CODE);
          }
        }}
        title=""
        className="text-left"
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <GreenCheckCircleIcon />
        </div>
        <Title text={i18n.t('pinCodeInsertedOK')} />
        <PinCodePrimaryButton text={i18n.t('continue')} />
      </Form>
    );
  }
  if (state === RecoveryState.ADD_NEW_PIN_CODE) {
    return (
      <AddPinCodeScreen
        isRecoveryFlow={true}
        target={target}
        callback={callback}
        closeModal={closeModal}
        errorHandler={errorHandler}
      />
    );
  } else return <></>;
};

export default RecoveryFlowPinCode;
