import axios from 'axios';

export const serverEndpoint = `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}`;
export const merchantDataEndpoint = `${serverEndpoint}/merchant`;
export const userDataEndpoint = `${serverEndpoint}/user`;

export const updateDocumentData = async ({
  url,
  accessToken,
  updateData,
  idToken
}: {
  url: string;
  accessToken: string;
  updateData: any;
  idToken: string;
}) => {
  const response = await axios.put(url, updateData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-id-token': idToken
    }
  });

  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
};

export const getDocumentData = async ({
  url,
  accessToken,
  idToken,
  searchParams
}: {
  url: string;
  accessToken?: string;
  idToken?: string;
  searchParams?: { [key: string]: any };
}) => {
  // Create a URL object
  const urlObj = new URL(url);

  // Add search parameters if they exist
  if (searchParams) {
    Object.keys(searchParams).forEach((key) => {
      urlObj.searchParams.append(key, String(searchParams[key]));
    });
  }

  const response = await axios.get(urlObj.toString(), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-id-token': idToken
    }
  });
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
};

export const postDocumentData = async ({
  url,
  data,
  accessToken,
  idToken
}: {
  url: string;
  data: { [key: string]: any };
  accessToken: string;
  idToken: string;
}) => {
  const response = await axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-id-token': idToken
    }
  });

  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
};

export const deleteDocumentData = async ({
  url,
  accessToken,
  idToken
}: {
  url: string;
  accessToken: string;
  idToken: string;
}) => {
  const response = await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-id-token': idToken
    }
  });

  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
};

const getOrCreateMerchant = async (accessToken: string, idToken: string) => {
  try {
    const response = await getDocumentData({
      url: merchantDataEndpoint,
      accessToken,
      idToken
    });
    return response.data;
  } catch (e: any) {
    if (e?.response?.data?.error === 'User is not a member of any merchant') {
      const createMerchantResponse = await postDocumentData({
        url: merchantDataEndpoint,
        data: {},
        accessToken,
        idToken
      });
      if (createMerchantResponse?.data?.error) {
        throw new Error('Error creating merchant');
      }
      const merchantResponse = await getDocumentData({
        url: merchantDataEndpoint,
        accessToken,
        idToken
      });
      return merchantResponse.data;
    } else throw e;
  }
};

export const getProfileInfo = async (
  accessToken: string,
  idToken: string
): Promise<any> => {
  try {
    const userResponse = await getDocumentData({
      url: userDataEndpoint,
      accessToken,
      idToken
    });

    const merchantResponse = await getOrCreateMerchant(accessToken, idToken);

    return { userData: userResponse.data, merchantData: merchantResponse };
  } catch (error: any) {
    if (error.message === 'User not found') {
      throw 'User not found';
    } else if (error?.response?.data?.error === 'User not found') {
      throw 'User not found';
    }
  }
};

export const uploadImage = async ({
  accessToken,
  data,
  idToken
}: {
  accessToken: string;
  data: any;
  idToken: string;
}) => {
  const response = await axios.post(`${serverEndpoint}/upload/image`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
      'x-id-token': idToken
    }
  });
  return response.data.data.url as string;
};

export async function getFundsAvailable(
  accessToken: string,
  refreshCache = false,
  idToken: string,
  testnet: boolean
) {
  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${serverEndpoint}/merchant/getFundsAvailable${
      !testnet ? `?refreshCache=${refreshCache}` : `?testnet=${testnet}`
    }`,
    headers: { Authorization: `Bearer ${accessToken}`, 'x-id-token': idToken }
  };
  const res = await axios.request(config);
  return res.data;
}

export const getMerchantWallets = async ({
  accessToken,
  idToken
}: {
  accessToken: string;
  idToken: string;
}) => {
  const response = await axios.get(serverEndpoint + '/merchant/wallets', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-id-token': idToken,
      contentType: 'application/json'
    }
  });
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
};

export const getUserDek = async (
  accessToken: string,
  data: { target: string; pinCode: string }
) => {
  try {
    const { data: DEK } = await callFunction({
      url: '/createOrRecoverAccount',
      accessToken: accessToken as string,
      method: 'POST',
      data
    });
    return DEK;
  } catch (error) {
    throw new Error(formatErrorMessage(error, 'getUserDek'));
  }
};

export const callFunction = async ({
  url,
  accessToken,
  method,
  data
}: {
  url: string;
  accessToken: string;
  method: 'GET' | 'POST' | 'DELETE' | 'PUT';
  data?: any;
}) => {
  try {
    const response = await axios.request({
      url: serverEndpoint + url,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        contentType: 'application/json'
      },
      method,
      data
    });

    return response.data;
  } catch (e: any) {
    throw new Error(formatErrorMessage(e, 'callFunction ' + url));
  }
};

export const formatErrorMessage = (e: any, functionName: string) => {
  if (typeof e === 'string') return e;
  if (typeof e === 'object') {
    if (typeof e.data?.error === 'object' && e?.data?.error?.message) {
      return e?.data?.error?.message;
    } else if (typeof e?.data?.error === 'string') {
      return e?.data?.error;
    } else if (
      typeof e?.response?.data?.error === 'object' &&
      e?.response?.data?.error?.message
    ) {
      return e?.response?.data?.error?.message;
    } else if (typeof e?.response?.data?.error === 'string') {
      return e?.response?.data?.error;
    } else if (e?.message && typeof e.message === 'string') return e.message;
    return 'Something went wrong in function ' + functionName;
  }
};
