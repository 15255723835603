import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import i18n from '../config/languageInternationalization';
import { PinCodePrimaryButton, PinCodeSecondaryButton } from './AddPinCode';
import { Form } from './ThemedComponents/Flowbite/Form';
import PhoneInput from 'react-phone-number-input';

type InsertPhoneParams = {
  error: string;
  handleSendPhoneNumber: (e: React.FormEvent<HTMLFormElement>) => void;
  setPhoneNumber: React.Dispatch<any>;
  phoneNumber: string;
  loading: boolean;
  navigate: NavigateFunction;
};

const InsertPhone = ({
  error,
  handleSendPhoneNumber,
  setPhoneNumber,
  phoneNumber,
  loading,
  navigate
}: InsertPhoneParams) => {
  return (
    <Form
      handleSubmit={(e) => handleSendPhoneNumber(e)}
      title={i18n.t('insertPhone')}
    >
      <h2 className="text-gray-500">{i18n.t('recoverySubtitle')}</h2>
      <div className="flex flex-col">
        <PhoneInput
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={(value) => {
            setPhoneNumber(value);
          }}
        />
        {error && <span className="text-red-600 text-center">{error}</span>}
        <div className="mt-4">
          <PinCodePrimaryButton
            text={i18n.t('continue')}
            disabled={!phoneNumber || loading || error.length > 0}
            isLoading={loading}
          />
        </div>
        <div className="mt-2">
          <PinCodeSecondaryButton
            text={i18n.t('skip')}
            handleOnClick={() => navigate('/overview')}
          />
        </div>
      </div>
    </Form>
  );
};

export default InsertPhone;
