import React, { useEffect, useState } from 'react';
import i18n from '../../../config/languageInternationalization';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { MerchantRoles } from '../../../utils/types';
import {
  Contract,
  ContractModalDelete,
  ContractModalSuccess,
  ContracthModalForm
} from './contract-modal';
import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import { Table } from 'flowbite-react';
import { getChainLevel } from '../../../redux/slices/userThunk';
import useCustomTheme from '../../ThemedComponents/use-custom-theme';
import { LoadingTableRow } from '../../ThemedComponents/Flowbite/loading-table-row';

export default function ContractsTable({
  setOpenFormModal,
  openFormModal
}: {
  setOpenFormModal: any;
  openFormModal: any;
}) {
  const { contracts, role, chainLevel } = useAppSelector((state) => state.user);
  const [openSuccessModal, setOpenSuccessModal] = useState<Contract | null>(
    null
  );
  const { getTableHeadStyle, getTableBodyStyle, getActiveColor, getTextColor } =
    useCustomTheme();

  const tableHeadColor = getTableHeadStyle();
  const tableBodyColor = getTableBodyStyle();
  const activeColor = getActiveColor();
  const textColor = getTextColor();

  const [openDeleteModal, setOpenDeleteModal] = useState<string | null>(null);

  const [editingContract, setEditingContract] = useState<Contract | null>(null);

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!chainLevel) {
      setLoading(true);
      dispatch(getChainLevel()).finally(() => {
        setLoading(false);
      });
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: tableBodyColor.backgroundColor,
        color: activeColor,
        borderRadius: 6
      }}
      className="overflow-x-auto"
    >
      <Table>
        <Table.Head>
          <Table.HeadCell style={tableHeadColor}>
            {i18n.t('contractAddress')}
          </Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}>
            {i18n.t('chain')}
          </Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}>
            {i18n.t('contractAddressFunctionName')}
          </Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}>
            {i18n.t('contractAddressFunctionDeclaration')}
          </Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}></Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}></Table.HeadCell>
        </Table.Head>

        <Table.Body className="divide-y">
          {!loading ? (
            <>
              {contracts && contracts.length ? (
                contracts?.map((t, i) => (
                  <>
                    <Table.Row
                      key={i}
                      className="align-middle items-center"
                      style={{
                        backgroundColor: tableBodyColor.backgroundColor,
                        color: textColor,
                        fontSize: 14,
                        fontWeight: 400,
                        borderColor: textColor
                      }}
                    >
                      {/* Contract address */}
                      <Table.Cell className="flex align-middle items-center cursor-pointer py-6">
                        <div style={{ wordWrap: 'break-word' }}>
                          <span
                            className="text-sm font-bold leading-tight"
                            style={{
                              color: tableHeadColor.backgroundColor
                            }}
                          >
                            {t.alias}
                          </span>
                          <br />
                          <span>{t.contractAddress}</span>
                        </div>
                      </Table.Cell>
                      {/* Chain */}
                      <Table.Cell>
                        <span>{t.chain}</span>
                      </Table.Cell>
                      {/* function name */}
                      <Table.Cell>{t.functionName}</Table.Cell>
                      {/* function parameters */}
                      <Table.Cell>{t.functionDeclaration}</Table.Cell>
                      {role === MerchantRoles.OWNER && (
                        <>
                          <Table.Cell>
                            <div data-test-id={`edit-client-button-${t.id}`}>
                              <PencilIcon
                                color={tableHeadColor.backgroundColor}
                                className="cursor-pointer h-5 w-5 transform hover:scale-125 active:scale-100"
                                onClick={() => {
                                  setEditingContract(t);
                                  setOpenFormModal(true);
                                }}
                              />
                            </div>
                          </Table.Cell>
                          <Table.Cell>
                            <div data-test-id={`delete-client-icon-${t.id}`}>
                              <TrashIcon
                                color={tableHeadColor.backgroundColor}
                                className="cursor-pointer w-5 h-5 transform hover:scale-125 active:scale-100"
                                onClick={() => setOpenDeleteModal(t.id)}
                              />
                            </div>
                          </Table.Cell>
                        </>
                      )}
                    </Table.Row>
                  </>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6}>
                    <p className="text-white text-base font-bold p-8">
                      {i18n.t('youHaveNoContractsYet')}
                    </p>
                  </Table.Cell>
                </Table.Row>
              )}
            </>
          ) : (
            <LoadingTableRow
              tableBodyColor={tableBodyColor.backgroundColor}
              activeColor={activeColor}
              cellsToComplete={5}
            />
          )}
        </Table.Body>
      </Table>

      <>
        {openFormModal && (
          <ContracthModalForm
            isOpen={openFormModal}
            onClose={() => {
              setOpenFormModal(false);
              setEditingContract(null);
            }}
            editingContract={editingContract}
            setOpenSuccessModal={setOpenSuccessModal}
          />
        )}
        {openSuccessModal && (
          <ContractModalSuccess
            isOpen={!!openSuccessModal}
            onClose={() => setOpenSuccessModal(null)}
            contractId={openSuccessModal?.id as string}
          />
        )}
        {openDeleteModal && (
          <ContractModalDelete
            isOpen={!!openDeleteModal}
            onClose={() => setOpenDeleteModal(null)}
            id={openDeleteModal as string}
          />
        )}
      </>
    </div>
  );
}
