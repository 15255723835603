import React, { useState } from 'react';
import i18n from '../../config/languageInternationalization';
import { SidebarLayout } from '../../layout/Sidebar';
import GenerateApiKey from './apikey-tab/generate-apikey';
import LinkedAccounts from './linked-accounts-tab';
import MerchantWebhook from './merchant-webhook/merchant-webhook';
import TokenTransferScreen from './tokens-tab/token-transfer-screen';
import Members from './members-tab/members';
import { OAuth } from './oauth/oauth';
import Profile from './profile-tab/profile';
import { Contracts } from './contracts/contracts';

const settingsElement: { label: string; component: React.ReactElement }[] = [
  { label: 'API Keys', component: <GenerateApiKey /> },
  { label: 'Webhooks', component: <MerchantWebhook /> },
  { label: i18n.t('linkedAccounts'), component: <LinkedAccounts /> },
  { label: i18n.t('members'), component: <Members /> },
  { label: 'Tokens', component: <TokenTransferScreen /> },
  { label: 'OAuth 2.0', component: <OAuth /> },
  {
    label: i18n.t('contracts'),
    component: <Contracts />
  },

  { label: i18n.t('profile'), component: <Profile /> }
];

const SettingsScreen: React.FunctionComponent = (): React.ReactElement => {
  const [settingElement, setSettingElement] = useState<{
    label: string;
    component: any;
  }>(settingsElement[0]);
  return (
    <SidebarLayout>
      <div>
        <h1 className="text-gray-400 font-semibold text-2xl">
          {i18n.t('settings')}
        </h1>
        <div className="flex mb-8 mt-7 overflow-auto">
          {settingsElement.map((e, i) => (
            <div
              key={i}
              className={`px-4 pb-3 ${
                settingElement == settingsElement[i]
                  ? 'border-b border-white'
                  : 'border-b border-gray-200/50'
              }`}
            >
              <button
                onClick={() => {
                  setSettingElement(settingsElement[i]);
                }}
              >
                <h3
                  className={`hover:cursor-pointer hover:text-white ${
                    settingElement == settingsElement[i]
                      ? 'text-white'
                      : 'text-gray-200'
                  }`}
                >
                  {e.label}
                </h3>
              </button>
            </div>
          ))}
        </div>
        {settingElement.component}
      </div>
    </SidebarLayout>
  );
};
export default SettingsScreen;
