import React from 'react';
import { FailureImages } from '../assets/images/NotFound';
import { useNavigate } from 'react-router-dom';
import i18n from '../config/languageInternationalization';
import { images } from '../assets/images';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="bg-[#080808] h-screen relative grid place-items-center">
      <img
        src={images.ConnectCube}
        alt="Connect"
        className="w-[200px] absolute top-10 right-[15%] hidden md:block"
      />
      <img
        src={images.Cubes}
        alt="Cubes"
        className="w-[200px] absolute bottom-[70px] left-[10%] hidden md:block"
      />
      <div className="bg-[rgba(38,42,52,0.4)] backdrop-blur-md h-[65%] rounded-xl w-[90vw] grid place-items-center">
        <h1 className="font-[Satoshi] font-medium text-[20px] md:text-[42px] text-white">
          {'Oops... something went wrong :('}
        </h1>
        <p className="font-[Satoshi] text-[#BAC3CA] font-medium text-[14px] md:text-[21px] w-[80%] md:w-[42%] mb-[-30px] text-center">
          {i18n.t('someProblemsWithRequest')}
        </p>
        <img
          src={FailureImages.FailureImage}
          alt="SphereOne"
          className="w-[300px] md:w-[350px]"
        />
        <div className="flex mt-[-30px]">
          <img
            src={FailureImages.CheckIcon}
            alt="check-icon"
            className="w-[20px] h-[20px] mt-1 md:mt-0 md:w-[30px] md:h-[30px] mr-2 md:mr-4"
          />
          <h3 className="text-gray-300 font-[Satoshi] text-sm md:text-xl font-medium">
            {i18n.t('loadingFailed')}
          </h3>
        </div>

        <button
          onClick={() => navigate('/overview')}
          className="bg-itemHover rounded-md h-12 w-64 text-base font-medium"
        >
          {i18n.t('home')}
        </button>
      </div>
    </div>
  );
}
