import React, { useState } from 'react';
import '../../index.css';

export const Tooltip = ({ children }: { children: React.ReactNode }) => {
  const [isVisble, setIsVisible] = useState(false);

  return (
    <div
      className="bg-blue-100 w-4 rounded-[100%] text-center  h-4 inline-block text-white font-semibold relative cursor-pointer"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <div className="flex flex-col items-center justify-center">
        <span className="relative px-2 text-xs text-center">i</span>
      </div>
      {isVisble && <div className="tooltip">{children}</div>}
    </div>
  );
};
