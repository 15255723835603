import React, { useEffect, useState } from 'react';
import { Modal, TextInput, Label } from 'flowbite-react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { useMediaQuery } from 'react-responsive';
import i18n from '../../../config/languageInternationalization';
import { SupportedChains } from '../../../utils/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  deleteDocumentData,
  merchantDataEndpoint,
  postDocumentData,
  updateDocumentData
} from '../../../utils/server';
import Button from '../../ThemedComponents/Buttons/button';
import { SuccessModalIcon } from '../../../assets/icons/successModalIcon';
import {
  removeContract,
  setContract,
  updateContract
} from '../../../redux/slices/user';
import Select from '../../ThemedComponents/Select';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

export interface Contract {
  contractAddress: string;
  functionName: string;
  functionDeclaration: string;
  alias: string;
  chain: SupportedChains | '';
  id: string;
}

interface NewFormValues extends Omit<Contract, 'id'> {}
const defaultValues: NewFormValues = {
  contractAddress: '',
  functionName: '',
  functionDeclaration: '',
  alias: '',
  chain: ''
};

const schema = z.object({
  contractAddress: z
    .string()
    .refine(
      (value) => isEvmAddress(value),
      i18n.t('contractsModalErrorAddress')
    ),
  chain: z
    .string()
    .refine((value) => value.trim() !== '', i18n.t('contractsModalErrorChain'))
    .optional(),
  functionName: z.string().min(1, i18n.t('contractsModalErrorFunctionName')),
  functionDeclaration: z
    .string()
    .min(1, i18n.t('contractsModalErrorFunctionDeclaration')),
  alias: z.string().min(1, i18n.t('contractsModalErrorAlias'))
});

interface ContractshModalProps {
  isOpen: boolean;
  onClose: () => void;
  editingContract: Contract | null;
  setOpenSuccessModal: React.Dispatch<React.SetStateAction<Contract | null>>;
}

type FormValues = z.infer<typeof schema>;

export const ContracthModalForm: React.FC<ContractshModalProps> = ({
  isOpen,
  onClose,
  editingContract,
  setOpenSuccessModal
}) => {
  const dispatch = useAppDispatch();
  const { merchantUid, accessToken, idToken, contracts, chainLevel } =
    useAppSelector((state) => state.user);
  const {
    control,
    handleSubmit,
    trigger,
    setError,
    setValue,
    formState: { errors },
    reset
  } = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(schema)
  });

  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });

  const onSubmit = async (data: FormValues) => {
    try {
      if (!editingContract && !isAliasUnique(contracts, data.alias)) {
        setError('alias', {
          message: i18n.t('contractsModalErrorAliasUnique')
        });
        return;
      }
      if (!data.chain)
        return setError('chain', {
          message: i18n.t('contractsModalErrorNoChain')
        });
      if (!chainLevel?.includes(data.chain as SupportedChains)) {
        return setError('chain', {
          message: i18n.t('contractsModalErrorNotSupportedChain')
        });
      }
      if (!accessToken || !idToken || isLoading) return;
      schema.parseAsync(data);
      setIsLoading(true);
      if (editingContract) await editContract(data);
      else await createContract(data);
      reset(defaultValues);
      handleClose();
    } catch (error: any) {
      setError('root', {
        message: i18n.t('contractModalSubmitError')
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createContract = async (data: FormValues) => {
    if (!accessToken || !idToken) return;
    data.alias = data.alias.trim().toLowerCase();
    const response = await postDocumentData({
      url: merchantDataEndpoint + `/${merchantUid}/contract`,
      data,
      accessToken: accessToken,
      idToken: idToken
    });
    if (!data.chain) throw new Error(i18n.t('contractsModalErrorNoChain'));
    if (!(data.chain in SupportedChains)) {
      throw new Error(i18n.t('contractsModalErrorNotSupportedChain'));
    }

    dispatch(setContract({ ...data, id: response.data.id }));
    setOpenSuccessModal(response.data as Contract);
  };

  const editContract = async (data: FormValues) => {
    if (!accessToken || !idToken) return;
    const response = await updateDocumentData({
      url:
        merchantDataEndpoint +
        `/${merchantUid}/contract/${editingContract?.id}`,
      updateData: {
        ...data
      },
      accessToken: accessToken,
      idToken: idToken
    });

    dispatch(updateContract(response.data as Contract));
  };

  const handleClose = () => {
    if (isLoading) return;
    onClose();
    reset({
      contractAddress: '',
      functionDeclaration: '',
      functionName: '',
      alias: '',
      chain: ''
    });
    errors.root && setError('root', { message: '' });
  };

  useEffect(() => {
    if (editingContract) {
      setValue('contractAddress', editingContract.contractAddress);
      setValue('functionName', editingContract.functionName);
      setValue('functionDeclaration', editingContract?.functionDeclaration);
      setValue('alias', editingContract?.id);
      setValue('chain', editingContract?.chain);
    }
  }, [editingContract]);
  return (
    <Modal
      dismissible
      popup
      show={isOpen}
      onClose={handleClose}
      className="dark bg-black bg-opacity-50"
      size={isMobile ? 'sm' : 'lg'}
    >
      <div className="bg-gray-800 rounded-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body className="p-8 gap-5">
            <h1 className="text-gray-50 text-xl font-medium leading-loose pb-5">
              {i18n.t('contractModalTitle')}
            </h1>
            <div className="pb-5">
              <Label htmlFor="contractAddress" value="Contract Address" />
              <Controller
                name="contractAddress"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextInput className="dark" id="contractAddress" {...field} />
                )}
              />
              {errors.contractAddress && (
                <p className="text-red-600 text-sm">
                  {errors.contractAddress.message}
                </p>
              )}
            </div>
            <div className="pb-5">
              <Label htmlFor="chain" value="Chain" />
              <Controller
                name="chain"
                control={control}
                render={({ field }) => (
                  <Select
                    id="CreateCharge-Chain"
                    className="text-gray-500 bg-card-stroke border border-gray-700 h-14 px-2 rounded-lg text-left flex items-center"
                    {...field}
                    defaultValues={editingContract?.chain}
                  >
                    <option
                      className="bg-gray-700 text-sm text-white"
                      value={undefined}
                      key="0"
                      selected
                    >
                      --
                    </option>
                    {chainLevel &&
                      chainLevel.map((e: SupportedChains) => {
                        return (
                          <option
                            className="bg-gray-700 text-sm text-white"
                            value={e}
                            key={e}
                          >
                            {e}
                          </option>
                        );
                      })}
                  </Select>
                )}
                rules={{ required: true }}
              />

              {errors.chain && (
                <p className="text-red-600 text-sm">{errors.chain?.message}</p>
              )}
            </div>
            <div className="pb-5">
              <Label
                htmlFor="functionDeclaration"
                value="Function declaration"
              />
              <Controller
                name="functionDeclaration"
                control={control}
                render={({ field }) => (
                  <TextInput id="functionDeclaration" {...field} />
                )}
                rules={{ required: true }}
              />
              {errors.functionDeclaration && (
                <p className="text-red-600 text-sm">
                  {errors.functionDeclaration?.message}
                </p>
              )}
            </div>
            <div className="pb-5">
              <Label htmlFor="functionName" value="Name of the function" />
              <Controller
                name="functionName"
                control={control}
                render={({ field }) => (
                  <TextInput id="functionName" {...field} />
                )}
                rules={{ required: true }}
              />
              {errors.functionName && (
                <p className="text-red-600 text-sm">
                  {errors.functionName.message}
                </p>
              )}
            </div>

            <div
              className="pb-5"
              style={editingContract ? { display: 'none' } : {}}
            >
              <Label htmlFor="alias" value="Alias" />
              <Controller
                name="alias"
                control={control}
                render={({ field }) => <TextInput id="alias" {...field} />}
                rules={{ required: true }}
              />
              {errors.alias && (
                <p className="text-red-600 text-sm">{errors.alias?.message}</p>
              )}
            </div>
            {errors.root && (
              <p className="text-red-600">{errors.root.message}</p>
            )}
            <div className="flex w-1/2 pt-5 gap-4">
              <PrimaryButton title={i18n.t('cancel')} onClick={onClose} />
              <PrimaryButton
                type="submit"
                loading={isLoading}
                title={i18n.t('save')}
              />
            </div>
          </Modal.Body>
        </form>
      </div>
    </Modal>
  );
};

interface ContractModalSuccessProps {
  isOpen: boolean;
  onClose: () => void;
  contractId: string;
}

export const ContractModalSuccess: React.FC<ContractModalSuccessProps> = ({
  isOpen,
  onClose,
  contractId
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });

  return (
    <Modal
      className="dark"
      show={isOpen}
      size={isMobile ? 'sm' : 'lg'}
      popup
      dismissible
      onClose={onClose}
    >
      <div className="bg-gray-800 rounded-lg shadow">
        <Modal.Body>
          <div className="flex flex-col justify-start p-3 mt-6 gap-4">
            <div className="flex flex-col items-center text-center">
              <SuccessModalIcon />
            </div>
            <h1 className="text-lg sm:text-xl font-medium text-gray-50 leading-loose text-start">
              {i18n.t('contractModalSuccessTitle')}
            </h1>
            <span className="text-sm sm:text-base font-normal text-gray-400 leading-normal">
              {i18n.t('contractModalSuccessDescription')}
            </span>
            <div className="flex w-1/2 self-center mt-2">
              <PrimaryButton title={i18n.t('close')} onClick={onClose} />
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

interface ContractModalDeleteProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ContractModalDelete: React.FC<ContractModalDeleteProps> = ({
  id,
  isOpen,
  onClose
}) => {
  const dispatch = useAppDispatch();
  const { merchantUid, accessToken, idToken } = useAppSelector(
    (state) => state.user
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    if (!accessToken || !idToken || isLoading) return;
    setIsLoading(true);
    try {
      await deleteDocumentData({
        url: merchantDataEndpoint + `/${merchantUid}/contract/${id}`,
        accessToken,
        idToken
      });
      dispatch(removeContract(id));
    } finally {
      setIsLoading(false);
      onClose();
    }
  };
  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };
  return (
    <>
      <Modal
        show={isOpen}
        size="sm"
        popup
        onClose={handleClose}
        className="dark"
      >
        <div className="bg-gray-800 rounded-lg shadow">
          <Modal.Body>
            <div className="flex flex-col justify-start p-3 mt-6 gap-4">
              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-600" />
              <h3 className="mb-5 text-lg font-normal  text-gray-50">
                {i18n.t('contractDeleteModalMessage')}
              </h3>
              <div className="flex justify-center gap-4">
                <PrimaryButton title={i18n.t('no')} onClick={handleClose} />
                <PrimaryButton
                  loading={isLoading}
                  onClick={handleDelete}
                  title={i18n.t('yes')}
                />
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export function isEvmAddress(address: string): boolean {
  return /^(0x)?[0-9a-fA-F]{40}$/.test(address);
}

function isAliasUnique(contracts: Contract[] | undefined, alias: string) {
  if (!contracts) return true;
  return contracts.every(
    (contract: Contract) => contract.alias.toLowerCase() !== alias.toLowerCase()
  );
}
