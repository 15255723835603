import React, { useState } from 'react';
import { Table, Tooltip } from 'flowbite-react';
import {
  ClipboardDocumentCheckIcon,
  EllipsisHorizontalCircleIcon,
  EllipsisVerticalIcon,
  MinusCircleIcon,
  PlusCircleIcon
} from '@heroicons/react/20/solid';

import useCustomTheme from '../../ThemedComponents/use-custom-theme';
import {
  Balance,
  LinkedAccountWallet,
  MerchantRoles
} from '../../../utils/types';
import { images } from '../../../assets/images';
import { toastSuccess } from '../../../utils/toastify';
import i18n from '../../../config/languageInternationalization';
import WalletActions from './wallet-actions';
import { useAppSelector } from '../../../redux/hooks';
import { LoadingTableRow } from '../../ThemedComponents/Flowbite/loading-table-row';

interface LinkedAccountsTableProps {
  linkedAccounts: LinkedAccountWallet[] | undefined;
  chainLogos:
    | {
        [key: string]: string;
      }
    | undefined;
  balancePerWallet: Balance[] | undefined;
}

export const mapLogoToChain = (
  chainName: string,
  chainLogos: { [key: string]: string } | undefined
) => {
  if (!chainName || !chainLogos || !chainLogos[chainName]) {
    return {
      uri:
        chainLogos?.FALLBACK ||
        'https://png.pngtree.com/png-vector/20220220/ourmid/pngtree-lucky-coin-png-image_4407874.png'
    };
  }
  return {
    uri: chainLogos[chainName]
  };
};

const LinkedAccountsTable: React.FC<LinkedAccountsTableProps> = ({
  linkedAccounts,
  chainLogos,
  balancePerWallet
}) => {
  const { role } = useAppSelector((state) => state.user);

  const { getTableHeadStyle, getTableBodyStyle, getActiveColor, getTextColor } =
    useCustomTheme();

  const tableHeadColor = getTableHeadStyle();
  const activeColor = getActiveColor();
  const tableBodyColor = getTableBodyStyle();
  const textColor = getTextColor();

  const [expandedRows, setExpandedRows] = useState<boolean[]>(
    new Array(linkedAccounts?.length || 0).fill(false)
  );

  const [showTooltips, setShowTooltips] = useState<boolean[]>(
    new Array(linkedAccounts?.length || 0).fill(false)
  );

  const [selectedWallet, setSelectedWallet] = useState<
    LinkedAccountWallet | undefined | null
  >(undefined);

  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleMouseEnter = (index: number) => {
    setShowTooltips((prevState) =>
      prevState.map((value, i) => (i === index ? true : value))
    );
  };

  const handleMouseLeave = (index: number) => {
    setShowTooltips((prevState) =>
      prevState.map((value, i) => (i === index ? false : value))
    );
  };

  const handleCopyAddress = (address: string) => {
    navigator.clipboard.writeText(address);
    toastSuccess(`${i18n.t('addressCopied')} ${address}`);
  };

  const toggleRowExpansion = (index: number) => {
    setExpandedRows((prevState) =>
      prevState.map((value, i) => (i === index ? !value : value))
    );
  };

  const renderNetworkCell = (chains: string[], index: number) => {
    const visibleChains = expandedRows[index] ? chains : chains.slice(0, 5);
    const rows = [];
    for (let i = 0; i < visibleChains.length; i += 5) {
      rows.push(visibleChains.slice(i, i + 5));
    }
    return (
      <div>
        {rows.map((row, rowIndex) => (
          <div key={rowIndex} className="flex flex-row">
            {row.map((chainName, chainIndex) => (
              <div key={chainIndex} className="flex flex-row">
                <img
                  className="w-6 h-6 m-1 rounded-full"
                  src={mapLogoToChain(chainName, chainLogos).uri}
                  alt={chainName}
                />
              </div>
            ))}
            {rowIndex === rows.length - 1 && chains.length > 5 && (
              <button onClick={() => toggleRowExpansion(index)}>
                {expandedRows[index] ? (
                  <MinusCircleIcon
                    className="w-6 h-6 transform hover:scale-125 active:scale-100"
                    color={tableHeadColor.backgroundColor}
                  />
                ) : (
                  <PlusCircleIcon
                    className="w-6 h-6 transform hover:scale-125 active:scale-100"
                    color={tableHeadColor.backgroundColor}
                  />
                )}
              </button>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: tableBodyColor.backgroundColor,
        color: activeColor,
        borderRadius: 6
      }}
      className="overflow-x-auto my-8"
    >
      <Table>
        <Table.Head>
          <Table.HeadCell style={tableHeadColor}>WALLETS</Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}>LABEL</Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}>BALANCE</Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}>NETWORK</Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}></Table.HeadCell>
        </Table.Head>
        {/* BODY */}
        <Table.Body className="divide-y">
          {linkedAccounts ? (
            linkedAccounts.map((e, i) => {
              return (
                <>
                  <Table.Row
                    key={e.address}
                    className="align-middle items-center"
                    style={{
                      backgroundColor: tableBodyColor.backgroundColor,
                      color: textColor,
                      fontSize: 14,
                      fontWeight: 400,
                      borderColor: textColor
                    }}
                  >
                    {/* Address */}
                    <Table.Cell
                      onMouseEnter={() => handleMouseEnter(i)}
                      onMouseLeave={() => handleMouseLeave(i)}
                      onClick={() => handleCopyAddress(e.address)}
                      className="flex align-middle items-center cursor-pointer py-6"
                    >
                      {!e.isImported ? (
                        <img
                          className="w-6 h-6 mr-2 rounded-full"
                          src={images.SphereLogo}
                          alt="logo"
                        />
                      ) : (
                        <div className="w-6 h-6 mr-2" />
                      )}
                      <Tooltip
                        className="flex flex-row items-center align-middle cursor-pointer"
                        content={
                          <div className="flex items-center align-middle gap-2">
                            <ClipboardDocumentCheckIcon className="w-4 h-4" />
                            Copy Address
                          </div>
                        }
                        placement="bottom"
                      >
                        <div>
                          <p>{e.address}</p>
                          {e.missingStarkKey && (
                            <p
                              style={{
                                color: tableHeadColor.backgroundColor
                              }}
                              className="text-xs font-bold leading-tight mt-2"
                            >
                              {i18n.t('missingStarkKey')}
                            </p>
                          )}
                        </div>
                      </Tooltip>
                    </Table.Cell>

                    {/* Label */}
                    <Table.Cell>{e.label}</Table.Cell>

                    {/* Balance */}
                    <Table.Cell>
                      <p>
                        $
                        {balancePerWallet?.filter(
                          (balance) => balance.address === e.address
                        )[0]?.price || '0.00'}
                      </p>
                    </Table.Cell>

                    {/* Chain Logos */}
                    <Table.Cell>{renderNetworkCell(e.chains, i)}</Table.Cell>

                    {/* Menu */}
                    <Table.Cell>
                      <>
                        <Tooltip
                          content={
                            role !== MerchantRoles.OWNER
                              ? i18n.t('notOwner')
                              : i18n.t('walletActions')
                          }
                        >
                          <button
                            disabled={role !== MerchantRoles.OWNER}
                            onClick={() => {
                              setSelectedWallet(e);

                              if (role === MerchantRoles.OWNER)
                                setShowMenu(true);
                            }}
                          >
                            <EllipsisVerticalIcon
                              className="h-5 w-5 mr-2 hover:cursor-pointer transform hover:scale-125 active:scale-100"
                              color={tableHeadColor.backgroundColor}
                            />
                          </button>
                        </Tooltip>
                      </>
                    </Table.Cell>
                  </Table.Row>
                </>
              );
            })
          ) : (
            <LoadingTableRow
              tableBodyColor={tableBodyColor.backgroundColor}
              activeColor={activeColor}
              cellsToComplete={4}
            />
          )}
        </Table.Body>
      </Table>
      {showMenu && selectedWallet && (
        <WalletActions
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          handleCopyAddress={handleCopyAddress}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
      )}
    </div>
  );
};

export default LinkedAccountsTable;
