import React, { useEffect, useState } from 'react';
import { Badge, Table } from 'flowbite-react';
import {
  OAuthModalSuccess,
  OAuthModalForm,
  OAuthModalDelete
} from './oauth-modal';
import { useAppSelector } from '../../../redux/hooks';
import { getDocumentData, merchantDataEndpoint } from '../../../utils/server';
import { copyToClipBoard } from '../apikey-tab/generate-apikey';
import { Button } from '../../ThemedComponents/Flowbite/Button';
import i18n from '../../../config/languageInternationalization';
import useCustomTheme from '../../ThemedComponents/use-custom-theme';
import {
  ClipboardDocumentCheckIcon,
  PencilIcon,
  TrashIcon
} from '@heroicons/react/20/solid';
import { toastError } from '../../../utils/toastify';

export interface OAuth2Client {
  clientName: string;
  clientId: string;
  clientSecret: string;
  accessTokenLifetime: string;
  redirectUris: string[];
  accessTokenStrategy: string;
  grantTypes: string[];
  responseTypes: string[];
  scope: string;
  skipConsent: boolean;
  tokenEndpointAuthMethod: string;
  allowedOrigins: string[];
}

const headerCells = [
  { title: 'CLIENT NAME' },
  { title: 'CLIENT ID' },
  { title: 'CLIENT SECRET' },
  { title: <span className="sr-only">Edit</span>, className: 'w-14' },
  { title: <span className="sr-only">Delete</span>, className: 'w-14' }
];

function OAuthClientsTable() {
  const { oAuth2Clients, isVerified } = useAppSelector((state) => state.user);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<OAuth2Client | null>(
    null
  );
  const [openDeleteModal, setOpenDeleteModal] = useState<string | null>(null);

  const [editingClient, setEditingClient] = useState<Omit<
    OAuth2Client,
    'clientSecret'
  > | null>(null);

  const { getTableHeadStyle, getTableBodyStyle, getActiveColor, getTextColor } =
    useCustomTheme();

  const tableHeadColor = getTableHeadStyle();
  const activeColor = getActiveColor();
  const tableBodyColor = getTableBodyStyle();
  const textColor = getTextColor();

  const renderModals = () => {
    return (
      <>
        {openFormModal && (
          <OAuthModalForm
            isOpen={openFormModal}
            onClose={() => {
              setOpenFormModal(false);
              setEditingClient(null);
            }}
            editingClient={editingClient}
            setOpenSuccessModal={setOpenSuccessModal}
          />
        )}
        {openSuccessModal && (
          <OAuthModalSuccess
            isOpen={!!openSuccessModal}
            onClose={() => setOpenSuccessModal(null)}
            clientId={openSuccessModal?.clientId as string}
            clientSecret={openSuccessModal?.clientSecret as string}
          />
        )}
        {openDeleteModal && (
          <OAuthModalDelete
            isOpen={!!openDeleteModal}
            onClose={() => setOpenDeleteModal(null)}
            clientId={openDeleteModal as string}
          />
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      <Table>
        <Table.Head style={tableHeadColor} className="lg:h-12 rounded-tl-lg ">
          {headerCells.map((cell, index) => (
            <TableHeadCell
              tableHeadColor={tableHeadColor}
              key={index}
              className={cell.className}
            >
              {cell.title}
            </TableHeadCell>
          ))}
        </Table.Head>
        {oAuth2Clients.length > 0 ? (
          <Table.Body className="divide-y">
            {oAuth2Clients.map((client, index) => (
              <OAuthClientRow
                key={index}
                client={client}
                index={index}
                setOpenModal={setOpenFormModal}
                setEditingClient={setEditingClient}
                setOpenDeleteModal={setOpenDeleteModal}
              />
            ))}
          </Table.Body>
        ) : (
          <Table.Body>
            <Table.Row
              style={{
                backgroundColor: tableBodyColor.backgroundColor,
                color: activeColor,
                fontSize: 14,
                fontWeight: 500
              }}
              className="justify-center items-center text-center w-full"
            >
              <Table.Cell colSpan={5}>
                <h1 className="text-gray-200 text-2xl font-semibold leading-9">
                  {i18n.t('oAuth2TableNoDataTitle')}
                </h1>
                <p className="text-gray-400 text-sm font-normal leading-tight">
                  {i18n.t('oAuth2TableNoDataDescription')}
                </p>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        )}
      </Table>
      <div className="w-full flex justify-center pb-10">
        <Button
          className="h-10 px-5 py-2.5 bg-gray-800 rounded-lg border border-gray-600 text-gray-400 hover:bg-gray-900 hover:text-gray-50"
          onClick={() => {
            if (isVerified) {
              setOpenFormModal(true);
            } else {
              toastError(i18n.t('notVerifiedAlert'));
            }
          }}
        >
          {i18n.t('oAuth2TableAdd')}
        </Button>
      </div>
      {renderModals()}
    </div>
  );
}

export default OAuthClientsTable;

interface TableHeadCellProps {
  children: React.ReactNode;
  className?: string;
  tableHeadColor: {
    backgroundColor: string;
    color: string;
  };
}

const TableHeadCell: React.FC<TableHeadCellProps> = ({
  children,
  className = '',
  tableHeadColor
}) => (
  <Table.HeadCell
    style={{ ...tableHeadColor }}
    className={`text-white font-semibold text-xs ${className}`}
  >
    {children}
  </Table.HeadCell>
);

interface OAuthClientRowProps {
  client: Omit<OAuth2Client, 'clientSecret'>;
  index: number;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEditingClient: React.Dispatch<
    React.SetStateAction<Omit<OAuth2Client, 'clientSecret'> | null>
  >;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<string | null>>;
}

const OAuthClientRow: React.FC<OAuthClientRowProps> = ({
  client,
  index,
  setEditingClient,
  setOpenModal,
  setOpenDeleteModal
}) => {
  const { merchantUid, accessToken, idToken } = useAppSelector(
    (state) => state.user
  );
  const [showClientSecretFeedback, setShowClientSecretFeedback] = useState<
    'success' | 'error' | null
  >(null);
  const fetchClientSecret = async (clientId: string) => {
    try {
      const response = await getDocumentData({
        url: merchantDataEndpoint + `/${merchantUid}/oauth/${clientId}`,
        accessToken,
        idToken
      });
      await copyToClipBoard(
        response.data.clientSecret,
        'Unable to copy client secret'
      );
      setShowClientSecretFeedback('success');
    } catch (e: any) {
      setShowClientSecretFeedback('error');
    }
  };

  useEffect(() => {
    if (showClientSecretFeedback) {
      const timeout = setTimeout(() => {
        setShowClientSecretFeedback(null);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [showClientSecretFeedback]);

  const { getTableBodyStyle, getTextColor, getTableHeadStyle } =
    useCustomTheme();

  const textColor = getTextColor();
  const tableBodyColor = getTableBodyStyle();
  const { backgroundColor: primaryColor } = getTableHeadStyle();

  return (
    <Table.Row
      style={{
        backgroundColor: tableBodyColor.backgroundColor,
        color: textColor,
        fontSize: 14,
        fontWeight: 400,
        borderColor: textColor
      }}
      key={index}
      className="align-middle items-center"
    >
      <Table.Cell className="font-normal text-sm leading-tight">
        {client.clientName}
      </Table.Cell>
      <Table.Cell
        style={{
          color: primaryColor
        }}
        className="text-sm font-bold leading-tight"
      >
        <div data-test-id={`client-id-cell-${client.clientId}`}>
          {client.clientId}
        </div>
      </Table.Cell>
      <Table.Cell className="text-base font-medium leading-normal">
        {showClientSecretFeedback ? (
          showClientSecretFeedback === 'success' ? (
            <Badge
              className="dark flex justify-center items-center w-28 animate-fade-in"
              color="success"
              size="sm"
            >
              Copied
            </Badge>
          ) : (
            <Badge
              className="flex justify-center items-center w-36 animate-fade-in"
              color="failure"
              size="sm"
            >
              Unable to copy
            </Badge>
          )
        ) : (
          <div
            style={{
              color: textColor
            }}
            className="flex items-center"
          >
            <span>**********</span>
            <div data-test-id={`copy-client-button-${client.clientId}`}>
              <ClipboardDocumentCheckIcon
                color={primaryColor}
                className="cursor-pointer ml-2 w-5 h-5 transform hover:scale-125 active:scale-100"
                onClick={() => fetchClientSecret(client.clientId)}
              />
            </div>
          </div>
        )}
      </Table.Cell>
      <Table.Cell>
        <div data-test-id={`edit-client-button-${client.clientId}`}>
          <PencilIcon
            color={primaryColor}
            className="cursor-pointer h-5 w-5 transform hover:scale-125 active:scale-100"
            onClick={() => {
              setEditingClient(client);
              setOpenModal(true);
            }}
          />
        </div>
      </Table.Cell>
      <Table.Cell>
        <div data-test-id={`delete-client-icon-${client.clientId}`}>
          <TrashIcon
            color={primaryColor}
            className="cursor-pointer w-5 h-5 transform hover:scale-125 active:scale-100"
            onClick={() => setOpenDeleteModal(client.clientId)}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};
