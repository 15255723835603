import axios from 'axios';
interface IChargeData {
  symbol: string;
  chain: string;
  successUrl: string;
  cancelUrl: string;
  items: {
    amount: string;
    image: string;
    name: string;
    quantity: number;
  }[];
  amount: string;
}

export const createCharge = async (
  apiKey: string,
  isTest: boolean,
  chargeData: IChargeData,
  expirationDate?: Date
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}/createCharge`,
      {
        isTest,
        expirationDate,
        chargeData
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        }
      }
    );
    const { data, error } = response.data;
    if (error) {
      throw new Error(`${error}`);
    }
    return data.paymentUrl;
  } catch (error) {
    throw new Error(`${error}`);
  }
};
