import React from 'react';
import { Table } from 'flowbite-react';

import { ApiKeyEnvironments, copyToClipBoard } from './generate-apikey';
import i18n from '../../../config/languageInternationalization';
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/20/solid';
import { toastSuccess } from '../../../utils/toastify';

export const ApiKeyTableRow = ({
  apiKey,
  tableBodyColor,
  apiKeyEnvironment
}: {
  apiKey: string;
  tableBodyColor: any;
  apiKeyEnvironment: ApiKeyEnvironments;
}) => {
  return (
    <Table.Row style={tableBodyColor} className="">
      <Table.Cell colSpan={2}>
        {apiKey && (
          <button
            className="flex gap-2"
            onClick={() => {
              copyToClipBoard(apiKey, i18n.t('generateApiKeyUnableToCopy'));
              toastSuccess(`${i18n.t('valueCopied')}: ${apiKey}`);
            }}
          >
            {apiKey}
            <DocumentDuplicateIcon
              width={20}
              height={20}
              className="text-gray-400 cursor-pointer"
            />
          </button>
        )}
      </Table.Cell>
    </Table.Row>
  );
};
