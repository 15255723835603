import React from 'react';
import CustomersTable from '../components/CustomersTable';
import { useAppSelector } from '../redux/hooks';
import i18n from '../config/languageInternationalization';
import { SidebarLayout } from '../layout/Sidebar';

const Customers = () => {
  const { customerData, loading } = useAppSelector((state) => state.user);

  return (
    <SidebarLayout>
      <>
        {/* Header */}
        <div className="flex items-center justify-between mb-4 min-h-[40px]">
          <h1 className="text-gray-400 font-semibold text-2xl py-2">
            {i18n.t('customers')}
          </h1>
        </div>

        <div className="overflow-auto">
          <CustomersTable
            customerData={customerData}
            loading={loading ? loading : false}
          />
        </div>
      </>
    </SidebarLayout>
  );
};

export default Customers;
