import React, { useState } from 'react';
import { Modal, TabsComponent, Tabs, Tooltip } from 'flowbite-react';
import {
  ClipboardDocumentCheckIcon,
  EyeIcon,
  LockOpenIcon,
  PencilSquareIcon,
  QrCodeIcon,
  TrashIcon
} from '@heroicons/react/20/solid';
import {
  LinkedAccountWallet,
  MerchantRoles,
  SupportedChains
} from '../../../utils/types';
import { toastError, toastSuccess } from '../../../utils/toastify';
import i18n from '../../../config/languageInternationalization';
import { callFunction } from '../../../utils/server';
import { getLinkedAccounts } from '../../../redux/slices/userThunk';
import InsertPinCode from '../../InsertPinCode';
import { TargetOperation } from './recover-wallet';
import { verifyJWT } from '../../../utils/verifyJWT';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Input } from '../../ThemedComponents/Flowbite/Form';
import { mapLogoToChain } from './linked-accounts-table';
import QRCode from 'qrcode.react';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

const viewAccountOnExplorer = (selectedWallet: LinkedAccountWallet) => {
  const address = selectedWallet.address;
  switch (true) {
    case selectedWallet.chains.includes(SupportedChains.ETHEREUM):
      window.open(`https://etherscan.io/address/${address}`, '_blank');
      break;
    case selectedWallet.chains.includes(SupportedChains.POLYGON):
      window.open(`https://blockscan.com/address/${address}`, '_blank');
      break;
    case selectedWallet.chains.includes(SupportedChains.FLOW):
      window.open(`https://www.flowdiver.io/account/${address}`, '_blank');
      break;
    case selectedWallet.chains.includes(SupportedChains.BITCOIN):
      window.open(
        `https://www.blockchain.com/explorer/addresses/btc/${address}`,
        '_blank'
      );
      break;
    case selectedWallet.chains.includes(SupportedChains.SOLANA):
      window.open(`https://solscan.io/account/${address}`, '_blank');
      break;
    case selectedWallet.chains.includes(SupportedChains.SUI):
      window.open(`https://suiexplorer.com/address/${address}`, '_blank');
      break;
    default:
      break;
  }
};

const WalletActions = ({
  selectedWallet,
  handleCopyAddress,
  showMenu,
  setShowMenu,
  setSelectedWallet
}: {
  selectedWallet: LinkedAccountWallet;
  handleCopyAddress: (address: string) => void;
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedWallet: React.Dispatch<
    React.SetStateAction<LinkedAccountWallet | undefined | null>
  >;
}) => {
  enum WalletActions {
    RENAME,
    DELETE,
    PRIVATE_KEY
  }

  const { accessToken, role, chainLogos } = useAppSelector(
    (state) => state.user
  );
  const dispatch = useAppDispatch();

  const [modal, setModal] = useState(false);
  const [privateKey, setPrivateKey] = useState('');
  const [starkPrivateKey, setStarkPrivateKey] = useState<string>('');
  const [action, setAction] = useState<WalletActions | null>(null);
  const [showQRCode, setShowQRCode] = useState(false);

  const [label, setLabel] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [editingLabel, setEditingLabel] = useState(false);
  const [currentLabel, setCurrentLabel] = useState(selectedWallet.label || '');

  const removeReadOnlyWallet = async ({
    walletSelected
  }: {
    walletSelected: LinkedAccountWallet;
  }) => {
    try {
      if (!accessToken) return toastError(i18n.t('accessTokenError'));
      if (role !== MerchantRoles.OWNER) return toastError(i18n.t('notOwner'));
      if (!walletSelected?.isImported) {
        return toastError(i18n.t('sphereWalletCannotBeRemoved'));
      }
      if (walletSelected.readOnly) {
        await callFunction({
          url: `/user/wallets/${walletSelected.id}`,
          accessToken: accessToken as string,
          method: 'DELETE'
        });
        toastSuccess(i18n.t('walletRemovedSuccessfully'));
        setSelectedWallet(null);
        return dispatch(getLinkedAccounts());
      }
    } catch (error: any) {
      toastError(`${i18n.t('contactUs')}. ${error}`);
    } finally {
      setSelectedWallet(null);
    }
  };

  const updateWalletLabel = async (label: string) => {
    if (!accessToken) return toastError(i18n.t('accessTokenError'));
    if (role !== MerchantRoles.OWNER) return toastError(i18n.t('notOwner'));
    if (!selectedWallet.id) return toastError(i18n.t('contactUs'));
    if (label.length > 20) return toastError(i18n.t('labelTooLong'));

    setSubmitLoading(true);
    try {
      await callFunction({
        url: `/user/wallets/${selectedWallet.id}`,
        accessToken: accessToken as string,
        method: 'PUT',
        data: { label }
      });
      await dispatch(getLinkedAccounts());
      setSubmitLoading(false);
      toastSuccess(i18n.t('labelUpdatedSuccessfully'));
      setCurrentLabel('');
      setEditingLabel(false);
    } catch (error: any) {
      toastError(`${i18n.t('contactUs')}. ${error}`);
    } finally {
      setSubmitLoading(false);
    }
  };

  const getPkAndDeleteAccount = async (wrappedDek: string) => {
    const data = await getUserPrivateKey(
      wrappedDek,
      selectedWallet.id,
      accessToken,
      !!selectedWallet.readOnly
    );
    if (!data?.data) throw new Error('Error in getting private key');
    data?.data?.privateKey && setPrivateKey(data.data.privateKey);
    data?.data?.starkPrivateKey &&
      setStarkPrivateKey(data.data.starkPrivateKey);
    if (action === WalletActions.DELETE) {
      await callFunction({
        url: `/user/wallets/${selectedWallet.id}` as string,
        accessToken: accessToken as string,
        method: 'DELETE'
      });
      toastSuccess(i18n.t('walletRemovedSuccessfully'));
    }
  };

  const getUserPrivateKey = async (
    DEK: string,
    walletId: string,
    accessToken: string | undefined,
    readOnly: boolean
  ) => {
    if (readOnly || !accessToken) return;
    const { data: response } = await callFunction({
      url: `/user/wallets/privateKey/${walletId}`,
      accessToken: accessToken,
      method: 'POST',
      data: { DEK }
    });
    const data = await verifyJWT(response);
    return { data };
  };

  const handleError = () => {
    toastError(
      `${i18n.t('somethingWentWrongImportingPK')}. ${i18n.t('contactUs')}`
    );
  };

  const handleLabelEdit = () => {
    setLabel(currentLabel);
    setEditingLabel(true);
  };

  enum TabIndex {
    COPY = 0,
    EXPLORER = 1,
    SCAN = 2,
    EXPORT = 3,
    DELETE = 4
  }

  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.COPY);

  return (
    <>
      <Modal
        show={showMenu}
        className="dark bg-black bg-opacity-50"
        onClose={() => {
          setShowMenu(false);
          setSelectedWallet(null);
          setPrivateKey('');
          setStarkPrivateKey('');
          if (action === WalletActions.DELETE) {
            setAction(null);
            dispatch(getLinkedAccounts());
          }
        }}
      >
        <Modal.Header className="bg-gray-800 border-gray-800">
          <TabsComponent
            onActiveTabChange={(index) => setTabIndex(index)}
            theme={customTheme}
            aria-label="Default tabs"
            style="pills"
          >
            <Tabs.Item active title="Copy" icon={ClipboardDocumentCheckIcon}>
              <div className="flex flex-col gap-4">
                <PrimaryButton
                  title={selectedWallet.address}
                  icon={<ClipboardDocumentCheckIcon className="w-4 h-4" />}
                  onClick={() => handleCopyAddress(selectedWallet.address)}
                />
                {role === MerchantRoles.OWNER && editingLabel ? (
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      if (label === selectedWallet.label) return;
                      updateWalletLabel(label);
                    }}
                    className="flex w-full gap-2 align-middle items-center"
                  >
                    <Input
                      className="text-gray-400 text-xs font-medium gap-2 align-middle items-center py-3 bg-gray-700 border-gray-500 focus:ring-gray-500 focus:border-gray-500"
                      type="text"
                      value={label}
                      onChange={(event) => setLabel(event.target.value)}
                    />
                    <div>
                      <PrimaryButton
                        type="submit"
                        disabled={submitLoading}
                        title={i18n.t('save')}
                        className="text-xs"
                      />
                    </div>
                  </form>
                ) : role === MerchantRoles.OWNER && currentLabel ? (
                  <div className="flex w-full gap-2 align-middle items-center">
                    <p
                      onClick={handleLabelEdit}
                      className="border sm:text-sm rounded-lg flex p-2.5 py-3 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                    >
                      {currentLabel}
                    </p>
                    <PencilSquareIcon
                      className="flex w-6 h-6 cursor-pointer text-gray-300"
                      onClick={handleLabelEdit}
                    />
                  </div>
                ) : (
                  <>
                    <Tooltip
                      content={
                        role !== MerchantRoles.OWNER
                          ? i18n.t('notOwner')
                          : 'Add Label'
                      }
                    >
                      <PrimaryButton
                        disabled={submitLoading || role !== MerchantRoles.OWNER}
                        title="Add Label"
                        onClick={() => setEditingLabel(true)}
                      />
                    </Tooltip>
                  </>
                )}
              </div>
            </Tabs.Item>
            <Tabs.Item title="Explorer" icon={EyeIcon}>
              <div className="flex w-1/2">
                <PrimaryButton
                  title={'View account on Explorer'}
                  icon={<EyeIcon className="w-4 h-4" />}
                  onClick={() => viewAccountOnExplorer(selectedWallet)}
                />
              </div>
            </Tabs.Item>
            <Tabs.Item title="Scan" icon={QrCodeIcon}>
              <div className="flex w-1/2">
                <PrimaryButton
                  title={'Scan QR Code'}
                  icon={<QrCodeIcon className="w-4 h-4" />}
                  onClick={() => setShowQRCode((showQRCode) => !showQRCode)}
                />
              </div>
            </Tabs.Item>
            <Tabs.Item title="Export" icon={LockOpenIcon}>
              <div className="flex w-1/2">
                <PrimaryButton
                  title={'Export Private Key'}
                  icon={<LockOpenIcon className="w-4 h-4" />}
                  onClick={() => {
                    setAction(WalletActions.PRIVATE_KEY), setModal(true);
                  }}
                />
              </div>
            </Tabs.Item>
            <Tabs.Item title="Delete" icon={TrashIcon}>
              <Tooltip
                className=""
                content={
                  !selectedWallet.isImported
                    ? i18n.t('sphereWalletCannotBeRemoved')
                    : i18n.t('removeWalletTooltip')
                }
              >
                <PrimaryButton
                  title={'Delete Wallet'}
                  icon={<TrashIcon className="w-4 h-4" />}
                  disabled={!selectedWallet.isImported}
                  onClick={async () => {
                    selectedWallet.readOnly
                      ? await removeReadOnlyWallet({
                          walletSelected: selectedWallet
                        })
                      : (setAction(WalletActions.DELETE), setModal(true));
                  }}
                />
              </Tooltip>
            </Tabs.Item>
          </TabsComponent>
        </Modal.Header>
        <Modal.Body className="bg-gray-800 border-gray-700 border-t px-5">
          {tabIndex === TabIndex.SCAN && showQRCode && (
            <div className="flex flex-1 w-full min-h-[300px] justify-center">
              <div className="flex items-center justify-center w-full h-full">
                <QRCode value={selectedWallet.address} size={300} />
              </div>
            </div>
          )}
          <div className="flex flex-col gap-4">
            <div className="flex justify-between text-white">
              {(tabIndex === TabIndex.EXPORT || tabIndex === TabIndex.DELETE) &&
                privateKey && (
                  <div className="overflow-hidden">
                    <PrimaryButton
                      title={`Copy your private key ${privateKey
                        .split('')
                        .slice(0, 10)
                        .join('')}...`}
                      icon={<ClipboardDocumentCheckIcon className="w-4 h-4" />}
                      onClick={() => handleCopyAddress(privateKey)}
                    />
                  </div>
                )}
              {(tabIndex === TabIndex.EXPORT || tabIndex === TabIndex.DELETE) &&
                starkPrivateKey && (
                  <>
                    <div className="overflow-hidden">
                      <PrimaryButton
                        title={`Copy your stark private key ${starkPrivateKey
                          .split('')
                          .slice(0, 6)
                          .join('')}...`}
                        icon={
                          <ClipboardDocumentCheckIcon className="w-4 h-4" />
                        }
                        onClick={() => handleCopyAddress(starkPrivateKey)}
                      />
                    </div>
                  </>
                )}
            </div>

            {/* Chains list */}
            <div className="flex flex-col ">
              <div className="flex flex-wrap">
                {selectedWallet.chains.map((chainName) => (
                  <div
                    className="m-2 bg-gray-700 flex mx-1 rounded py-1 px-2 mb-1 align-middle items-center"
                    key={chainName}
                  >
                    <img
                      className="w-6 h-6 mr-2 rounded-full"
                      src={mapLogoToChain(chainName, chainLogos).uri}
                      alt={chainName}
                    />

                    <p className="text-gray-400 ml-1">{chainName}</p>
                  </div>
                ))}
              </div>
              {selectedWallet.missingStarkKey && (
                <p className="mt-4 text-base text-colorYellow">
                  {i18n.t('missingStarkKey')}
                </p>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {modal && (
        <InsertPinCode
          closeModal={() => setModal(false)}
          text={
            action === WalletActions.PRIVATE_KEY
              ? i18n.t('exportKeyPinCodeText')
              : i18n.t('deleteWalletPinCodeText')
          }
          warning={
            action === WalletActions.PRIVATE_KEY
              ? i18n.t('exportKeyWarning')
              : i18n.t('alertTitleBeforeRemoveLinkedAccount')
          }
          title={
            action === WalletActions.PRIVATE_KEY
              ? `${i18n.t('exportKeyPinCodeText').charAt(0).toUpperCase()}${i18n
                  .t('exportKeyPinCodeText')
                  .slice(1)}`
              : `${i18n
                  .t('deleteWalletPinCodeText')
                  .charAt(0)
                  .toUpperCase()}${i18n.t('deleteWalletPinCodeText').slice(1)}`
          }
          callback={getPkAndDeleteAccount}
          target={TargetOperation.GET_PRIVATE_KEY}
          errorHandler={handleError}
        />
      )}
    </>
  );
};

export default WalletActions;

const customTheme = {
  base: 'flex flex-col gap-2',
  tablist: {
    base: 'flex text-center',
    styles: {
      default: 'flex-wrap border-b border-gray-200 dark:border-gray-700',
      underline:
        'flex-wrap -mb-px border-b border-gray-200 dark:border-gray-700',
      pills:
        'flex-wrap font-medium text-sm text-gray-500 dark:text-gray-400 space-x-2',
      fullWidth:
        'w-full text-sm font-medium divide-x divide-gray-200 shadow grid grid-flow-col dark:divide-gray-700 dark:text-gray-400 rounded-none'
    },
    tabitem: {
      base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:ring-1 focus:ring-gray-700 focus:outline-none',
      styles: {
        default: {
          base: 'rounded-t-lg',
          active: {
            on: 'bg-gray-100 text-cyan-600 dark:bg-gray-800 dark:text-cyan-500',
            off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800  dark:hover:text-gray-300'
          }
        },
        underline: {
          base: 'rounded-t-lg',
          active: {
            on: 'text-cyan-600 rounded-t-lg border-b-2 border-cyan-600 active dark:text-cyan-500 dark:border-cyan-500',
            off: 'border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300'
          }
        },
        pills: {
          base: '',
          active: {
            on: 'rounded-lg bg-gray-600 text-white border-gray-600 dark:bg-gray-600 dark:text-white dark:border-gray-600',
            off: 'rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white'
          }
        },
        fullWidth: {
          base: 'ml-0 first:ml-0 w-full rounded-none flex',
          active: {
            on: 'p-4 text-gray-900 bg-gray-100 active dark:bg-gray-700 dark:text-white rounded-none',
            off: 'bg-white hover:text-gray-700 hover:bg-gray-50 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700 rounded-none'
          }
        }
      },
      icon: 'mr-2 h-5 w-5'
    }
  },
  tabitemcontainer: {
    base: '',
    styles: {
      default: '',
      underline: '',
      pills: '',
      fullWidth: ''
    }
  },
  tabpanel: 'py-3'
};
