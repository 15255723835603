import React from 'react';
import { Configuration, FrontendApi } from '@ory/client';
import { useAuth } from 'react-oidc-context';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/20/solid';

import i18n from '../../../config/languageInternationalization';
import { useAppDispatch } from '../../../redux/hooks';
import { signOut } from '../../../redux/slices/user';
import { toastError } from '../../../utils/toastify';

const audience = process.env.REACT_APP_AUTH_AUDIENCE as string;

// Here we initialize the SDK to create the logout flow and update it
// with the logout token. This is required to log the user out from ory session.
const oryClient = new FrontendApi(
  new Configuration({
    basePath: audience,
    baseOptions: {
      withCredentials: true
    }
  })
);

export const logout = async (signoutSilent: any, logoutURL?: string) => {
  if (!logoutURL) logoutURL = '/';
  try {
    // Get the user session. Normally this should not fail, but if it does
    // we will just ignore it. And log the user out from the app.
    const session = await oryClient.toSession();

    if (session) {
      const res = await oryClient.createBrowserLogoutFlow();
      await oryClient.updateLogoutFlow({
        token: res.data.logout_token
      });
    }

    signoutSilent();
    return (window.location.href = logoutURL);
  } catch (error) {
    console.error(error);
    signoutSilent();
    return (window.location.href = logoutURL);
  }
};

const LogOutButton = ({ showTitle }: { showTitle: boolean }) => {
  const dispatch = useAppDispatch();
  const { signoutSilent } = useAuth();
  const handdleLogout = async () => {
    try {
      dispatch(signOut());
      return logout(signoutSilent);
    } catch (error) {
      toastError(i18n.t('somethingWentWrong'));
    }
  };

  return (
    <button
      className="text-white hover:text-itemHover cursor-pointer flex mr-8"
      onClick={handdleLogout}
    >
      <ArrowRightOnRectangleIcon className="mr-3 text-[24px] h-6 w-6" />
      {showTitle && i18n.t('logout')}
    </button>
  );
};

export default LogOutButton;
