import React from 'react';
import numberWithCommas from '../../utils/formatTokens';

const BalancesHeader = ({
  totalBalance
}: {
  totalBalance: number | undefined;
}) => {
  const displayTotalBalance = (totalBalance: number | undefined) => {
    if (totalBalance) {
      return `${numberWithCommas(totalBalance.toString())} USD`;
    } else {
      return `0 USD`;
    }
  };

  return (
    <div className="flex flex-col justify-between border-b rounded-t-md p-8 bg-card-bg border-b-card-stroke">
      <div className=" md:m-0">
        <p className="text-white mb-[6px]">TOTAL</p>
        <p className="text-4xl text-white md:text-6xl">
          {displayTotalBalance(totalBalance)}
        </p>
      </div>
    </div>
  );
};

export default BalancesHeader;
