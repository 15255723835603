import React from 'react';
import { Table } from 'flowbite-react';
import i18n from '../../../config/languageInternationalization';
import { Loader } from '../loader';

export const LoadingTableRow = (
  {
    tableBodyColor,
    activeColor,
    cellsToComplete
  }: {
    tableBodyColor: string;
    activeColor: string;
    cellsToComplete: number;
  } = {
    tableBodyColor: '#1f2937',
    activeColor: 'white',
    cellsToComplete: 4
  }
) => {
  return (
    <Table.Row
      style={{
        backgroundColor: tableBodyColor,
        color: activeColor
      }}
    >
      <Table.Cell className="whitespace-nowrap font-medium text-white">
        <div className="flex">
          <Loader />
          <span>{i18n.t('loading')}...</span>
        </div>
      </Table.Cell>

      {[...Array(cellsToComplete)].map((e, i) => (
        <Table.Cell key={i} />
      ))}
    </Table.Row>
  );
};
