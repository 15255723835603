import React from 'react';
import { Icons } from '../../assets/icons';

export default function Select(props: any) {
  const { className, children, style, id, ...otherProps } = props;
  return (
    <select
      id={id}
      style={{
        ...style,
        backgroundImage: `url(${Icons.selectIcon})`,
        backgroundPosition: 'right .75rem center',
        backgroundSize: '16px 12px'
      }}
      className={`appearance-none bg-clip-padding bg-no-repeat transition ease-in-out m-0  focus:outline-none pr-10 bg-card-bg border border-card-stroke rounded-lg text-gray-300 py-[10px] px-4 text-base focus:ring-1 focus:ring-blue-100 ${className}`}
      {...otherProps}
    >
      {children}
    </select>
  );
}
