import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import i18n from '../../../config/languageInternationalization';
import { MerchantRoles } from '../../../utils/types';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

interface AddMemberComponentProps {
  memberToAdd: string;
  roleToAdd: string;
  setMemberToAdd: Dispatch<SetStateAction<string>>;
  setRoleToAdd: Dispatch<SetStateAction<string>>;
  handleOnSubmit: () => Promise<void>;
  loading: boolean;
  error: string;
  showAddMember: boolean;
  setShowAddMember: Dispatch<SetStateAction<boolean>>;
  emptyRole: string;
  setError: Dispatch<SetStateAction<string>>;
}

export const AddMemberComponent: React.FC<AddMemberComponentProps> = ({
  memberToAdd,
  roleToAdd,
  setMemberToAdd,
  setRoleToAdd,
  handleOnSubmit,
  loading,
  error,
  showAddMember,
  setShowAddMember,
  emptyRole,
  setError
}) => {
  return (
    <div>
      {showAddMember && (
        <>
          <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-8"
            onClick={() => {
              setShowAddMember(false);
              setError('');
            }}
          ></div>
          <div className="absolute z-50 flex flex-1 w-1/2 h-fit top-0 right-0 left-0 bottom-0 m-auto bg-card-bg rounded-lg">
            <div className="bg-card-bg border border-gray-800 text-white p-6 rounded-md flex-1 flex">
              <form
                className="flex-1 gap-6 flex flex-col justify-center items-center"
                onSubmit={async (e) => {
                  try {
                    e.preventDefault();
                    await handleOnSubmit();
                  } catch (error: string | any) {
                    setError(error.message || error);
                  }
                }}
              >
                <label
                  htmlFor="email"
                  className="w-full flex flex-col text-white font-medium text-sm"
                >
                  {i18n.t('email')}:
                  <input
                    id="email"
                    placeholder={i18n.t('placeholderAddMemberEmail')}
                    value={memberToAdd || ''}
                    onChange={(e) => setMemberToAdd(e.target.value)}
                    type="email"
                    className="border-b bg-card-bg border border-card-stroke rounded-md dark:border-gray-700 mt-2"
                  />
                </label>
                <label
                  htmlFor="role"
                  className="w-full flex flex-col text-white font-medium text-sm"
                >
                  {i18n.t('role')}:
                  <select
                    id="role"
                    value={roleToAdd || emptyRole}
                    onChange={(e) => setRoleToAdd(e.target.value)}
                    className="border-b bg-card-bg border border-card-stroke rounded-md dark:border-gray-700 mt-2"
                  >
                    <option value={emptyRole}>{emptyRole}</option>
                    <option value={MerchantRoles.MEMBER}>MEMBER</option>
                    <option value={MerchantRoles.ADMIN}>ADMIN</option>
                  </select>
                </label>

                <div className="flex w-1/2 gap-4 place-self-start">
                  <PrimaryButton
                    onClick={() => setShowAddMember(false)}
                    title={i18n.t('close')}
                  />

                  <PrimaryButton
                    loading={loading}
                    title={!loading ? i18n.t('addMember') : i18n.t('loading')}
                    onClick={handleOnSubmit}
                  />
                </div>
                {error && showAddMember && (
                  <p className="text-red-600">{error}</p>
                )}
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
