export const isEmptyString = (str: string) => str === '';

export const isStrShorterThan = (str: string, length: number) =>
  str.length <= length;

export const isEmail = (str: string) => /\S+@\S+\.\S+/.test(str);

export const isPhoneNumber = (str: string) =>
  /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/.test(str);

export const isValidUsername = (username: string): boolean =>
  /^[a-zA-Z][a-zA-Z0-9-_.]{2,}$/.test(username); // allow letters, numbers and -_. but not at the beginning. It must be at least 3 characters long. Matches "user", "Us3r", "us-er", and "us.er1" but not "123", "&user", "us@r", or "us er".

export const isListNullOrEmpty = (list: []) =>
  list.length == 0 || list === null || list === undefined;

export function isSafeUrl(url: string): boolean {
  const urlRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

  if (!urlRegex.test(url)) {
    return false;
  }

  if (!url.startsWith('https://')) {
    return false;
  }

  return true;
}

export function isValidUrl(url: string, httpOnly = false) {
  try {
    const newUrl = new URL(url);
    if (httpOnly) {
      return ['http:', 'https:'].includes(newUrl.protocol);
    }
    return true;
  } catch (err) {
    return false;
  }
}

export const isLocalhost = (value: string) =>
  value.toLowerCase().includes('localhost');
export const isValidRedirectUri = (value: string) =>
  isLocalhost(value) ? isValidUrl(value, true) : isValidUrl(value);

export const removeTrailingSlash = (word: string) => word.replace(/\/+$/, '');

export const validatePinCode = (pinCode: string) => {
  if (!pinCode) throw new Error('No pin code provided');
  if (pinCode.length !== 4) throw new Error('Pin code must be 4 digits long');
  if (isNaN(Number(pinCode))) throw new Error('Pin code must be a number');
};
