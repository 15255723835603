import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setPreferredChainAndToken } from '../../../redux/slices/userThunk';
import {
  MerchantRoles,
  SupportedChains,
  TokenMetadata,
  tokensInitialState
} from '../../../utils/types';
import Select from '../../ThemedComponents/Select';
import SelectTokenModal from './select-token-modal';
import i18n from '../../../config/languageInternationalization';
import { getTokens } from '../../../utils/getTokens';
import { toastError, toastSuccess } from '../../../utils/toastify';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

const SelectPreferredChain = () => {
  const dispatch = useAppDispatch();
  const { preferredToken, preferredChain, role } = useAppSelector(
    (state) => state.user
  );

  const [tokens, setTokens] = useState<any>({});
  const [hasChanges, setHasChanges] = useState(false);
  const [loading, setLoading] = useState(false);

  const [chain, setChain] = useState<string>(
    preferredChain || SupportedChains.SOLANA
  );
  const [selectedCurrency, setSelectedCurrency] = useState<TokenMetadata>(
    preferredToken || ({} as TokenMetadata)
  );

  function isValidChain(e: any) {
    return e.target.value in SupportedChains;
  }
  const saveValues = async () => {
    try {
      if (role === MerchantRoles.MEMBER) return;

      setLoading(true);

      await dispatch(
        setPreferredChainAndToken({
          token: selectedCurrency,
          chain
        })
      );

      setHasChanges(false);
      toastSuccess(i18n.t('savedSuccessfully'));
    } catch (error) {
      toastError(i18n.t('somethingWentWrongAlert') + error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTokens().then((tokens) => {
      if (tokens) setTokens(tokens);
      else setTokens(tokensInitialState);
    });
  }, []);

  return (
    <div className="w-full">
      <div className="flex justify-center flex-col">
        <div className="flex flex-col gap-2">
          <h3 className="text-gray-200 font-medium text-lg">
            {i18n.t('pleaseSelectYourPreferredChain')}
          </h3>
          <h4 className="text-gray-400 font-normal text-xs">
            {i18n.t('preferredChainHint')}
          </h4>
        </div>
        <div className="flex mt-6">
          <div className="flex space-x-4 mr-4 w-1/2">
            <Select
              data-test-id="select-preferred-chain"
              className="flex w-full"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (role === MerchantRoles.MEMBER) return;
                if (isValidChain(e)) {
                  setChain(e.target.value as SupportedChains);
                  setSelectedCurrency({} as TokenMetadata);
                }
              }}
              value={chain}
              defaultValue={chain}
            >
              {Object.keys(SupportedChains).map((e) => {
                return (
                  <option key={e} className="bg-gray-700 text-base" value={e}>
                    {e.toUpperCase()}
                  </option>
                );
              })}
            </Select>

            <SelectTokenModal
              tokens={tokens[chain]}
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
              setHasChanges={setHasChanges}
            />
          </div>
          {/* SAVE BUTTON */}
          <>
            {role !== MerchantRoles.MEMBER && hasChanges && (
              <div>
                <PrimaryButton
                  onClick={() => saveValues()}
                  disabled={loading}
                  title={i18n.t('saveChanges')}
                />
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default SelectPreferredChain;
