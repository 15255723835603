import SelectIcon from './select.svg';
import { ReactComponent as CopyIcon } from './copy.svg';
import { ReactComponent as EyeIcon } from './eye.svg';
import { ReactComponent as FileCopyIcon } from './file-copy.svg';
import { ReactComponent as TrashIcon } from './trash.svg';

export const Icons = {
  selectIcon: SelectIcon,
  copyIcon: CopyIcon,
  eyeIcon: EyeIcon,
  fileCopyIcon: FileCopyIcon,
  trashIcon: TrashIcon
};
