import React from 'react';
import { Card } from './DisplayCards';
import { useWindowSize } from '../../../hooks/useWindowsSize';

const StrengthsCard = ({ item }: { item: Card }) => {
  const [width, height] = useWindowSize();
  const isSmallHeight = height < 800 || (width && width < 1024);
  return (
    <div
      className={`mb-6 md:mb-14 flex ${isSmallHeight ? 'flex' : 'flex-col'}`}
    >
      <img className="w-12 pb-6 lg:w-14" src={item.img} alt="title-image" />
      <div className="mx-2">
        <h2 className="pb-2 font-bold text-xl lg:text-2xl tracking-[-0.44px] text-white">
          {item.h2}
        </h2>
        <p className="max-w-md font-normal lg:text-lg tracking-[1%] text-gray-300">
          {item.p}
        </p>
      </div>
    </div>
  );
};

export default StrengthsCard;
