import React from 'react';
import { useAppSelector } from '../../../redux/hooks';
import SelectPreferredChain from './select-prefered-chain';
import MakeTransferButton from './transfer-modal';
import { MerchantRoles } from '../../../utils/types';
import FeeSelector from './fee-selector';

const TokenTransferScreen: React.FunctionComponent = (): React.ReactElement => {
  const { testEnvironment, role } = useAppSelector((state) => state.user);

  return (
    <div className={`p-4 rounded-lg border bg-gray-800 border-gray-700`}>
      {!testEnvironment && <SelectPreferredChain />}
      {role === MerchantRoles.OWNER && (
        <div className="flex flex-col md:flex-row my-4 gap-4 place-items-start">
          <MakeTransferButton />
          <FeeSelector />
        </div>
      )}
    </div>
  );
};

export default TokenTransferScreen;
