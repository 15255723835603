import React from 'react';
import { Button as FlowbiteButton, ButtonProps } from 'flowbite-react';
import { AiOutlineLoading } from 'react-icons/ai';

type ButtonWithIconProps = {
  children: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
} & ButtonProps;

export const Button: React.FC<ButtonWithIconProps> = ({
  children,
  className,
  type = 'button',
  onClick,
  ...props
}) => {
  return (
    <FlowbiteButton
      className={`bg-blue-600 hover:bg-blue-700 text-white ${className}`}
      color="transparent"
      onClick={onClick}
      type={type}
      processingSpinner={<AiOutlineLoading className="h-5 w-5 animate-spin" />}
      {...props}
    >
      {children}
    </FlowbiteButton>
  );
};
