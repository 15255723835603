import React, { ButtonHTMLAttributes, useState } from 'react';
import useCustomTheme from '../use-custom-theme';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  className?: string;
  icon?: React.ReactElement;
}

export const PrimaryButton: React.FC<ButtonProps> = ({
  title,
  onClick,
  loading,
  disabled,
  className,
  icon,
  ...rest
}) => {
  const { getButtonColor, getButtonHoverColor } = useCustomTheme();

  const theme = getButtonColor();
  const hoverTheme = getButtonHoverColor();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyles = isHovered ? hoverTheme : theme;

  return (
    <button
      disabled={loading || disabled}
      type="button"
      onClick={onClick}
      className={`text-gray-400 border bg-gray-800 border-gray-600 hover:text-white focus:text-white  focus:ring-0 focus:ring-transparent  focus:bg-primary-700 hover:bg-primary-600 transition-all disabled:bg-gray-500 hover:disabled:bg-gray-600 disabled:cursor-not-allowed flex w-full py-3 px-3 font-medium rounded-lg justify-center items-center align-middle ${
        className ? className : ''
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={buttonStyles}
      {...rest}
    >
      <p
        className={`${
          loading ? 'animate-pulse' : ''
        } text-sm flex text-center justify-center font-medium leading-5 self-stretch grow whitespace-nowrap gap-1 align-middle items-center ${
          className ? className : ''
        }`}
      >
        {icon} {title}
      </p>
    </button>
  );
};
