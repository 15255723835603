import React, { ReactElement, useEffect } from 'react';
import { SidebarLayout } from '../layout/Sidebar';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { VectorMap } from '@react-jvectormap/core';
import { worldMill } from '@react-jvectormap/world';
import axios from 'axios';
import { useAppSelector } from '../redux/hooks';
import i18n from '../config/languageInternationalization';

interface TimeSeriesData {
  date: string;
  value: number;
}

export default function Home() {
  const to = new Date();
  const from = new Date();
  from.setDate(from.getDate() - 7);
  const [period, setPeriod] = React.useState<number>(7);
  const { accessToken, idToken } = useAppSelector((state) => state.user);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [totalSales, setTotalSales] = React.useState<{
    total: number;
    data: TimeSeriesData[];
  }>({ total: 0, data: fillPeriodWithZero(from, to) });
  const [repeatCustomerRate, setRepeatCustomerRate] = React.useState<{
    total: number;
    data: TimeSeriesData[];
  }>({ total: 0, data: fillPeriodWithZero(from, to) });
  const [averageOrderValue, setAverageOrderValue] = React.useState<{
    total: number;
    data: TimeSeriesData[];
  }>({ total: 0, data: fillPeriodWithZero(from, to) });
  const [totalOrders, setTotalOrders] = React.useState<{
    total: number;
    data: TimeSeriesData[];
  }>({ total: 0, data: fillPeriodWithZero(from, to) });
  const [purchasesByLocation, setPurchasesByLocation] = React.useState<
    { name: string; value: number }[]
  >([]);
  const [productsByUnitsSold, setProductsByUnitsSold] = React.useState<
    { name: string; value: number }[]
  >([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const to = new Date();
        const from = new Date();
        from.setDate(from.getDate() - period);

        const res = await axios.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/merchant/analytics?from=${getFormattedDateString(
            from
          )}&to=${getFormattedDateString(to)}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'x-id-token': idToken
            }
          }
        );

        const sumReduce = (accumulator: number, currentValue: number) =>
          accumulator + currentValue;

        const totalSales = res.data.totalSales as TimeSeriesData[];
        const repeatCustomerRate = res.data
          .repeatCustomerRate as TimeSeriesData[];
        const averageOrderValue = res.data
          .averageOrderValue as TimeSeriesData[];
        const totalOrders = res.data.totalOrders as TimeSeriesData[];

        setTotalSales({
          total: totalSales.map((i) => i.value).reduce(sumReduce),
          data: totalSales
        });
        setRepeatCustomerRate({
          total: Math.floor(
            totalSales.map((i) => i.value).reduce(sumReduce) / period
          ), // average
          data: repeatCustomerRate
        });
        setAverageOrderValue({
          total: Math.floor(
            averageOrderValue.map((i) => i.value).reduce(sumReduce) / period
          ), // average
          data: averageOrderValue
        });
        setTotalOrders({
          total: totalOrders.map((i) => i.value).reduce(sumReduce),
          data: totalOrders
        });
        setPurchasesByLocation(
          res.data.purchasesByLocation.map(
            (v: { index: number; countryCode: string; value: number }) => ({
              name: v.countryCode,
              value: v.value
            })
          )
        );
        setProductsByUnitsSold(
          res.data.topProductsByUnitsSold.map(
            (v: { index: number; id: string; value: number }) => ({
              name: v.id,
              value: v.value
            })
          )
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [period]);

  return (
    <SidebarLayout>
      <div className="flex items-center justify-between mb-4 min-h-[40px]">
        <h1 className="text-gray-400 font-semibold text-2xl">Overview</h1>
        <select
          onChange={(event) => setPeriod(Number(event.target.value))}
          className="bg-gray-800 border border-gray-700 text-gray-400 rounded-md"
          value={period}
        >
          <option value={30}>Last 30 days</option>
          <option value={14}>Last 14 days</option>
          <option value={7}>Last 7 days</option>
        </select>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <AnalyticsGraph
            isLoading={loading}
            data={totalSales.data}
            title={i18n.t('totalSales')}
            totalStr={`$${totalSales.total}`}
            height={450}
            interval={Math.floor(period / 7)}
          />
          <AnalyticsGraph
            isLoading={loading}
            data={averageOrderValue.data}
            title={i18n.t('averageOrderValue')}
            totalStr={`$${averageOrderValue.total}`}
            interval={Math.floor(period / 7)}
          />
          <TopProductsByUnitsSoldGraph
            isLoading={loading}
            topProducts={productsByUnitsSold}
          />
        </div>
        <div className="flex flex-col gap-4">
          <AnalyticsGraph
            isLoading={loading}
            data={repeatCustomerRate.data}
            title={i18n.t('repeatCustomerRate')}
            totalStr={`${repeatCustomerRate.total}%`}
            interval={Math.floor(period / 7)}
          />
          <AnalyticsGraph
            isLoading={loading}
            data={totalOrders.data}
            title={i18n.t('totalOrders')}
            totalStr={`${totalOrders.total}`}
            interval={Math.floor(period / 7)}
          />
          <PurchasesByLocation
            isLoading={loading}
            topLocations={purchasesByLocation}
          />
        </div>
      </div>
    </SidebarLayout>
  );
}

function AnalyticsGraph({
  title,
  totalStr,
  data,
  height = 400,
  interval = 0,
  isLoading = true
}: {
  title: string;
  totalStr: string;
  data: any[];
  height?: number;
  interval?: number;
  isLoading?: boolean;
}): ReactElement {
  return (
    <div className="bg-gray-800 rounded-md">
      <div className="space-y-4 px-6 py-4 border-b border-b-gray-700">
        <h2 className="text-xl text-white font-medium">{title}</h2>
        {isLoading ? (
          <div className="h-4 w-48 animate-pulse bg-gray-700 rounded"></div>
        ) : (
          <h3 className="text-2xl text-white font-bold">{totalStr}</h3>
        )}
      </div>
      {isLoading ? (
        <LoadingGraph />
      ) : (
        <div className={`py-5`}>
          <span className="text-[#9CA3AF] ml-4 text-sm">{title}</span>

          <ResponsiveContainer width="100%" height={height}>
            <LineChart
              data={data}
              margin={{
                bottom: 20,
                top: 20,
                left: 0,
                right: 34
              }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis
                angle={-45}
                dataKey="date"
                stroke="#9CA3AF"
                axisLine={false}
                fontSize={12}
                interval={interval}
              />
              <YAxis
                stroke="#9CA3AF"
                axisLine={false}
                fontSize={12}
                domain={['auto', 'auto']}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#1C64F2"
                strokeWidth={4}
                dot={{ stroke: '#1C64F2', strokeWidth: 2, r: 0 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}

function TopProductsByUnitsSoldGraph({
  topProducts = [],
  isLoading = false
}: {
  topProducts?: { name: string; value: number }[];
  isLoading?: boolean;
}): ReactElement {
  const max = Math.max(...topProducts.map((item) => item.value));

  return (
    <div className="bg-gray-800 rounded-md">
      <div className="space-y-4 px-6 py-4 border-b border-b-gray-700">
        <h2 className="text-xl text-white font-medium">
          {i18n.t('topProductsByUnitsSold')}
        </h2>
      </div>
      {isLoading ? (
        <LoadingGraph />
      ) : (
        <div className="p-5 space-y-2">
          {topProducts.map((item) => {
            return (
              <div key={item.name} className="space-y-2 w-full ">
                <div className="flex items-center">
                  {/* Label container */}
                  <div className="flex space-x-3 w-32 items-center">
                    <p className="text-gray-400 text-base ">{item.name}</p>
                  </div>
                  {/* Bar container */}
                  <ChartBar percentage={Math.floor((item.value / max) * 100)} />
                  {/* Value container */}
                  <div className="w-16 flex items-center justify-end">
                    <p className="text-gray-400 text-sm">{item.value}</p>
                  </div>
                </div>
              </div>
            );
          })}
          {topProducts.length === 0 && (
            <p className="text-white">{i18n.t('noProductsIdentified')}</p>
          )}
        </div>
      )}
    </div>
  );
}

function PurchasesByLocation({
  topLocations = [],
  isLoading = false
}: {
  topLocations?: { name: string; value: number }[];
  isLoading?: boolean;
}): ReactElement {
  const max = Math.max(...topLocations.map((item) => item.value));

  const mapData: { [key: string]: number } = {};
  topLocations.forEach((item) => {
    const percentage = Math.floor((item.value / max) * 100);
    mapData[item.name] = percentage;
  });

  return (
    <div className="bg-gray-800 rounded-md">
      <div className="space-y-4 px-6 py-4 border-b border-b-gray-700">
        <h2 className="text-xl text-white font-medium">
          {i18n.t('topLocationsByPurchases')}
        </h2>
      </div>
      {isLoading ? (
        <LoadingGraph />
      ) : (
        <>
          <div className="px-4">
            <VectorMap
              map={worldMill}
              backgroundColor="transparent"
              zoomOnScroll={false}
              style={{
                width: '100%',
                height: '300px'
              }}
              regionStyle={{
                initial: {
                  fill: '#374151',
                  fillOpacity: 0.9,
                  stroke: 'none',
                  strokeWidth: 0,
                  strokeOpacity: 0
                },
                hover: {
                  fillOpacity: 0.8,
                  cursor: 'pointer'
                }
              }}
              regionsSelectable={false}
              series={{
                regions: [
                  {
                    values: mapData, // Countries values
                    scale: ['#93C5FD', '#2563EB'],
                    attribute: 'fill' // fill is a default attribute for bubble maps
                  }
                ]
              }}
            />
          </div>
          <div className="p-5 space-y-2">
            {topLocations.map((item) => {
              return (
                <div key={item.name} className="space-y-2 w-full ">
                  <div className="flex items-center">
                    {/* Label container */}
                    <div className="flex space-x-3 w-32 items-center">
                      <img
                        className="h-4 w-6"
                        alt={item.name}
                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${item.name}.svg`}
                      />
                      <p className="text-white text-base font-semibold">
                        {item.name}
                      </p>
                    </div>
                    {/* Bar container */}
                    <ChartBar
                      percentage={Math.floor((item.value / max) * 100)}
                    />
                    {/* Value container */}
                    <div className="w-16 flex items-center justify-end">
                      <p className="text-gray-400 text-sm">{item.value}</p>
                    </div>
                  </div>
                </div>
              );
            })}
            {topLocations.length === 0 && (
              <p className="text-white">{i18n.t('noDataAvailable')}</p>
            )}
          </div>
        </>
      )}
    </div>
  );
}

function ChartBar({ percentage }: { percentage: number }): ReactElement {
  const width = `${percentage}%`;

  return (
    <div className="w-full bg-gray-700 h-6 rounded">
      <div style={{ width }} className="bg-blue-600 h-6 rounded"></div>
    </div>
  );
}

function LoadingGraph() {
  return (
    <div className="p-4">
      <div className="animate-pulse w-full flex items-center justify-center h-48  rounded bg-gray-700">
        <svg
          className="w-10 h-10 text-gray-600"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 18"
        >
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
    </div>
  );
}

function fillPeriodWithZero(from: Date, to: Date): TimeSeriesData[] {
  const dateSequence: Date[] = [];
  const currentDate = new Date(from);
  while (currentDate <= to) {
    dateSequence.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateSequence.map((date) => {
    return {
      date: getFormattedDateString(date).slice(2).replace('-', '/'),
      value: 0
    };
  });
}

function fillPeriodWithRandom(from: Date, to: Date): TimeSeriesData[] {
  const dateSequence: Date[] = [];
  const currentDate = new Date(from);
  while (currentDate <= to) {
    dateSequence.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateSequence.map((date) => {
    return {
      date: getFormattedDateString(date).slice(2).replace('-', '/'),
      value: Math.floor(Math.random() * 100)
    };
  });
}

function getFormattedDateString(date: Date = new Date()): string {
  return `${String(date.getFullYear())}-${
    date.getMonth() + 1
  }-${date.getDate()}`;
}
