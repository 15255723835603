import BigNumber from 'bignumber.js';

function returnStringIfNotString(x: any) {
  if (typeof x != 'string') {
    return x.toString();
  } else {
    return x;
  }
}

export default function numberWithCommas(x: any) {
  const y = returnStringIfNotString(x);
  if (y.includes(`.`)) {
    const a = y.slice(0, y.indexOf('.'));
    const b = y.slice(x.indexOf('.'), y.length);
    return `${a.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${b}`;
  } else {
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

export const normalizeDecimals = (amount: string, decimals: number) => {
  const newBigNumber = new BigNumber(amount);
  const amountTokens = newBigNumber.dividedBy(10 ** decimals);
  return amountTokens;
};
