import React, { ChangeEvent, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { ArrowsUpDownIcon } from '@heroicons/react/20/solid';

import {
  MerchantRoles,
  SupportedChains,
  SupportedTestChains
} from '../../../utils/types';
import ModalRow from '../ModalRow';
import { useAppSelector } from '../../../redux/hooks';
import i18n from '../../../config/languageInternationalization';
import { chainOptions } from '../../../utils/chains';
import SelectTokenModal from './select-token-modal';
import InsertPinCode from '../../InsertPinCode';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

interface State {
  chain: SupportedChains;
  symbol: string;
  toAddress: string;
  tokenAddress: string;
  amount: number | string;
}

const MakeTransferButton = () => {
  const user = useAppSelector((state) => state.user);

  const {
    role,
    linkedAccounts,
    testEnvironment,
    preferredChain,
    preferredToken,
    supportedTokens,
    isPinCodeSetup
  } = user;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [txId, setTxId] = useState('');
  const [state, setState] = useState<State>({
    chain: (preferredChain as SupportedChains) || SupportedChains.POLYGON,
    symbol: preferredToken?.symbol || supportedTokens.POLYGON[0].symbol,
    tokenAddress: '',
    toAddress: '',
    amount: ''
  });

  const [errors, setErrors] = React.useState<{
    chain: string | null;
    symbol: string | null;
    tokenAddress: string | null;
    toAddress: string | null;
    amount: string | null;
  }>({
    chain: null,
    symbol: null,
    tokenAddress: null,
    toAddress: null,
    amount: null
  });

  const navigate = useNavigate();

  const testSupported = chainOptions.filter((chain) =>
    (Object.values(SupportedTestChains) as string[]).includes(chain.value)
  );

  React.useEffect(() => {
    if (testEnvironment) return;
    setState((prevState) => ({
      ...prevState,
      chain: (preferredChain as SupportedChains) || SupportedChains.POLYGON,
      symbol:
        (preferredToken?.symbol as string) || supportedTokens.POLYGON[0].symbol,
      tokenAddress:
        (preferredToken?.address as string) ||
        supportedTokens.POLYGON[0].address
    }));
  }, [preferredChain, preferredToken, user, showModal]);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedChain = !testEnvironment
      ? chainOptions.find((chain) => chain.value === e.target.value)
      : testSupported.find((chain) => chain.value === e.target.value);

    if (selectedChain) {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
        symbol: selectedChain.nativeToken as string,
        tokenAddress: selectedChain.nativeAddress as string
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value
      }));
    }

    verifyData();
  };

  const smartWalletAddress = linkedAccounts?.find(
    (account) =>
      account.chains.includes(state.chain) && account.isImported === false
  )?.address;

  const handleClose = () => {
    setShowModal(false);
    setState({
      chain:
        (user.preferredChain as SupportedChains) || SupportedChains.POLYGON,
      symbol: user.preferredToken?.symbol || '',
      toAddress: '',
      tokenAddress: user.preferredToken?.address || '',
      amount: 0
    });
    setTxId('');
  };

  const [openPinCodeModal, setOpenPinCodeModal] = useState(false);

  const verifyData = () => {
    const fieldsToCheck = [
      { key: 'amount', message: i18n.t('amountError') },
      { key: 'chain', message: i18n.t('chainError') },
      { key: 'symbol', message: i18n.t('symbolError') },
      { key: 'toAddress', message: i18n.t('toAddressError') }
    ];

    fieldsToCheck.forEach((field) => {
      if (!state[field.key as keyof State]) {
        setErrors((prevState) => ({
          ...prevState,
          [field.key]: field.message
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field.key]: null
        }));
      }
    });
  };

  const handleSubmit = async () => {
    if (
      !state.symbol ||
      !state.amount ||
      !state.toAddress ||
      !state.tokenAddress
    ) {
      verifyData();
      return;
    }

    setLoading(true);
    try {
      const chargeData = JSON.stringify({
        chargeData: {
          symbol: state.symbol,
          chain: state.chain,
          tokenAddress: state.tokenAddress,
          toAddress: state.toAddress,
          amount: state.amount
        },
        isTest: testEnvironment,
        isDirectTransfer: true
      });

      const createChargeResponse: AxiosResponse = await axios.request({
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}/createCharge`,
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': testEnvironment ? user.testApiKey : user.apiKey
        },
        data: chargeData
      });
      const transactionId = createChargeResponse.data.data.chargeId;
      setTxId(transactionId);

      if (!isPinCodeSetup) {
        if (role === MerchantRoles.OWNER) {
          return navigate('/user/pincode');
        } else
          return alert(
            'Pin code is not setup and you are not the owner of this merchant. Please contact the owner to setup pin code.'
          );
      }

      setOpenPinCodeModal(true);
    } catch (error: any) {
      if (error.message === 'Request failed with status code 401')
        window.alert(i18n.t('sessionExpired'));
      else
        window.alert(
          `Payment Failed: ${
            error.response.data.error ? error.response.data.error : error
          }`
        );
    } finally {
      setLoading(false);
    }
  };

  const insertPinCodeCallback = async (wrappedDek: string) => {
    setLoading(true);
    try {
      const payData = JSON.stringify({
        transactionId: txId,
        wrappedDek
      });

      await axios.request({
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}/pay/route`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`
        },
        data: JSON.stringify({ transactionId: txId })
      });

      const payResponse = await axios.request({
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}/pay`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.accessToken}`
        },
        data: payData
      });
      window.alert(
        `Transaction created, current status: ${payResponse.data.data.status}`
      );
    } catch (e: any) {
      window.alert(`Payment Failed: ${e}`);
      setErrors(e);
    } finally {
      setLoading(false);
    }
  };

  const errorHandler = (error: string) => {
    if (error === 'Request failed with status code 401')
      window.alert(i18n.t('sessionExpired'));
    else window.alert(`Payment Failed: ${error}`);
  };

  if (role !== MerchantRoles.OWNER) return <></>;
  return (
    <div>
      <PrimaryButton
        title={i18n.t('makeATransfer')}
        onClick={() => setShowModal(true)}
        icon={<ArrowsUpDownIcon className="h-4 w-4" />}
      />
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-900 bg-opacity-30">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 p-8 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-800 outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between rounded-t">
                  <h3 className="text-gray-50 text-xl font-medium leading-loose">
                    Send Money
                  </h3>
                  {testEnvironment && (
                    <span className="rounded-lg shadow-lg text-gray-300 bg-red-800 p-2">
                      {`${
                        state.chain === SupportedChains.SOLANA
                          ? `${state.chain} Devnet`
                          : `${state.chain} Testnet`
                      }`}
                    </span>
                  )}
                </div>
                {testEnvironment && (
                  <div className="flex flex-row justify-center">
                    <p className="text-white text-sm mt-2 text-center">
                      {i18n.t('testMakeTransferSubtitle', {
                        symbol: chainOptions.find(
                          (c) => c.value === state.chain
                        )?.nativeToken as string,
                        chain: state.chain,
                        env:
                          state.chain === SupportedChains.SOLANA
                            ? 'dev'
                            : 'test'
                      })}{' '}
                      {smartWalletAddress && <p> {smartWalletAddress}</p>}
                    </p>
                  </div>
                )}
                {/*body*/}
                <ModalRow
                  id="Chain-selector"
                  label={'Destination chain'}
                  selectOptions={
                    !testEnvironment ? chainOptions : testSupported
                  }
                  defaultValue={state.chain}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  input={false}
                  name={'chain'}
                  disabled={false}
                  error={errors.chain}
                />

                {!testEnvironment ? (
                  <>
                    <div className="flex mt-5 mb-2">
                      <span className="text-gray-300">Token:</span>
                    </div>

                    <SelectTokenModal
                      tokens={supportedTokens[state.chain]}
                      selectedCurrency={{
                        symbol: state.symbol,
                        address: state.tokenAddress,
                        name: state.symbol
                      }}
                      setSelectedCurrency={(e) => {
                        setState({
                          ...state,
                          symbol: e.symbol,
                          tokenAddress: e.address
                        });
                        verifyData();
                      }}
                      transferModal={true}
                      error={errors.symbol}
                    />
                  </>
                ) : (
                  <>
                    <ModalRow
                      label={'Cryptocurrency symbol:'}
                      onChange={handleChange}
                      input={true}
                      name={'symbol'}
                      defaultValue={
                        chainOptions.find((c) => c.value === state.chain)
                          ?.nativeToken as string
                      }
                      readOnly={true}
                    />
                    <ModalRow
                      label={'Token address:'}
                      onChange={handleChange}
                      input={true}
                      name={'tokenAddress'}
                      defaultValue={
                        chainOptions.find((c) => c.value === state.chain)
                          ?.nativeAddress as string
                      }
                      readOnly={true}
                    />
                  </>
                )}

                <ModalRow
                  id="destination-input"
                  label={'To address:'}
                  onChange={handleChange}
                  input={true}
                  name={'toAddress'}
                  defaultValue={state.toAddress}
                  error={errors.toAddress}
                />
                <ModalRow
                  id="token-amount"
                  label={'Token amount:'}
                  onChange={handleChange}
                  input={true}
                  name={'amount'}
                  placeholder="0.123"
                  error={errors.amount}
                />
                {txId && (
                  <ModalRow
                    label={'Transaction ID:'}
                    input={true}
                    name={'txId'}
                    defaultValue={txId}
                    readOnly={true}
                  />
                )}

                {/*Close & Send Buttons*/}
                <div className="flex w-full mt-5 rounded-b">
                  <div className="flex justify-start w-1/2 gap-6">
                    <PrimaryButton
                      title={i18n.t('close')}
                      onClick={handleClose}
                      loading={loading}
                    />
                    <PrimaryButton
                      onClick={handleSubmit}
                      loading={loading}
                      title={i18n.t('send')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-30 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
      {openPinCodeModal && txId && (
        <InsertPinCode
          closeModal={() => setOpenPinCodeModal(false)}
          text={i18n.t('confirmPay', {
            amount: state.amount,
            symbol: state.symbol,
            toAddress: state.toAddress
          })}
          warning={i18n.t('confirmPayWarning')}
          callback={insertPinCodeCallback}
          target={txId}
          errorHandler={errorHandler}
        />
      )}
    </div>
  );
};

export default MakeTransferButton;
