import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import i18n from '../../../config/languageInternationalization';
import { useAuthStatus } from '../../../hooks/useAuthStatus';
import { useAppSelector } from '../../../redux/hooks';
import { useAuth } from 'react-oidc-context';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

const LoginBox = () => {
  const navigate = useNavigate();
  const { isNewUser } = useAuthStatus();
  const user = useAppSelector((state) => state.user);
  const auth = useAuth();
  const audience = process.env.REACT_APP_AUTH_AUDIENCE as string;

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/overview');
    }
  }, [auth.isAuthenticated]);

  return (
    <>
      <div className="place-items-center justify-center place-self-center text-white rounded-2xl flex w-1/12 min-w-[300px] bg-slate-700">
        <PrimaryButton
          autoFocus={true}
          title={auth.isLoading ? i18n.t('loading') : i18n.t('login')}
          onClick={() =>
            void auth.signinRedirect({
              scope: 'openid profile email offline_access',
              extraQueryParams: { audience: audience }
            })
          }
          loading={auth.isLoading}
        />
      </div>
      <div id="captcha-container"></div>
    </>
  );
};

export default LoginBox;
