import { loginPageImages } from '../../../assets/images/Onboarding/index';
import i18n from '../../../config/languageInternationalization';
export const cardsData = [
  {
    img: loginPageImages.globe,
    h2: i18n.t('connectWithYourBusinessApps'),
    p: i18n.t('connectWithYourBusinessAppsTextBelow')
  },
  {
    img: loginPageImages.payment,
    h2: i18n.t('offerPopularPaymentMethods'),
    p: i18n.t('offerPopularPaymentMethodsTextBelow')
  }
];
