import { configureStore } from '@reduxjs/toolkit';
import userSelector from './slices/user';

const showDevTools =
  process.env.REACT_APP_NODE_ENV === 'development' ||
  process.env.REACT_APP_NODE_ENV === undefined;

export const store = configureStore({
  reducer: {
    user: userSelector
  },
  devTools: showDevTools
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
