import React from 'react';
import i18n from '../../../config/languageInternationalization';

import { useAppDispatch } from '../../../redux/hooks';
import { callFunction } from '../../../utils/server';
import { getLinkedAccounts } from '../../../redux/slices/userThunk';
import { toastError, toastSuccess } from '../../../utils/toastify';

export enum ModalStateEnumIMX {
  INIT = 'INIT',
  INSERT_KEY = 'INSERT_KEY',
  SUCCESS = 'SUCCESS'
}

export const AddIMXStarkPrivateKey = ({
  publicKey,
  recoveryKey,
  accessToken,
  closeModal,
  starkPK,
  setStarkPK,
  starkPKError,
  setStarkPKError,
  modal,
  setModal,
  loading,
  setLoading,
  wrappedDek,
  setRecoverWalletModal
}: {
  publicKey: string;
  recoveryKey: { mnemonic: string } | { privateKey: string };
  accessToken: string;
  closeModal: () => void;
  starkPK: string;
  setStarkPK: (e: string) => void;
  starkPKError: string;
  setStarkPKError: (e: string) => void;
  modal: ModalStateEnumIMX;
  setModal: (e: ModalStateEnumIMX) => void;
  loading: boolean;
  setLoading: (e: boolean) => void;
  wrappedDek: string;
  setRecoverWalletModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const onChangeStarkPrivateKey = (e: string) => {
    setStarkPKError('');
    if (e.length <= 63) {
      setStarkPK(e);
    }
    if (!e.match(/[0-9a-fA-F]{63}/)) {
      setStarkPKError(i18n.t('addIMXStarkPrivateKeyValidation'));
    } else {
      setStarkPKError('');
    }
  };

  const dispatch = useAppDispatch();

  const ButtonCloseModal = () => {
    return (
      <button
        onClick={() => {
          closeModal();
          setRecoverWalletModal(false);
        }}
        className="text-base text-white align-self-center bg-stone-700 rounded-lg px-3 py-3"
      >
        <p>{i18n.t('close')}</p>
      </button>
    );
  };

  return (
    <div>
      <div className="bg-stone-900/90 p-6 w-3/4 rounded-xl min-w-[850px] ">
        <div className="flex justify-center">
          {modal === ModalStateEnumIMX.INIT && (
            <div className="w-3/4 flex flex-col gap-6">
              <h4 className="text-white text-center font-bold text-xl">
                {i18n.t('addIMXStarkPrivateKeyWalletSuccessfullyImported')}
              </h4>
              <p className="text-white text-center">
                {i18n.t(
                  'addIMXStarkPrivateKeyWalletSuccessfullyImportedMessage'
                )}
              </p>
              <button
                onClick={() => {
                  setModal(ModalStateEnumIMX.INSERT_KEY);
                }}
                className="text-base text-white align-self-center bg-blue-500 rounded-lg px-3 py-3"
              >
                <p>{i18n.t('addIMXStarkPrivateKeyEnterStarknetPK')}</p>
              </button>
              <ButtonCloseModal />
            </div>
          )}

          {modal === ModalStateEnumIMX.INSERT_KEY && (
            <div className="w-3/4 flex flex-col gap-6">
              <h4 className="text-white text-center font-bold text-xl">
                {i18n.t('addIMXStarkPrivateKeyPleaseEnterStarknetPK')}
              </h4>
              <input
                className={`bg-stone-900 text-white rounded-8 p-2 rounded-lg flex-row items-center border border-slate-700 w-full self-center`}
                value={starkPK || ''}
                onChange={(e) => onChangeStarkPrivateKey(e.target.value)}
                placeholder={`${i18n.t(
                  'addIMXStarkPrivateKeyPKPlaceholder'
                )}...`}
                maxLength={63}
              />
              {starkPKError && <p className="text-red-600">{starkPKError}</p>}

              <button
                className="text-white align-self-center bg-blue-500 rounded-lg px-3 py-3"
                disabled={loading || !!starkPKError}
                onClick={async () => {
                  setLoading(true);
                  try {
                    const { data } = await callFunction({
                      url: '/checkStarkPrivateKey',
                      method: 'POST',
                      data: {
                        DEK: wrappedDek,
                        publicKey,
                        recoveryKey,
                        starkPrivateKey: starkPK
                      },
                      accessToken
                    });
                    if (data) {
                      dispatch(getLinkedAccounts());
                      toastSuccess(
                        i18n.t(
                          'addIMXStarkPrivateKeyWalletSuccessfullyImported'
                        )
                      );
                      setModal(ModalStateEnumIMX.SUCCESS);
                    }
                  } catch (error: any) {
                    if (error?.message?.includes('Stark Private Key')) {
                      setStarkPKError(error.message || error);
                      setModal(ModalStateEnumIMX.INSERT_KEY);
                    } else {
                      toastError(
                        `${i18n.t('errorImportingWallet')} ${
                          error?.message || error
                        }`
                      );
                    }
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                <p>{i18n.t('verificationPhoneInputScreenConfirm')}</p>
              </button>
              <ButtonCloseModal />
            </div>
          )}

          {modal === ModalStateEnumIMX.SUCCESS && (
            <div className="w-3/4 flex flex-col gap-6">
              <p className="text-white text-center">
                {i18n.t(
                  'addIMXStarkPrivateKeySuccessfullyImportedIMXStarknetPK'
                )}
              </p>
              <p className="text-white text-center">
                {i18n.t('addIMXStarkPrivateKeySuccessfullyImportedMessage')}
              </p>

              <ButtonCloseModal />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
