import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  getChainLogos,
  getLinkedAccounts,
  getPaymentsTable,
  getTransactions,
  getUserBalances,
  getUserInfo,
  isCountrySupportedForOfframp,
  updateMemberLastActivity
} from '../redux/slices/userThunk';
import {
  setIsCreatingMerchant,
  signOut,
  updateAccessToken
} from '../redux/slices/user';
import i18n from '../config/languageInternationalization';
import { logout } from '../components/Sidebar/Account/LogOut';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { MerchantRoles } from '../utils/types';

interface AuthStatus {
  isAuthenticated: boolean;
  isAuthLoading: boolean;
  user: User | undefined | null;
  isNewUser: null | boolean;
  emailVerified: boolean | undefined;
}

export interface ProfileStatus {
  hasMerchantProfile: boolean;
  hasUserProfile: boolean;
}

export const useAuthStatus = (): AuthStatus => {
  const { user, signinSilent } = useAuth();
  const { isAuthenticated, isLoading, signoutSilent } = useAuth();
  const emailVerified = user?.profile?.email_verified;
  const dispatch = useAppDispatch();
  const [isNewUser, setNewUser] = useState(false);
  const { uid, isCreatingMerchant } = useAppSelector((state) => state.user);
  const logoutURL = `${process.env.REACT_APP_AUTH_AUTHORITY}/ui/verification?return_to=${process.env.REACT_APP_AUTH_REDIRECT_URI}`;
  const navigate = useNavigate();

  const forceLogout = async () => {
    try {
      dispatch(signOut());
      return logout(signoutSilent);
    } catch (error) {
      alert(i18n.t('somethingWentWrong'));
    }
  };

  const handleExpiredUser = async () => {
    if (user?.refresh_token) {
      const newUser = await signinSilent();
      if (!newUser) {
        return forceLogout();
      }
    } else {
      return forceLogout();
    }
  };

  const handleUnauthenticatedUser = async () => {
    await forceLogout();
    alert(i18n.t('somethingWentWrong'));
  };

  const handleAuthentication = async () => {
    try {
      if (!user) return;

      if (!emailVerified) {
        dispatch(signOut());
        alert(i18n.t('verifyEmailToUse'));
        logout(signoutSilent, logoutURL);
        return;
      }

      const userData = await dispatch(
        getUserInfo({
          uid: user.profile?.sub as string,
          accessToken: user.access_token,
          idToken: user.id_token as string
        })
      ).unwrap();

      if (userData) {
        setNewUser(false);
        if (!userData.isPinCodeSetup && userData.role === MerchantRoles.OWNER) {
          navigate('/user/pincode');
        }
        await Promise.all([
          dispatch(getUserBalances({ refreshCache: false, testnet: false })),
          dispatch(getLinkedAccounts()),
          dispatch(getPaymentsTable()),
          dispatch(updateMemberLastActivity()),
          dispatch(isCountrySupportedForOfframp(userData.countryCode)),
          dispatch(getChainLogos()),
          dispatch(getTransactions())
        ]);
      } else {
        await forceLogout();
        alert(i18n.t('somethingWentWrong'));
      }
    } catch (error) {
      if (error === 'User not found') {
        setNewUser(true);
      }
      reportError(error);
    }
  };

  useEffect(() => {
    (async () => {
      if (isCreatingMerchant || !user) return;

      if (user?.profile && !user?.profile?.email) {
        navigate('/addEmail');
        return;
      }
      dispatch(setIsCreatingMerchant(true));
      try {
        await handleAuthentication();
        if (!user) return;

        if (user.expires_at && user.expires_at * 1000 < Date.now()) {
          await handleExpiredUser();
          return;
        }

        if (!user.id_token) {
          await handleUnauthenticatedUser();
          return;
        }

        const payloadUser: null | JwtPayload = jwtDecode(user.access_token);
        if (typeof payloadUser === 'object' && uid === payloadUser?.sub) {
          if (!user.access_token) return;
          dispatch(updateAccessToken(user.access_token));
          return;
        }
      } catch (error) {
        reportError(error);
      }
    })();
  }, [user]);

  return {
    isAuthenticated,
    isAuthLoading: isLoading,
    emailVerified,
    user,
    isNewUser
  };
};
