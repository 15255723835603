import React, { useEffect, useState } from 'react';

import ButtonsRow from './buttons-row';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import LinkedAccountsTable from './linked-accounts-table';
import { RecoverWallet } from './recover-wallet';
import {
  getChainLogos,
  getLinkedAccounts,
  getUserBalances
} from '../../../redux/slices/userThunk';

const LinkedAccounts: React.FunctionComponent = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { linkedAccounts, chainLogos, balancePerWallet } = useAppSelector(
    (state) => state.user
  );

  const [recoverWalletModal, setRecoverWalletModal] = useState(false);

  useEffect(() => {
    if (!chainLogos) dispatch(getChainLogos());
    if (!linkedAccounts) dispatch(getLinkedAccounts());
    if (!balancePerWallet)
      dispatch(getUserBalances({ refreshCache: false, testnet: false }));
  }, [chainLogos, linkedAccounts, balancePerWallet]);

  return (
    <div>
      <div className="flex">
        <ButtonsRow setRecoverWalletModal={setRecoverWalletModal} />
      </div>
      <LinkedAccountsTable
        linkedAccounts={linkedAccounts}
        chainLogos={chainLogos}
        balancePerWallet={balancePerWallet}
      />

      {recoverWalletModal && (
        <RecoverWallet
          recoverWalletModal={recoverWalletModal}
          setRecoverWalletModal={setRecoverWalletModal}
        />
      )}
    </div>
  );
};

export default LinkedAccounts;
