import React, { useEffect, useState } from 'react';
import Button from '../components/ThemedComponents/Buttons/button';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setMerchantTheme } from '../redux/slices/userThunk';
import ColorPickerComponent from '../components/ColorPicker';
import { SidebarLayout } from '../layout/Sidebar';
import i18n from '../config/languageInternationalization';
import { PrimaryButton } from '../components/ThemedComponents/Buttons/primary-button';
import { toastError } from '../utils/toastify';
import { MerchantRoles } from '../utils/types';

const SetThemeSection = () => {
  const dispatch = useAppDispatch();
  const { theme, role } = useAppSelector((state) => state.user);

  const [primaryColor, setPrimaryColor] = useState(theme?.primaryColor ?? '');
  const [logo, setLogo] = useState<string | File | undefined>(
    theme?.logo ?? undefined
  );
  const [showColorPicker, setShowColorPicker] = useState(false);

  useEffect(() => {
    setPrimaryColor(theme?.primaryColor ?? '');
    setLogo(theme?.logo ?? undefined);
  }, [theme]);

  const ShowImage = ({ logo }: { logo: string | File | undefined }) => {
    if (typeof logo === 'string' && logo.startsWith('http')) {
      // if the logo is a url (already saved in Firebase-Storage)
      return (
        <>{logo ? <img width="100px" height="100px" src={logo} /> : <div />}</>
      );
    } else if (logo instanceof File) {
      // if the logo is a file (not yet saved in Firebase-Storage, uploaded from local)
      return (
        <>
          {logo ? (
            <img width="100px" height="100px" src={URL.createObjectURL(logo)} />
          ) : (
            <div />
          )}
        </>
      );
    } else {
      return <div />;
    }
  };
  const saveTheme = async () => {
    if (!primaryColor) return toastError(i18n.t('missingColorMessage'));
    if (!logo) return toastError(i18n.t('missingLogoMessage'));

    // close color picker in case user decides not to close it before saving
    if (showColorPicker) setShowColorPicker(!showColorPicker);
    // then, save the theme to current merchant
    await dispatch(setMerchantTheme({ primaryColor, logo: logo }));
  };

  return (
    <SidebarLayout>
      {/* Header */}
      <div className="flex items-center justify-between mb-4 min-h-[40px]">
        <h1 className="text-gray-400 font-semibold text-2xl">
          {i18n.t('theme')}
        </h1>
      </div>

      {/* PRIMARY */}
      <div className="mb-6 flex flex-1">
        <div className="gap-4 border bg-card-bg p-4 rounded-md border-card-stroke md:w-2/4 flex flex-col">
          <span className="ml-2 mb-2 text-white">{i18n.t('primaryColor')}</span>
          <span className=" bg-card-bg border border-card-stroke rounded-md text-gray-300 py-[10px] px-4 text-base w-full">
            {primaryColor}
          </span>
          <div
            className={`w-full h-10 rounded-md border border-white mb-2`}
            style={{ backgroundColor: `${primaryColor}` }}
          />

          <PrimaryButton
            onClick={() => setShowColorPicker(!showColorPicker)}
            title={showColorPicker ? i18n.t('done') : i18n.t('change')}
            autoFocus={true}
          />
        </div>
        {showColorPicker && (
          <div
            className="ml-2 mt-3 mb-2 flex flex-grow justify-center"
            data-test-id="color-picker"
          >
            <ColorPickerComponent
              chosenColor={primaryColor}
              setChosenColor={(color: string) => {
                setPrimaryColor(color);
              }}
            />
          </div>
        )}
      </div>

      {/* LOGO */}
      <div className="mb-6 flex flex-1">
        <div className="gap-4 border bg-card-bg p-4 rounded-md border-card-stroke md:w-2/4 flex flex-col">
          <span className="ml-2 text-white ">Logo</span>
          <button className="flex w-full bg-card-bg border border-card-stroke rounded-md text-gray-300 py-[10px] text-base text-center px-4 focus:ring-1 focus:ring-blue-100">
            <input
              type="file"
              name="logo"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const file = e.target.files?.[0];

                if (file) {
                  if (!file.type.startsWith('image/')) {
                    return toastError(`${i18n.t('invalidImageFileType')}`);
                  }

                  const maxSize = 5 * 1024 * 1024; // 5MB
                  if (file.size > maxSize) {
                    return toastError(`${i18n.t('imageSizeExceeded')}`);
                  }
                  setLogo(file);
                } else {
                  return toastError('File cannot be uploaded.');
                }
              }}
            />
          </button>

          <div className="ml-2 mt-2">
            <ShowImage logo={logo} />
          </div>
        </div>
      </div>

      {/* ACTION BUTTONS */}
      <div className="flex w-1/4 gap-4">
        <PrimaryButton
          title={i18n.t('saveChanges')}
          onClick={saveTheme}
          className="text-sm"
          disabled={role === MerchantRoles.MEMBER}
        />
        <PrimaryButton
          title={i18n.t('resetValues')}
          onClick={() => {
            dispatch(setMerchantTheme(null));
          }}
          className="text-sm"
          disabled={role === MerchantRoles.MEMBER}
        />
      </div>
    </SidebarLayout>
  );
};

export default SetThemeSection;
