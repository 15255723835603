import React, { useEffect } from 'react';
import i18n from '../config/languageInternationalization';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getPaymentsTable, getTransactions } from '../redux/slices/userThunk';
import {
  PageDirection,
  TransactionHistoryType,
  TxStatus
} from '../utils/types';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import TxDetailModal from './invoices/TxDetailmodal';
import { Table } from 'flowbite-react';
import useCustomTheme from './ThemedComponents/use-custom-theme';
import { PrimaryButton } from './ThemedComponents/Buttons/primary-button';
import { LoadingTableRow } from './ThemedComponents/Flowbite/loading-table-row';

export default function PaymentsTable() {
  const dispatch = useAppDispatch();

  const { transactionHistoryData, hasNextPage, loading, pageNumber } =
    useAppSelector((state) => state.user);

  const [currentTx, setCurrentTx] = React.useState<any>(null);

  useEffect(() => {
    if (!transactionHistoryData && !loading) {
      dispatch(getTransactions());
    }
  }, [transactionHistoryData, loading]);

  const paymentStatus = (status: string) => {
    switch (status) {
      case TxStatus.SUCCESS:
        return 'bg-green-500';
      case TxStatus.CANCELED:
        return 'bg-red-500';
      default:
        return 'bg-yellow-400';
    }
  };

  const { getTableHeadStyle, getTableBodyStyle, getTextColor } =
    useCustomTheme();

  const tableHeadColor = getTableHeadStyle();
  const tableBodyColor = getTableBodyStyle();
  const textColor = getTextColor();

  return (
    <>
      <div
        className="flex flex-1 h-full flex-col rounded-md bg-card-bg"
        style={{
          backgroundColor: tableBodyColor.backgroundColor,
          color: textColor,
          borderRadius: 6
        }}
      >
        <div className="flex-grow overflow-auto rounded-t-md scrollbar-thin scrollbar-thumb-card-stroke scrollbar-track-card-bg scrollbar-track-rounded-full">
          <Table>
            <Table.Head>
              <Table.HeadCell style={tableHeadColor}>
                {i18n.t('date')}
              </Table.HeadCell>
              <Table.HeadCell style={tableHeadColor}>
                {i18n.t('walletAddress')}
              </Table.HeadCell>
              <Table.HeadCell style={tableHeadColor}>
                {i18n.t('invoice')}
              </Table.HeadCell>
              <Table.HeadCell style={tableHeadColor}>
                {i18n.t('status')}
              </Table.HeadCell>
              <Table.HeadCell style={tableHeadColor}>
                {i18n.t('amount')}
              </Table.HeadCell>
              <Table.HeadCell style={tableHeadColor}></Table.HeadCell>
            </Table.Head>

            <Table.Body className="text-left font-normal text-sm divide-y">
              {!loading ? (
                <>
                  {transactionHistoryData && transactionHistoryData.length ? (
                    transactionHistoryData?.map(
                      (t: TransactionHistoryType, i: any) => (
                        <>
                          <Table.Row
                            key={i}
                            className="align-middle items-center"
                            style={{
                              backgroundColor: tableBodyColor.backgroundColor,
                              color: textColor,
                              fontSize: 14,
                              fontWeight: 400,
                              borderColor: textColor
                            }}
                          >
                            {/* DATE */}
                            <Table.Cell className="py-6">
                              {new Date(
                                Number(t.dateCreated._seconds) * 1000
                              ).toString()}
                            </Table.Cell>

                            {/* W ADDRESS */}
                            <Table.Cell>
                              {`${t.toAddress.slice(
                                0,
                                5
                              )}...${t.toAddress.slice(-5)}`}
                            </Table.Cell>

                            {/* INVOICE */}
                            <Table.Cell>{t.uid}</Table.Cell>

                            {/*STATUS */}
                            <Table.Cell className="flex-1 flex flex-row align-middle py-6 items-center">
                              <div
                                className={`h-2 w-2 mr-2 rounded-md ${paymentStatus(
                                  t.status
                                )}`}
                              />
                              <p>{t.status}</p>
                            </Table.Cell>

                            {/*AMOUNT */}
                            <Table.Cell className="text-emerald-600">
                              <p className="flex bg-emerald-900 w-fit p-1 rounded-md">
                                {t.total} {t.symbol}
                              </p>
                            </Table.Cell>

                            {/*ELIPSIS */}
                            <Table.Cell>
                              <EllipsisVerticalIcon
                                className="h-5 w-5 mr-2 hover:cursor-pointer transform hover:scale-125 active:scale-100"
                                color={tableHeadColor.backgroundColor}
                                onClick={() => {
                                  setCurrentTx(t);
                                }}
                              />
                            </Table.Cell>
                          </Table.Row>
                        </>
                      )
                    )
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={5}>
                        <h2 className=" text-base font-bold p-8">
                          {i18n.t('youHaveNoTransactionsYet')}
                        </h2>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </>
              ) : (
                <LoadingTableRow
                  tableBodyColor={tableBodyColor.backgroundColor}
                  activeColor={textColor}
                  cellsToComplete={4}
                />
              )}
            </Table.Body>
          </Table>
        </div>
        {transactionHistoryData && transactionHistoryData.length ? (
          <div
            style={{
              borderTop: '1px solid',
              borderColor: textColor
            }}
            className="flex items-center justify-end pr-4 gap-2 w-full py-4"
          >
            <div className="flex w-1/4 min-w-[300px]">
              <PrimaryButton
                onClick={() =>
                  dispatch(getPaymentsTable(PageDirection.Backward))
                }
                disabled={(pageNumber as number) <= 1}
                title="Prev"
                loading={loading}
              />

              <p className="text-white font-semibold px-4">{pageNumber}</p>
              <PrimaryButton
                onClick={() =>
                  dispatch(getPaymentsTable(PageDirection.Forward))
                }
                disabled={!hasNextPage}
                title="Next"
                loading={loading}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {currentTx && (
        <TxDetailModal
          onClose={() => setCurrentTx(null)}
          isOpen={currentTx !== null}
          transaction={currentTx}
        />
      )}
    </>
  );
}
