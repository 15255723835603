import React from 'react';

import { cardsData } from './leftSideCardsData';
import StrengthsCard from './StrengthsCard';

export type Card = {
  img: string;
  h2: string;
  p: string;
};

function DisplayCards() {
  return (
    <div className="flex flex-1 flex-col justify-center items-center align-middle">
      {cardsData.map((item: Card) => (
        <StrengthsCard item={item} key={item.h2} />
      ))}
    </div>
  );
}

export default DisplayCards;
