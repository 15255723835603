import React from 'react';
import { Link } from 'react-router-dom';

import LogOutButton from './Account/LogOut';
import { menuItem } from './MenuItemArray';
import useCustomTheme from '../ThemedComponents/use-custom-theme';
import { useAppSelector } from '../../redux/hooks';
import { MerchantRoles } from '../../utils/types';

const MenuItem = ({
  margin,
  active,
  setActive,
  open,
  hideText,
  isSmallHeight
}: {
  margin: string;
  active: string;
  setActive: any;
  open: boolean;
  hideText: string;
  isSmallHeight: boolean | 0;
}) => {
  const { getActiveBackgroundColor, getActiveTextColor } = useCustomTheme();
  const { role } = useAppSelector((state) => state.user);

  const activeBackgroundColor = getActiveBackgroundColor();
  const activeTextColor = getActiveTextColor();

  const soonText =
    'text-transparent bg-clip-text bg-gradient-to-r from-[rgba(122,203,255,1)] to-[rgba(19,112,245,1)]';

  return (
    <div>
      <ul>
        {menuItem.map((item, index) => {
          if (item.separation)
            return (
              <li key={index}>
                <div
                  className="h-0.5 bg-card-bg my-6"
                  style={{
                    marginBottom: margin,
                    marginTop: margin
                  }}
                />
              </li>
            );
          if (!item.title) return;
          if (item.title === 'Theme' && role === MerchantRoles.MEMBER) return;
          const isActive = item.title.toLowerCase() === active.toLowerCase();
          return (
            <>
              {}
              <Link key={index} to={item.link!}>
                <li
                  onClick={() => setActive(item.title)}
                  className={`duration-500 md:mb-2 lg:mb-4 flex justify-between p-2 rounded-lg`}
                  style={{
                    backgroundColor:
                      isActive && !item.comingSoon ? activeBackgroundColor : ''
                  }}
                >
                  {/* TITLE CONTAINER */}
                  <div
                    className={`left-0 flex flex-row text-start justify-between items-center ${
                      !item.comingSoon && !isActive
                        ? 'text-white cursor-pointer'
                        : !item.comingSoon && isActive
                        ? 'text-itemHover'
                        : 'text-gray-500'
                    } `}
                    style={{
                      color: isActive && !item.comingSoon ? activeTextColor : ''
                    }}
                  >
                    {/* ICON */}
                    <div className={`mr-3 h-5 w-5 duration-500`}>
                      {item.icon}
                    </div>
                    {/* TITLE */}
                    <div className={hideText}>
                      <span
                        className={`text-base tracking-[1%] font-normal mr-6 min-w-[100px] text-start whitespace-pre`}
                      >
                        {item.title}
                      </span>
                      {/* SOON */}
                      {item.comingSoon && (
                        <span
                          className={`hover:bg-transparent ${soonText} mr-8`}
                        >
                          Soon
                        </span>
                      )}
                    </div>
                  </div>
                </li>
              </Link>
            </>
          );
        })}

        <div className={`${isSmallHeight ? 'mt-2' : 'mb-2'} ml-2`}>
          <LogOutButton showTitle={open} />
        </div>
      </ul>
    </div>
  );
};

export default MenuItem;
