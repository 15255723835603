import React, { useEffect, useRef, useState } from 'react';
import logo from '../assets/images/SphereIconLogoGreen.png';
import { Transition } from '@headlessui/react';
import i18n from '../config/languageInternationalization';

const Loading = () => {
  const spinRef = useRef<any>(null);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowMessage(true);
    }, 3000);
  }, []);

  const spin = () => {
    spinRef.current.classList.add('animate-spin', 'spin-fast');
  };

  useEffect(() => {
    spin();
  }, []);

  return (
    <div className="grid place-content-center items-center place-items-center select-none">
      <Transition
        show={true}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        className="justify-center self-center items-center align-middle relative mb-10"
      >
        <img
          ref={spinRef}
          className="h-20 w-20 md:h-28 md:w-28 spin-fast animate-spin mb-4 justify-center items-center"
          src={logo}
          alt="Loading spinner"
        />
      </Transition>
      {showMessage && (
        <p className="text-white text-sm md:text-base text-center absolute bottom-[40%]">
          {i18n.t('pleaseWait')}
        </p>
      )}
    </div>
  );
};

export default Loading;
