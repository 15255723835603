import es from './languages/dist/es.json';
import en from './languages/en.json';
import { Dict, I18n } from 'i18n-js';

export const translations: Dict = {
  en,
  es
};

const i18n = new I18n(translations);
i18n.enableFallback = true;
const language = window.navigator.language;
i18n.locale = language.slice(0, 2);

export default i18n;
