import React, { useEffect, useState } from 'react';
import { TokenMetadata } from '../../../utils/types';

const SelectTokenModal = ({
  tokens,
  selectedCurrency,
  setSelectedCurrency,
  transferModal = false,
  error,
  setHasChanges
}: {
  tokens: TokenMetadata[];
  selectedCurrency: TokenMetadata | null;
  setSelectedCurrency: (value: TokenMetadata) => void;
  transferModal?: boolean;
  error?: string | null;
  setHasChanges?: (value: boolean) => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [filteredTokens, setFilteredTokens] = useState<TokenMetadata[]>(tokens);

  const handleChange = (e: any) => {
    const value = e.target.value;
    const results = tokens.filter((token) => {
      if (value === '') return tokens;
      return token.name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredTokens(results);
  };

  useEffect(() => {
    setFilteredTokens(tokens);
  }, [tokens]);

  return (
    <div className="flex w-full">
      <div className="flex w-full">
        <div className={`flex w-full`}>
          <button
            data-test-id="select-token-button"
            onClick={() => setShowModal(true)}
            className={`flex w-full appearance-none bg-clip-padding bg-no-repeat transition ease-in-out m-0 text-left focus:outline-none bg-card-bg border ${
              error ? 'border-red-500' : 'border-card-stroke'
            } rounded-lg text-gray-300 py-[10px] px-4 ${
              transferModal ? 'w-[347px]' : 'w-52'
            } text-base items-center align-middle overflow-hidden whitespace-nowrap`}
          >
            {selectedCurrency!.name?.length
              ? selectedCurrency!.name
              : 'Select currency'}
          </button>
          {error && (
            <h3 className="text-red-500 w-[347px] text-center text-sm">
              {error}
            </h3>
          )}
        </div>
      </div>
      {showModal && (
        <div
          onClick={() => setShowModal(false)}
          className="fixed z-50 transform -translate-x-1/2 -translate-y-1/2"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="fixed w-[450px] m-auto left-0 right-0"
          >
            <input
              type="text"
              placeholder="Search"
              className={` ${
                filteredTokens.length ? 'rounded-t-md border-b-2' : 'rounded-md'
              } p-4 flex w-10/12 bg-card-bg text-white`}
              onChange={handleChange}
            />
            <div className="bg-card-bg max-h-80 w-10/12 rounded-b-md overflow-auto scrollbar-thin scrollbar-thumb-card-stroke scrollbar-track-card-bg scrollbar-track-rounded-full border border-gray-400">
              {filteredTokens.map((token, i) => (
                <div
                  className="p-3 border-b cursor-pointer"
                  key={i}
                  onClick={() => {
                    setSelectedCurrency(token);
                    setShowModal(false);
                    setHasChanges && setHasChanges(true);
                  }}
                >
                  <p className="text-base text-gray-50">{token.name}</p>
                  <p className="text-xs text-gray-400">
                    {token.symbol} - {token.address}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectTokenModal;
