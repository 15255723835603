import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserState } from './user';
import { getCustomers } from '../../utils/getCustomers';
import {
  Balance,
  LinkedAccountWallet,
  MerchantRoles,
  PageDirection
} from '../../utils/types';

import {
  callFunction,
  getDocumentData,
  getFundsAvailable,
  getMerchantWallets,
  getProfileInfo,
  merchantDataEndpoint,
  serverEndpoint,
  updateDocumentData,
  uploadImage
} from '../../utils/server';
import i18n from '../../config/languageInternationalization';
import BigNumber from 'bignumber.js';
import { normalizeDecimals } from '../../utils/formatTokens';
import axios from 'axios';
import { isEmail } from '../../utils/validation';
import { getTokens } from '../../utils/getTokens';
import { verifyJWT } from '../../utils/verifyJWT';

const getIsPinCodeSetup = async ({
  accessToken,
  idToken,
  userUid,
  userIsPinCodeSetup,
  merchantOwnerUid,
  apiKey,
  isTest
}: {
  accessToken: string;
  userIsPinCodeSetup?: boolean;
  idToken: string;
  userUid: string;
  merchantOwnerUid: string;
  apiKey: string;
  isTest: boolean;
}) => {
  if (userUid === merchantOwnerUid) {
    return !!userIsPinCodeSetup;
  }
  if (!apiKey) return undefined;
  const {
    data: { data }
  } = await axios.get(
    `${serverEndpoint}/user/${merchantOwnerUid}?isTest=${isTest}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'x-id-token': idToken,
        'x-api-key': apiKey
      }
    }
  );
  return !!data?.userInfo?.isPinCodeSetup;
};
export const getUserInfo = createAsyncThunk(
  'get/userinfo',
  async (
    {
      uid,
      accessToken,
      idToken
    }: { uid: string; accessToken: string; idToken: string },
    { rejectWithValue }
  ) => {
    try {
      const { userData, merchantData } = await getProfileInfo(
        accessToken,
        idToken
      );
      const isPinCodeSetup = await getIsPinCodeSetup({
        accessToken,
        idToken,
        userUid: uid,
        userIsPinCodeSetup: userData?.isPinCodeSetup,
        merchantOwnerUid: merchantData?.ownerUid,
        apiKey: merchantData?.apiKey || merchantData.testApiKey,
        isTest: !merchantData?.apiKey
      });

      const data: Omit<UserState, 'pageNumber' | 'recordsPerPage' | 'members'> =
        {
          isVerified: merchantData?.isVerified,
          isAuthenticated: true,
          isSidebarOpen: window.innerWidth >= 860,
          uid: uid,
          name: userData?.name,
          email: userData.email,
          username: userData?.username,
          address: userData?.address,
          phone: userData?.phone,
          birthdate: userData?.birthdate,
          profilePicture: userData?.profilePicture,
          countryCode: userData?.countryCode,
          countryFlag: userData?.countryFlag,
          preferredChain: merchantData?.preferredChain,
          preferredToken: merchantData?.preferredToken,
          isStatusFailing: merchantData?.isStatusFailing,
          apiKey: merchantData?.apiKey,
          testApiKey: merchantData?.testApiKey,
          merchantEndpoint: merchantData?.merchantEndpoint,
          theme: merchantData?.theme,
          merchantFee: merchantData?.merchantFee,
          accessToken,
          idToken,
          graphData: [],
          role: merchantData?.role,
          isCreatingMerchant: false,
          merchantUid: merchantData?.uid,
          oAuth2Clients: merchantData?.oAuth2Clients,
          isPinCodeSetup,
          isPhoneAdded: userData?.isPhoneAdded,
          customerData: [],
          contracts: merchantData?.contracts,
          termsAccepted: !!merchantData?.termsAccepted
        };

      data.customerData = await getCustomers(
        userData.uid,
        accessToken,
        idToken
      );

      const supportedTokens = await getTokens();
      data.supportedTokens = supportedTokens;
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getPaymentsTable = createAsyncThunk(
  'get/getPaymentsTable',
  async (
    direction: PageDirection | undefined,
    { getState, rejectWithValue }
  ) => {
    try {
      const {
        recordsPerPage,
        lastVisibleId,
        firstVisibleId,
        pageNumber,
        uid,
        accessToken,
        idToken
      } = (getState() as { user: UserState }).user;
      if (!accessToken || !uid || !idToken) {
        return rejectWithValue(i18n.t('errorUnauthenticated'));
      }
      // Base URL
      const url = `${merchantDataEndpoint}/transactionHistory`;

      // Adding search parameters
      const searchParams: { [key: string]: any } = {
        recordsPerPage,
        pageNumber,
        lastVisibleId,
        firstVisibleId
      };
      direction && (searchParams.direction = direction);

      const response = await getDocumentData({
        url,
        accessToken,
        idToken,
        searchParams
      });

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

// SET Preferred chain
export const setPreferredChainAndToken = createAsyncThunk(
  'set/chainAndToken',
  async (data: any, { getState, rejectWithValue }) => {
    try {
      const { uid, accessToken, idToken } = (getState() as { user: UserState })
        .user;
      if (!uid || !accessToken || !idToken) {
        return rejectWithValue(i18n.t('errorUnauthenticated'));
      }
      const updateData = {
        preferredChain: data.chain,
        preferredToken: data.token
      };
      await updateDocumentData({
        url: merchantDataEndpoint,
        accessToken,
        updateData,
        idToken
      });
      return {
        chain: data.chain,
        token: data.token
      };
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// SET Webhook URL
export const setWebhookUrl = createAsyncThunk(
  'set/webhookUrl',
  async (url: string, { getState, rejectWithValue }) => {
    try {
      const { uid, accessToken, idToken } = (getState() as { user: UserState })
        .user;
      if (!uid || !accessToken || !idToken) {
        return rejectWithValue(i18n.t('errorUnauthenticated'));
      }

      await updateDocumentData({
        url: merchantDataEndpoint,
        accessToken,
        updateData: { merchantEndpoint: url },
        idToken
      });
      return url;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const setMerchantTheme = createAsyncThunk(
  'set/merchantTheme',
  async (
    theme: { primaryColor: string; logo: File | string } | null,
    { getState, rejectWithValue }
  ) => {
    try {
      const { user } = getState() as { user: UserState };
      if (!user.uid || !user.accessToken || !user.idToken) {
        return rejectWithValue(i18n.t('errorUnauthenticated'));
      }

      if (theme === null) {
        await updateDocumentData({
          url: merchantDataEndpoint,
          accessToken: user.accessToken,
          idToken: user.idToken,
          updateData: { currentTheme: {} }
        });
        return null;
      }

      // check if color has changed
      const updatedColor =
        theme.primaryColor !== user.theme?.primaryColor
          ? theme.primaryColor
          : user.theme?.primaryColor;

      // check if image has changed
      let updatedLogo: string;
      if (theme.logo instanceof File) {
        // if it is an instance of a File, means that user selected a file from their local
        const formData = new FormData();
        formData.append('image', theme.logo);
        updatedLogo = await uploadImage({
          accessToken: user.accessToken,
          idToken: user.idToken,
          data: formData
        });
      } else if (typeof theme.logo === 'string') {
        // if it is a string, means that user selected a file from their firebase storage
        if (theme.logo !== user.theme?.logo) updatedLogo = theme.logo;
        else updatedLogo = user.theme?.logo;
      } else {
        // if it is undefined, means that user didn't change the logo
        updatedLogo = user.theme?.logo || '';
      }
      const currentTheme = {
        primaryColor: updatedColor,
        logo: updatedLogo
      };
      const updateData = {
        currentTheme
      };
      await updateDocumentData({
        url: merchantDataEndpoint,
        accessToken: user.accessToken,
        idToken: user.idToken,
        updateData
      });
      return currentTheme;
    } catch (error: any) {
      alert(i18n.t('somethingWentWrongAlert'));
      return rejectWithValue(error);
    }
  }
);

// Fetch user balances
export const getUserBalances = createAsyncThunk(
  'user/getUserBalances',
  async (
    { refreshCache, testnet }: { refreshCache: boolean; testnet: boolean },
    { getState, rejectWithValue }
  ) => {
    try {
      const { uid, accessToken, idToken } = (getState() as { user: UserState })
        .user;
      if (!uid || !accessToken || !idToken) {
        return rejectWithValue(i18n.t('errorUnauthenticated'));
      }

      let response = { data: { total: 0, balances: [] } };

      response = await getFundsAvailable(
        accessToken,
        refreshCache,
        idToken,
        testnet
      );

      const {
        total,
        balances
      }: {
        total: number | string;
        balances: Balance[];
      } = response.data;

      const sumSameTokens: any = {};
      const sumPerWallet: any = {};

      for (const balance of balances) {
        const currentSymbol = balance.tokenMetadata.symbol;
        const price = new BigNumber(balance.price);
        const amount = normalizeDecimals(
          balance.amount,
          balance.tokenMetadata.decimals || 0
        );

        if (!sumSameTokens[currentSymbol])
          sumSameTokens[currentSymbol] = {
            ...balance,
            amount,
            price
          };
        else
          sumSameTokens[currentSymbol] = {
            ...sumSameTokens[currentSymbol],
            amount: sumSameTokens[currentSymbol].amount.plus(amount),
            price: sumSameTokens[currentSymbol].price.plus(price)
          };

        // Total price per wallet
        const walletAddress = balance.address;
        if (!sumPerWallet[walletAddress])
          sumPerWallet[walletAddress] = {
            price
          };
        else
          sumPerWallet[walletAddress] = {
            price: sumPerWallet[walletAddress].price.plus(price)
          };
      }

      const totalPrice = Number(new BigNumber(total).div(10 ** 6).toFixed(2));
      const formattedWalletBalance: Balance[] = Object.entries(
        sumPerWallet
      ).map(([walletAddress, balance]: [string, any]) => {
        return {
          ...balance,
          address: walletAddress,
          price: balance.price.div(10 ** 6).toFixed(2)
        };
      });
      const formattedBalances: Balance[] = Object.values(sumSameTokens).map(
        (balance: any) => {
          return {
            ...balance,
            amount: balance.amount.gte(1)
              ? balance.amount.toFixed(2)
              : balance.amount.toFixed(8),
            price: balance.price.div(10 ** 6).toFixed(2)
          };
        }
      );

      if (!testnet) {
        return { totalPrice, formattedBalances, formattedWalletBalance }; // Include sumPerWallet in the return object
      } else {
        return {
          totalTestBalance: totalPrice,
          formattedTestBalances: formattedBalances,
          sumPerWallet // Include sumPerWallet in the return object
        };
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// GET user linked accounts
export const getLinkedAccounts = createAsyncThunk(
  'user/getLinkedAccounts',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { data, error } = await getMerchantWallets({
        accessToken: (getState() as any).user.accessToken,
        idToken: (getState() as any).user.idToken
      });
      if (error) {
        throw new Error(error);
      }
      const wallets: LinkedAccountWallet[] = data.map((w: any) => ({
        id: w.address,
        type: 'wallet',
        address: w.address,
        chains: w.chains,
        isImported: w.isImported,
        label: w.label,
        readOnly: w.readOnly,
        missingStarkKey: w.missingStarkKey ?? false
      }));
      return [...wallets];
    } catch (e: any) {
      return rejectWithValue(e.message || e.response.data.message);
    }
  }
);

// UPDATE merchantData document
export const updateMerchantData = createAsyncThunk(
  'user/updateMerchantData',
  async (
    payload: { url: string; updateData: any },
    { getState, rejectWithValue }
  ) => {
    try {
      const { url, updateData } = payload;
      await updateDocumentData({
        url,
        accessToken: (getState() as any).user.accessToken,
        idToken: (getState() as any).user.idToken,
        updateData: { ...updateData }
      });

      return updateData;
    } catch (e: any) {
      return rejectWithValue(e.message || e.response.data.message);
    }
  }
);

export const getMembers = createAsyncThunk(
  'get/getMembers',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { uid, accessToken, idToken } = (getState() as { user: UserState })
        .user;
      if (!accessToken || !uid || !idToken) {
        return rejectWithValue(i18n.t('errorUnauthenticated'));
      }

      const response = await getDocumentData({
        url: `${merchantDataEndpoint}/members`,
        accessToken,
        idToken
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.error || error.message || error
      );
    }
  }
);

export const addMember = createAsyncThunk(
  'user/addMember',
  async (
    { email, role }: { email: string; role: string },
    { getState, rejectWithValue }
  ) => {
    try {
      if (!email.trim().length || !isEmail(email)) {
        throw new Error(i18n.t('errorValidEmail'));
      }
      if (!role || role in MerchantRoles === false) {
        throw new Error(i18n.t('errorValidRole'));
      }
      const { members, accessToken, idToken } = (
        getState() as { user: UserState }
      ).user;
      if (members?.find((m) => m.email.toLowerCase() === email.toLowerCase())) {
        throw new Error(i18n.t('errorEmailAlreadyMember'));
      }

      const newMember = await axios.post(
        `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}/merchant/members`,
        { email: email, role },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'x-id-token': idToken
          }
        }
      );
      const newMembers = members ? [...members] : [];
      newMembers.push({
        email,
        role: role as MerchantRoles,
        createdAt: newMember.data.data.createdAt
      });
      return newMembers;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.error || error.message || error
      );
    }
  }
);

export const removeMember = createAsyncThunk(
  'user/removeMember',
  async ({ email }: { email: string }, { getState, rejectWithValue }) => {
    try {
      const { accessToken, idToken } = (getState() as { user: UserState }).user;

      await axios.delete(
        `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}/merchant/members`,
        {
          data: { email },
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'x-id-token': idToken
          }
        }
      );
      const newMembers = (
        getState() as { user: UserState }
      ).user.members?.filter(
        (e) => e.email.toLowerCase() !== email.toLowerCase()
      );
      return newMembers;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.error || error.message || error
      );
    }
  }
);

export const updateMember = createAsyncThunk(
  'user/updateMember',
  async (
    { email, role }: { email: string; role: string },
    { getState, rejectWithValue }
  ) => {
    try {
      if (!email.trim().length) throw new Error(i18n.t('errorValidEmail'));
      if (!role.trim() || role in MerchantRoles === false)
        throw new Error(i18n.t('errorValidRole'));
      const { accessToken, idToken } = (getState() as { user: UserState }).user;
      await axios.put(
        `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}/merchant/members`,
        { email, role },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'x-id-token': idToken
          }
        }
      );
      const members = (getState() as { user: UserState }).user.members;
      if (!members || !members.length)
        throw new Error(i18n.t('errorNoMemberToUpdate'));
      const indexOfMemberToUpdate = members.findIndex(
        (e) => e.email.toLowerCase() === email.toLowerCase()
      );
      if (indexOfMemberToUpdate === -1)
        throw new Error(i18n.t('errorMemberNotFound'));
      const newMembers = [...members];
      newMembers[indexOfMemberToUpdate] = {
        email,
        role: role as MerchantRoles,
        createdAt: newMembers[indexOfMemberToUpdate].createdAt,
        lastActive: newMembers[indexOfMemberToUpdate].lastActive
      };
      return newMembers;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.error || error.message || error
      );
    }
  }
);

export const updateMemberLastActivity = createAsyncThunk(
  'user/updateMemberLastActivity',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { accessToken, idToken, email } = (
        getState() as { user: UserState }
      ).user;
      const response = await axios.put(
        `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}/merchant/members/activity`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'x-id-token': idToken,
            'Content-Type': 'application/json'
          }
        }
      );

      const members = (getState() as { user: UserState }).user.members;

      if (!members || !members.length || !email)
        throw new Error(i18n.t('errorNoMemberToUpdate'));

      const indexOfMemberToUpdate = members.findIndex(
        (e) => e.email.toLowerCase() === email.toLowerCase()
      );

      if (indexOfMemberToUpdate === -1)
        throw new Error(i18n.t('errorMemberNotFound'));

      const newMembers = [...members];
      newMembers[indexOfMemberToUpdate] = {
        ...newMembers[indexOfMemberToUpdate],
        lastActive: response.data.data.lastActivity
      };

      return newMembers;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.error || error.message || error
      );
    }
  }
);

export const getChainLevel = createAsyncThunk(
  'get/getChainLevels',
  async (_, { getState, rejectWithValue }) => {
    try {
      const accessToken = (getState() as { user: UserState }).user.accessToken;
      const { data } = await axios.get(
        `${serverEndpoint}/config/chains/chain-levels`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      return data.CALL_CONTRACT;
    } catch (error: any) {
      alert(i18n.t('somethingWentWrongAlert'));
      return rejectWithValue(error);
    }
  }
);

export const isCountrySupportedForOfframp = createAsyncThunk(
  'get/isCountrySupportedForOfframp',
  async (countryCode: string, { rejectWithValue }) => {
    try {
      const response = await getDocumentData({
        url: `${serverEndpoint}/offramp/coinflow/supported-countries`
      });
      const isCountrySupported = response.data.find(
        (c: { name: string; alpha2: string }) => c.alpha2 === countryCode
      );
      return !!isCountrySupported;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getChainLogos = createAsyncThunk(
  'user/getChainLogos',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { chainLogos } = await callFunction({
        accessToken: (getState() as any).user.accessToken,
        url: '/config/chains/images',
        method: 'GET'
      });
      return chainLogos;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const acceptTerms = createAsyncThunk(
  'user/acceptTerms',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { uid, accessToken, idToken, role } = (
        getState() as { user: UserState }
      ).user;
      if (!uid || !accessToken || !idToken) {
        throw new Error(i18n.t('errorUnauthenticated'));
      }
      if (role !== MerchantRoles.OWNER) {
        throw new Error(i18n.t('notOwner'));
      }
      const date = Date.now();
      const updateData = {
        termsAccepted: date
      };
      await updateDocumentData({
        url: merchantDataEndpoint,
        accessToken,
        updateData,
        idToken
      });
      return {
        termsAccepted: date
      };
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  }
);

export const getTransactions = createAsyncThunk(
  'user/getTransactions',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { accessToken, uid } = (getState() as any).user;

      const response = await callFunction({
        accessToken,
        url: '/transactions',
        method: 'GET'
      });

      const txs = await verifyJWT(response.data);
      return txs.transactions.filter((t: any) => t.receiverUid == uid);
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
