import React from 'react';
import { Color, ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

export default function ColorPickerComponent({
  chosenColor,
  setChosenColor,
  hideHEX = true,
  hideRGB = true,
  hideHSV = true,
  isDark = true
}: {
  chosenColor: string;
  setChosenColor: (color: string) => void;
  hideHEX?: boolean;
  hideRGB?: boolean;
  hideHSV?: boolean;
  isDark?: boolean;
}) {
  // when first rendered, the color picker will be the color of the chosenColor
  const [color, setColor] = useColor('hex', chosenColor);

  return (
    <div>
      <ColorPicker
        width={456}
        height={228}
        color={color}
        onChange={(color: Color) => {
          // set this value, so that the parent component can get the value
          setChosenColor(color.hex);
          // keep an internal state of the color picker, in order to reflect change
          setColor(color);
        }}
        hideHEX={hideHEX}
        hideRGB={hideRGB}
        hideHSV={hideHSV}
        dark={isDark}
      />
    </div>
  );
}
