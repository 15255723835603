import React, { ChangeEvent } from 'react';
import Select from '../ThemedComponents/Select';
import { SupportedChains } from '../../utils/types';

interface Props {
  label: string;
  input: boolean;
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  selectOptions?: { value: string; label: string }[];
  defaultValue?: string | SupportedChains;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  error?: string | null;
  id?: string;
}

const ModalRow = ({
  label,
  selectOptions,
  defaultValue,
  onChange,
  input,
  name,
  placeholder,
  readOnly = false,
  disabled = true,
  error,
  id
}: Props) => {
  return (
    <div className="flex mt-5">
      <div className="flex flex-col gap-2">
        <span className="text-gray-300">{label}</span>

        {input ? (
          <input
            id={id}
            className={`flex justify-between items-center bg-card-bg border rounded-lg ${
              error ? 'border-red-500' : 'border-card-stroke'
            } text-gray-300 py-[10px] px-4 text-sm focus:ring-1 focus:ring-blue-100 w-96`}
            placeholder={placeholder}
            onChange={onChange}
            value={defaultValue}
            name={name}
            readOnly={readOnly}
          />
        ) : (
          <Select
            className="w-96 text-sm"
            onChange={onChange}
            defaultValue={defaultValue}
            name={name}
            disabled={disabled}
            id={id}
          >
            {selectOptions?.map((option, index) => {
              return (
                <option
                  className="bg-gray-700 text-sm"
                  value={option.value}
                  key={index}
                >
                  {option.label}
                </option>
              );
            })}
          </Select>
        )}
        {error && <p className="text-red-500 w-96 text-sm">{error}</p>}
      </div>
    </div>
  );
};

export default ModalRow;
