import React, { useEffect, useState } from 'react';
import { Modal, TextInput, Label } from 'flowbite-react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  deleteDocumentData,
  merchantDataEndpoint,
  postDocumentData,
  updateDocumentData
} from '../../../utils/server';
import {
  isValidRedirectUri,
  removeTrailingSlash
} from '../../../utils/validation';
import {
  removeOAuth2Client,
  setOAuth2Client,
  updateOAuth2Client
} from '../../../redux/slices/user';
import { Button } from '../../ThemedComponents/Flowbite/Button';
import { OAuth2Client } from './oauth-table';
import {
  HiChevronRight,
  HiCheck,
  HiOutlineExclamationCircle
} from 'react-icons/hi';
import { useMediaQuery } from 'react-responsive';
import { copyToClipBoard } from '../apikey-tab/generate-apikey';
import { SuccessModalIcon } from '../../../assets/icons/successModalIcon';
import { Icons } from '../../../assets/icons';
import i18n from '../../../config/languageInternationalization';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

const schema = z.object({
  clientName: z.string().min(3, i18n.t('oAuth2ModalErrorClientName')),
  newUri: z
    .string()
    .refine(
      (value) => value === '' || isValidRedirectUri(value),
      i18n.t('oAuth2ModalErrorNewUri')
    )
    .optional(),
  redirectUris: z
    .array(
      z.object({
        value: z.string()
      })
    )
    .min(1, i18n.t('oAuth2ModalErrorRedirectUris')),
  accessTokenLifetime: z
    .string()
    .refine(
      (value) => !isNaN(Number(value)),
      i18n.t('oAuth2ModalErrorAccessTokenLifetimeNumber')
    )
    .refine(
      (value) => Number(value) >= 3600,
      i18n.t('oAuth2ModalErrorAccessTokenLifetimeMinimum')
    )
});

interface OAuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  editingClient?: Omit<OAuth2Client, 'clientSecret'> | null;
  setOpenSuccessModal: React.Dispatch<
    React.SetStateAction<OAuth2Client | null>
  >;
}

type FormValues = z.infer<typeof schema>;

export const OAuthModalForm: React.FC<OAuthModalProps> = ({
  isOpen,
  onClose,
  editingClient,
  setOpenSuccessModal
}) => {
  const dispatch = useAppDispatch();
  const { merchantUid, accessToken, idToken } = useAppSelector(
    (state) => state.user
  );
  const {
    control,
    handleSubmit,
    trigger,
    setError,
    setValue,
    formState: { errors },
    reset
  } = useForm<FormValues>({
    defaultValues: {
      redirectUris: [],
      accessTokenLifetime: '3600'
    },
    resolver: zodResolver(schema)
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'redirectUris'
  });
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const formatRedirectUrisForServer = (redirectUris: { value: string }[]) => {
    return redirectUris.map((uriObject) => uriObject.value);
  };
  const onSubmit = async (data: FormValues) => {
    try {
      if (!accessToken || !idToken || isLoading) return;
      schema.parse(data);
      setIsLoading(true);
      if (editingClient) await editOAuth2Client(data);
      else await createOAuth2Client(data);
      reset({
        clientName: '',
        redirectUris: [],
        accessTokenLifetime: '3600'
      });
    } catch (error: any) {
      setError('root', {
        message: i18n.t('oAuth2ModalSubmitError')
      });
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const createOAuth2Client = async (data: FormValues) => {
    if (!accessToken || !idToken) return;
    const response = await postDocumentData({
      url: merchantDataEndpoint + `/${merchantUid}/oauth`,
      data: {
        ...data,
        redirectUris: data.redirectUris.map((uri) => uri.value)
      },
      accessToken: accessToken,
      idToken: idToken
    });
    const { clientSecret, ...client } = response.data;
    dispatch(setOAuth2Client(client));
    setOpenSuccessModal({ ...client, clientSecret });
  };

  const editOAuth2Client = async (data: FormValues) => {
    if (!accessToken || !idToken) return;
    const response = await updateDocumentData({
      url:
        merchantDataEndpoint +
        `/${merchantUid}/oauth/${editingClient?.clientId}`,
      updateData: {
        ...data,
        redirectUris: formatRedirectUrisForServer(data.redirectUris)
      },
      accessToken: accessToken,
      idToken: idToken
    });
    const { clientSecret, ...client } = response.data;
    dispatch(updateOAuth2Client(client));
  };

  const handleClose = () => {
    if (isLoading) return;
    onClose();
    reset({
      clientName: '',
      redirectUris: [],
      accessTokenLifetime: '3600'
    });
    errors.root && setError('root', { message: '' });
  };

  const handleAddUri = async (value: string | undefined) => {
    const isValid = await trigger('newUri');
    if (!isValid || !value) return;
    append({ value: removeTrailingSlash(value) });
    setValue('newUri', '');
  };

  useEffect(() => {
    if (editingClient) {
      setValue('clientName', editingClient.clientName);
      setValue('accessTokenLifetime', editingClient.accessTokenLifetime);
      setValue(
        'redirectUris',
        editingClient?.redirectUris.map((uri) => ({ value: uri }))
      );
    }
  }, [editingClient]);

  return (
    <Modal
      dismissible
      popup
      show={isOpen}
      onClose={handleClose}
      className="dark"
      size={isMobile ? 'sm' : 'lg'}
    >
      <div className="bg-gray-800 rounded-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body className="p-8 gap-5">
            <h1 className="text-gray-50 text-xl font-medium leading-loose pb-5">
              {i18n.t('oAuth2ModalTitle')}
            </h1>
            <div className="pb-5">
              <Label htmlFor="clientName" value="Client Name" />
              <Controller
                name="clientName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextInput className="dark" id="clientName" {...field} />
                )}
              />
              {errors.clientName && (
                <p className="text-red-600 text-sm">
                  {errors.clientName.message}
                </p>
              )}
            </div>
            <div className="pb-5">
              <Label htmlFor="redirectUris" value="Redirect URIs" />
              <div className="flex items-center">
                <Controller
                  name="newUri"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextInput
                        id="newUri"
                        {...field}
                        className="w-full"
                        placeholder="https://example.com"
                      />
                      <Button
                        data-test-id="add-uri-button"
                        className="w-11 h-10 p-3 bg-gray-700 rounded-lg border border-gray-600 text-gray-50 text-sm font-normal ml-2 hover:bg-gray-800"
                        onClick={() => handleAddUri(field.value)}
                      >
                        +
                      </Button>
                    </>
                  )}
                />
              </div>
              {errors.newUri && (
                <p className="text-red-600 text-sm">{errors.newUri.message}</p>
              )}
              {errors.redirectUris && (
                <p className="text-red-600 text-sm">
                  {errors.redirectUris.message}
                </p>
              )}
              <ul className="text-white pt-1">
                {fields.map((item, index) => {
                  return (
                    <li
                      key={item.id}
                      className="inline-block bg-gray-700 rounded-md text-sm p-2 m-1"
                    >
                      {item.value}
                      <button
                        data-test-id={`remove-uri-button-${item.value}`}
                        className="text-md font-bold text-gray-400 ml-2 hover:text-gray-50"
                        onClick={() => remove(index)}
                      >
                        x
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <Label
              htmlFor="accessTokenLifetime"
              value="Access Token Lifetime"
            />
            <Controller
              name="accessTokenLifetime"
              control={control}
              render={({ field }) => (
                <TextInput
                  id="accessTokenLifetime"
                  {...field}
                  helperText={
                    <span className="font-normal text-xs sm:text-sm leading-tight">
                      {i18n.t('oAuth2ModalAccessTokenDescriptionTitle')}
                      <br />
                      {i18n.t('oAuth2ModalAccessTokenDescription')}
                    </span>
                  }
                  rightIcon={() => <p className="text-gray-400">seconds</p>}
                />
              )}
              rules={{ required: true }}
            />
            {errors.accessTokenLifetime && (
              <p className="text-red-600 text-sm">
                {errors.accessTokenLifetime.message}
              </p>
            )}

            {errors.root && (
              <p className="text-red-600">{errors.root.message}</p>
            )}

            <div className="pt-5 inline-flex gap-4">
              <Button color="gray" onClick={onClose}>
                {i18n.t('cancel')}
              </Button>
              <Button type="submit" isProcessing={isLoading}>
                {i18n.t('save')}
              </Button>
            </div>
          </Modal.Body>
        </form>
      </div>
    </Modal>
  );
};

interface OAuthModalSuccessProps {
  isOpen: boolean;
  onClose: () => void;
  clientId: string;
  clientSecret: string;
}

export const OAuthModalSuccess: React.FC<OAuthModalSuccessProps> = ({
  isOpen,
  onClose,
  clientId,
  clientSecret
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const [isClientIdCopied, setIsClientIdCopied] = useState(false);
  const [isClientSecretCopied, setIsClientSecretCopied] = useState(false);

  const handleCopy = async (
    value: string,
    errorMessage: string,
    setCopiedState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    await copyToClipBoard(value, errorMessage);
    setCopiedState(true);
    setTimeout(() => {
      setCopiedState(false);
    }, 2000);
  };
  return (
    <Modal
      className="dark"
      show={isOpen}
      size={isMobile ? 'sm' : 'lg'}
      popup
      dismissible
      onClose={onClose}
    >
      <div className="bg-gray-800 rounded-lg shadow">
        <Modal.Body>
          <div className="items-center space-y-5 p-3">
            <div className="flex flex-col items-center pt-6">
              <SuccessModalIcon />
            </div>
            <h1 className=" text-lg sm:text-xl font-medium text-gray-50 text-center leading-loose">
              {i18n.t('oAuth2ModalSuccessTitle')}
            </h1>
            <span className="text-sm sm:text-base font-normal text-gray-400 leading-normal">
              {i18n.t('oAuth2ModalSuccessDescription')}
            </span>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="clientId" value="Client ID" />
              </div>
              <TextInput
                id="clientId"
                defaultValue={clientId}
                contentEditable={false}
                addon={
                  !isClientIdCopied ? (
                    <Icons.fileCopyIcon
                      className="cursor-pointer transform hover:scale-125 active:scale-100"
                      onClick={async () => {
                        await handleCopy(
                          clientId,
                          'Unable to copy client id',
                          setIsClientIdCopied
                        );
                      }}
                    />
                  ) : (
                    <HiCheck className="animate-fade-in" size={20} />
                  )
                }
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="clientSecret" value="Client secret" />
              </div>
              <TextInput
                id="clientSecret"
                defaultValue={clientSecret}
                type="password"
                contentEditable={false}
                addon={
                  !isClientSecretCopied ? (
                    <Icons.fileCopyIcon
                      className="cursor-pointer transform hover:scale-125 active:scale-100"
                      onClick={async () => {
                        await handleCopy(
                          clientSecret,
                          'Unable to copy client secret',
                          setIsClientSecretCopied
                        );
                      }}
                    />
                  ) : (
                    <HiCheck className="animate-fade-in" size={20} />
                  )
                }
              />
            </div>
            <div className="flex justify-center">
              <div className="w-full h-px bg-gray-600" />
            </div>
            <p className="w-full text-gray-400 text-base font-normal leading-normal">
              {i18n.t('oAuth2ModalSuccessNextSteps')}
            </p>
            <p
              className="w-full text-blue-500 text-sm sm:text-base font-normal leading-norma cursor-pointer"
              onClick={() =>
                window.open(
                  'https://docs.sphereone.xyz/docs/how-to-use-our-login#web-sdk',
                  '_blank'
                )
              }
            >
              {i18n.t('oAuth2ModalSuccessNextStepsDescription')}
              <HiChevronRight className="w-4 h-4 inline-block" />
            </p>
            <div className="flex justify-center">
              <Button onClick={onClose}>{i18n.t('close')}</Button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

interface OAuthModalDeleteProps {
  clientId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const OAuthModalDelete: React.FC<OAuthModalDeleteProps> = ({
  clientId,
  isOpen,
  onClose
}) => {
  const dispatch = useAppDispatch();
  const { merchantUid, accessToken, idToken } = useAppSelector(
    (state) => state.user
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    if (!accessToken || !idToken || isLoading) return;
    setIsLoading(true);
    try {
      await deleteDocumentData({
        url: merchantDataEndpoint + `/${merchantUid}/oauth/${clientId}`,
        accessToken,
        idToken
      });
      dispatch(removeOAuth2Client(clientId));
    } finally {
      setIsLoading(false);
      onClose();
    }
  };
  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };
  return (
    <>
      <Modal
        show={isOpen}
        size="sm"
        popup
        onClose={handleClose}
        className="dark bg-black bg-opacity-50 rounded-md"
      >
        <Modal.Header className="bg-gray-800 border-gray-800 rounded-md" />
        <Modal.Body className="bg-gray-800 border-gray-800 border-t px-5 rounded-md">
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-600" />
            <h3 className="mb-5 text-lg font-normal  text-gray-50">
              {i18n.t('oAuth2DeleteModalMessage')}
            </h3>
            <div className="flex justify-center gap-4">
              <PrimaryButton
                loading={isLoading}
                onClick={handleClose}
                title={i18n.t('no')}
              />
              <PrimaryButton
                loading={isLoading}
                onClick={handleDelete}
                title={i18n.t('yes')}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
