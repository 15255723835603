import React, { ChangeEvent, FormEvent, useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';

export const Title = ({ text }: { text: string }) => {
  return (
    <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
      {text}
    </h1>
  );
};

export const Input = ({
  text,
  name,
  type,
  className,
  value,
  onChange,
  ...props
}: {
  text?: string;
  name?: string;
  type?: string;
  className?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div>
      {name && (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-white"
        >
          {text || name}
        </label>
      )}
      <input
        data-test-id="insert-pincode-input"
        type={type ?? ''}
        name={name}
        value={value ?? ''}
        className={
          'border sm:text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500 ' +
            className ?? ''
        }
        {...props}
        onChange={onChange}
      />
    </div>
  );
};

export const PasswordInput = ({
  text,
  name,
  type,
  className,
  value,
  onChange,
  ...props
}: {
  text?: string;
  name?: string;
  type?: string;
  className?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const [secureInput, setSecureInput] = useState(true);
  const handleSecureInput = () => {
    setSecureInput(!secureInput);
  };
  return (
    <div>
      {name && (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-white"
        >
          {text || name}
        </label>
      )}
      <div className="flex flex-row border rounded-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
        <input
          data-test-id="insert-pincode-input"
          type={secureInput ? 'password' : ''}
          name={name}
          value={value ?? ''}
          className={
            'sm:text-sm rounded-lg block w-full p-2.5 border-transparent bg-gray-700 placeholder-gray-400 text-white' +
              className ?? ''
          }
          {...props}
          onChange={onChange}
        />
        {secureInput ? (
          <EyeSlashIcon
            className="h-8 mt-1 cursor-pointer mx-2"
            onClick={handleSecureInput}
          />
        ) : (
          <EyeIcon
            className="h-8 mt-1 cursor-pointer mx-2"
            onClick={handleSecureInput}
          />
        )}
      </div>
    </div>
  );
};

export function Form({
  handleSubmit,
  children,
  title,
  className,
  style
}: {
  title: string;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  className?: string;
  style?: any;
  children: React.ReactNode;
}) {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 100000
      }}
    >
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <Title text={title} />
              <form
                className={'space-y-4 md:space-y-6' + className ?? ''}
                onSubmit={handleSubmit}
                style={style}
              >
                {children}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
