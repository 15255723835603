import React from 'react';
import Sidebar from '../components/Sidebar';
import TopBar from './TopBar';
import { useAppSelector } from '../redux/hooks';
import CallbackFailureBanner from '../components/Notifications/CallbackFailureBanner';

export function SidebarLayout({ children }: { children: any }) {
  const { isStatusFailing, loading } = useAppSelector((state) => state.user);

  if (loading) return <div>Loading...</div>;
  return (
    <>
      <TopBar />

      <Sidebar />

      {isStatusFailing && <CallbackFailureBanner />}
      <div className="ml-16 sm:ml-64 mt-14 p-5 ">{children}</div>
    </>
  );
}
