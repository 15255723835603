import React, { useState } from 'react';
import { Table, Flowbite, Tooltip } from 'flowbite-react';

import i18n from '../../../config/languageInternationalization';
import { toastError } from '../../../utils/toastify';
import { useAppSelector } from '../../../redux/hooks';
import { MerchantRoles } from '../../../utils/types';
import useCustomTheme from '../../ThemedComponents/use-custom-theme';
import { LoadingTableRow } from '../../ThemedComponents/Flowbite/loading-table-row';
import { ApiKeyTableRow } from './apikey-tablerow';
import CreateApiKey from './create-apikey';
import Loading from '../../Loading';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';
import { PlusIcon } from '@heroicons/react/20/solid';

export enum ApiKeyEnvironments {
  PROD = 'PROD',
  TEST = 'TEST'
}

const GenerateApiKey = () => {
  const { role, apiKey, testApiKey } = useAppSelector((state) => state.user);

  const { getTableHeadStyle, getTableBodyStyle, getActiveColor } =
    useCustomTheme();

  const tableHeadColor = getTableHeadStyle();
  const activeColor = getActiveColor();
  const tableBodyColor = getTableBodyStyle();

  const [isLoading, setIsLoading] = useState(false);
  const [createApiKeyBox, setCreateApiKeyBox] = useState(false);
  const [apiKeyEnvironment, setApiKeyEnvironment] =
    useState<ApiKeyEnvironments>(ApiKeyEnvironments.TEST);

  if (isLoading)
    return (
      <div className="absolute z-20 bg-black opacity-80 left-0 top-0 h-full w-full flex justify-center items-center">
        <Loading />
      </div>
    );
  else
    return (
      <>
        {createApiKeyBox ? (
          <CreateApiKey
            onClose={() => setCreateApiKeyBox(false)}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        ) : (
          <>
            {/* Buttons Row */}
            <div className="flex justify-between mb-8">
              {/* Environment Buttons */}
              <div className="flex min-w-[300px] gap-2">
                <PrimaryButton
                  autoFocus={true}
                  onClick={() => setApiKeyEnvironment(ApiKeyEnvironments.TEST)}
                  title="Test API Keys"
                  className="text-xs"
                />
                <PrimaryButton
                  onClick={() => setApiKeyEnvironment(ApiKeyEnvironments.PROD)}
                  title="Production API Keys"
                  className="text-xs"
                />
              </div>

              {/* Create Api Key Button*/}
              <div className="flex">
                <Tooltip
                  content={
                    role === MerchantRoles.MEMBER
                      ? i18n.t('notOwner')
                      : i18n.t('createNewApiKey')
                  }
                >
                  <PrimaryButton
                    icon={<PlusIcon className="flex w-4 h-4" />}
                    onClick={() => setCreateApiKeyBox(true)}
                    title={i18n.t('createNewApiKey')}
                    disabled={role === MerchantRoles.MEMBER}
                  />
                </Tooltip>
              </div>
            </div>

            {/* TABLE */}
            <div>
              {/* TABLE TITLE */}
              <div style={tableHeadColor} className="rounded-t-md p-4 pb-0">
                <h3>
                  {apiKeyEnvironment === ApiKeyEnvironments.TEST
                    ? i18n.t('standardAPIKeys')
                    : i18n.t('productionAPIKeys')}
                </h3>
                <p className="text-xs">
                  {apiKeyEnvironment === ApiKeyEnvironments.TEST
                    ? i18n.t('standardAPIKeysDescription')
                    : i18n.t('productionAPIKeysDescription')}
                </p>
              </div>
              {/* TABLE Header + Body */}
              <Flowbite
                theme={{
                  theme: {
                    table: {
                      head: {
                        cell: {
                          base: 'group-first/head:first:rounded-tl-none group-first/head:last:rounded-tr-none bg-gray-50 dark:bg-gray-700 px-6 py-3'
                        }
                      },
                      body: {
                        cell: {
                          base: 'group-first/body:group-first/row:first:rounded-tl-md group-first/body:group-first/row:last:rounded-tr-md group-last/body:group-last/row:first:rounded-bl-md group-last/body:group-last/row:last:rounded-br-md px-6 py-4'
                        }
                      },
                      row: {
                        base: 'group/row'
                      }
                    }
                  }
                }}
              >
                <Table hoverable>
                  <Table.Head>
                    {/* <Table.HeadCell style={tableHeadColor}>KEY</Table.HeadCell> */}
                    <Table.HeadCell style={tableHeadColor}>
                      <span className="sr-only">Edit</span>
                    </Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y font-normal">
                    {apiKeyEnvironment === ApiKeyEnvironments.TEST &&
                    testApiKey ? (
                      <ApiKeyTableRow
                        apiKey={testApiKey}
                        tableBodyColor={tableBodyColor}
                        apiKeyEnvironment={apiKeyEnvironment}
                      />
                    ) : apiKeyEnvironment === ApiKeyEnvironments.PROD &&
                      apiKey ? (
                      <ApiKeyTableRow
                        apiKey={apiKey}
                        tableBodyColor={tableBodyColor}
                        apiKeyEnvironment={apiKeyEnvironment}
                      />
                    ) : isLoading ? (
                      <LoadingTableRow
                        tableBodyColor={tableBodyColor.backgroundColor}
                        activeColor={activeColor}
                        cellsToComplete={1}
                      />
                    ) : (
                      <Table.Row style={tableBodyColor}>
                        <Table.Cell colSpan={1}>
                          <div className="flex justify-center items-center">
                            <p className="text-sm text-gray-400">
                              {i18n.t('noAPIKeys')}
                            </p>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Flowbite>
            </div>
          </>
        )}
      </>
    );
};

export const copyToClipBoard = async (copyMe: string, errorMessage: string) => {
  try {
    await navigator.clipboard.writeText(copyMe);
  } catch (err) {
    toastError(errorMessage);
  }
};

export default GenerateApiKey;
