import globe from './globalNetwork.svg';
import payment from './paymentGateway.svg';
import security from './cyberSecurity.svg';
import playstore from './downloadGooglePay.svg';
import appstore from './downloadAppstore.svg';

export const loginPageImages = {
  globe,
  payment,
  security,
  playstore,
  appstore
};
