import { createSlice } from '@reduxjs/toolkit';
import {
  Balance,
  CustomerData,
  Graph,
  LinkedAccountWallet,
  MerchantMembers,
  MerchantRoles,
  SupportedChains,
  ThemeData,
  TokenMetadata,
  TransactionHistoryType
} from '../../utils/types';
import {
  acceptTerms,
  addMember,
  getChainLevel,
  getChainLogos,
  getLinkedAccounts,
  getMembers,
  getPaymentsTable,
  getTransactions,
  getUserBalances,
  getUserInfo,
  isCountrySupportedForOfframp,
  removeMember,
  setMerchantTheme,
  setPreferredChainAndToken,
  setWebhookUrl,
  updateMember,
  updateMemberLastActivity,
  updateMerchantData
} from './userThunk';
import i18n from '../../config/languageInternationalization';
import { OAuth2Client } from '../../components/SettingsSection/oauth/oauth-table';
import { Contract } from '../../components/SettingsSection/contracts/contract-modal';

export interface UserState {
  merchantFee?: number;
  isVerified?: boolean;
  isAuthenticated: boolean;
  loading?: boolean;
  error?: string;
  uid?: string | null;
  name?: string;
  email?: string | null;
  username?: string;
  address?: string;
  linkedAccounts?: LinkedAccountWallet[];
  phone?: string;
  birthdate?: string;
  profilePicture?: string | undefined;
  countryCode?: string | any;
  countryFlag?: string;
  customerData: CustomerData[] | [];
  transactionHistoryData?: TransactionHistoryType[] | [];
  hasNextPage?: boolean;
  pageNumber: number;
  lastVisibleId?: any;
  firstVisibleId?: any;
  recordsPerPage: number;
  totalBalance?: number;
  totalTestBalance?: number;
  balanceTokens?: Balance[]; // Sums symbol if repeated
  balancePerWallet?: Balance[]; // Balance per wallet
  balanceTokensTestnet?: Balance[];
  //defaultToken?: string; We should get this token from current liquidity function
  //defaultChain?: string; We should get this chain from current liquidity function
  apiKey?: string;
  preferredToken?: TokenMetadata;
  preferredChain?: string;
  isStatusFailing?: boolean;
  isSidebarOpen: boolean;
  merchantEndpoint?: string;
  testEnvironment?: boolean;
  testApiKey?: string;
  theme?: ThemeData | null;
  isMerchant?: boolean;
  accessToken?: string;
  idToken?: string;
  graphData?: Graph[];
  role?: MerchantRoles;
  members?: MerchantMembers[];
  supportedTokens?: any;
  isCreatingMerchant: boolean;
  merchantUid?: string;
  oAuth2Clients: Omit<OAuth2Client, 'clientSecret'>[];
  isPinCodeSetup?: boolean;
  isPhoneAdded?: boolean;
  contracts?: Contract[];
  chainLevel?: SupportedChains[];
  isCountrySupportedForOfframp?: boolean;
  chainLogos?: {
    [key: string]: string;
  };
  termsAccepted?: boolean;
}

const initialState: UserState = {
  accessToken: undefined,
  isAuthenticated: false,
  loading: true,
  isCreatingMerchant: false,
  isSidebarOpen: window.innerWidth >= 860,
  testEnvironment: false,
  merchantEndpoint: '',
  pageNumber: 1,
  recordsPerPage: 10,
  transactionHistoryData: [],
  oAuth2Clients: [],
  chainLevel: undefined,
  isCountrySupportedForOfframp: false,
  customerData: [],
  termsAccepted: undefined
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateApiKey: (state, action) => {
      if (!action.payload.isTest) {
        state.apiKey = action.payload.apiKey;
      } else state.testApiKey = action.payload.apiKey;
    },
    setTestEnvironment: (state, action) => {
      state.testEnvironment = action.payload;
    },
    setIsSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload;
    },
    signOut: () => {
      initialState;
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setIsCreatingMerchant: (state, action) => {
      state.isCreatingMerchant = action.payload;
    },
    setOAuth2Client: (state, action) => {
      state.oAuth2Clients = [...state.oAuth2Clients, action.payload];
    },
    updateOAuth2Client: (state, action) => {
      state.oAuth2Clients = state.oAuth2Clients.map((client) => {
        if (client.clientId === action.payload.clientId) {
          return action.payload;
        }
        return client;
      });
    },
    removeOAuth2Client: (state, action) => {
      state.oAuth2Clients = state.oAuth2Clients.filter(
        (client) => client.clientId !== action.payload
      );
    },

    setContract: (state, action) => {
      if (!state.contracts) state.contracts = [action.payload];
      else state.contracts = [...state.contracts, action.payload];
    },
    updateContract: (state, action) => {
      if (!state.contracts) state.contracts = [action.payload];
      state.contracts = state.contracts.map((contract) => {
        if (contract.id === action.payload.id) {
          return { ...contract, ...action.payload };
        }
        return contract;
      });
    },
    removeContract: (state, action) => {
      if (!state.contracts) state.contracts = [];
      state.contracts = state.contracts.filter(
        (contract) => contract.id !== action.payload
      );
    },

    setIsPinCodeSetup: (state, action) => {
      state.isPinCodeSetup = action.payload;
    },
    setIsPhoneAdded: (state) => {
      state.isPhoneAdded = true;
    }
  },
  extraReducers: (builder) => {
    // - GET -
    // Get user balances
    builder.addCase(getUserBalances.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserBalances.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserBalances.fulfilled, (state, action) => {
      state.loading = false;
      if (!state.testEnvironment) {
        state.totalBalance = action.payload.totalPrice;
        state.balanceTokens = action.payload.formattedBalances;
        state.balancePerWallet = action.payload.formattedWalletBalance;
      } else {
        state.totalTestBalance = action.payload.totalTestBalance;
        state.balanceTokensTestnet = action.payload.formattedTestBalances;
      }
    });

    builder.addCase(getChainLogos.rejected, (state, action) => {
      state.chainLogos = {};
    });
    builder.addCase(getChainLogos.fulfilled, (state, action) => {
      state.chainLogos = action.payload;
    });

    builder.addCase(getTransactions.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTransactions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || i18n.t('errorFetchingTransactions');
    });

    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.loading = false;
      state.transactionHistoryData = action.payload;
    });

    // - PUT -
    // UPDATE merchatData document
    builder.addCase(updateMerchantData.fulfilled, (state, action) => {
      state.merchantFee = action.payload.merchantFee;
    });

    // user info
    builder.addCase(getUserInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message || i18n.t('somethingWentWrong');
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
        isAuthenticated: true
      };
    });

    // Payments Table
    builder.addCase(getPaymentsTable.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentsTable.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || i18n.t('somethingWentWrong');
    });
    builder.addCase(getPaymentsTable.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    });

    // Linked accounts
    builder.addCase(getLinkedAccounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLinkedAccounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || i18n.t('errorUnableFetchBalances');
      state.linkedAccounts = [];
    });
    builder.addCase(getLinkedAccounts.fulfilled, (state, action) => {
      state.loading = false;
      state.linkedAccounts = action.payload;
    });

    // - SET -
    // Set Webhook URL
    builder.addCase(setWebhookUrl.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setWebhookUrl.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || i18n.t('somethingWentWrong');
    });
    builder.addCase(setWebhookUrl.fulfilled, (state, action) => {
      state.merchantEndpoint = action.payload;
      state.loading = false;
    });

    // Set preferred chain and token
    builder.addCase(setPreferredChainAndToken.fulfilled, (state, action) => {
      state.preferredChain = action.payload?.chain;
      state.preferredToken = action.payload?.token;
    });

    builder.addCase(setMerchantTheme.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setMerchantTheme.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || i18n.t('errorUnableSetTheme');
    });
    builder.addCase(setMerchantTheme.fulfilled, (state, action) => {
      state.theme = action.payload;
      state.loading = false;
    });

    builder.addCase(getMembers.rejected, (state, action) => {
      state.error = action.error.message || i18n.t('errorUnableGetMembers');
    });
    builder.addCase(getMembers.fulfilled, (state, action) => {
      state.members = action.payload;
    });

    builder.addCase(addMember.rejected, (state, action) => {
      state.error = action.error.message || i18n.t('errorUnableAddMembers');
    });
    builder.addCase(addMember.fulfilled, (state, action) => {
      state.members = action.payload;
    });

    builder.addCase(removeMember.rejected, (state, action) => {
      state.error = action.error.message || i18n.t('errorUnableRemoveMembers');
    });
    builder.addCase(removeMember.fulfilled, (state, action) => {
      state.members = action.payload;
    });

    builder.addCase(updateMember.rejected, (state, action) => {
      state.error = action.error.message || i18n.t('errorUnableUpdateMembers');
    });
    builder.addCase(updateMember.fulfilled, (state, action) => {
      state.members = action.payload;
    });

    builder.addCase(updateMemberLastActivity.rejected, (state, action) => {
      state.error =
        action.error.message || i18n.t('errorUnableUpdateLastActivity');
    });
    builder.addCase(updateMemberLastActivity.fulfilled, (state, action) => {
      state.members = action.payload;
    });

    builder.addCase(getChainLevel.rejected, (state, action) => {
      state.error = action.error.message || i18n.t('errorGettingChainLevel');
    });
    builder.addCase(getChainLevel.fulfilled, (state, action) => {
      state.chainLevel = action.payload;
    });

    // Country supported for offramp
    builder.addCase(isCountrySupportedForOfframp.rejected, (state, action) => {
      state.error =
        action.error.message || i18n.t('errorSupportedCountryForOfframp');
    });
    builder.addCase(isCountrySupportedForOfframp.fulfilled, (state, action) => {
      state.isCountrySupportedForOfframp = action.payload;
    });

    builder.addCase(acceptTerms.fulfilled, (state, action) => {
      state.termsAccepted = true;
    });

    builder.addCase(acceptTerms.rejected, (state, action) => {
      state.error = action.error.message || i18n.t('errorAcceptingTerms');
    });
  }
});

export const {
  updateApiKey,
  setTestEnvironment,
  setIsSidebarOpen,
  signOut,
  updateAccessToken,
  setIsCreatingMerchant,
  setOAuth2Client,
  updateOAuth2Client,
  removeOAuth2Client,
  setIsPinCodeSetup,
  setIsPhoneAdded,
  setContract,
  updateContract,
  removeContract
} = userSlice.actions;
export default userSlice.reducer;
