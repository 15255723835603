import React, { useEffect } from 'react';
import images from '../assets/images/brand/index';
import DisplayCards from './Onboarding/Features/DisplayCards';
import Button from './ThemedComponents/Buttons/button';
import { useDispatch } from 'react-redux';
import i18n from '../config/languageInternationalization';
import { logout } from './Sidebar/Account/LogOut';
import { signOut } from '../redux/slices/user';
import { useAuth } from 'react-oidc-context';
import { postDocumentData } from '../utils/server';
import { useNavigate } from 'react-router-dom';

export const AddEmailScreen = () => {
  const [emailInput, setEmailInput] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { user, signoutSilent } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) navigate('/login');
  }, []);

  const logoutURL = `${process.env.REACT_APP_AUTH_AUTHORITY}/ui/verification?return_to=${process.env.REACT_APP_AUTH_REDIRECT_URI}`;
  return (
    <section className="h-screen flex flex-1 flex-col px-16 lg:px-28 pt-12 pb-40">
      {/* LOGO CONTAINER */}
      <div className="mb-12 lg:mb-24 ">
        <img src={images.SphereOneLogoFull} className="w-28 xl:w-40" />
      </div>

      {/* CONTENT CONTAINER */}
      <div className="flex flex-1 mb-12 flex-col-reverse md:flex-row">
        {/* LEFT SIDE CARDS */}
        <div className="flex flex-1 flex-col ">
          <DisplayCards />
        </div>

        {/* RIGHT SIDE BOX */}
        <div className="md:ml-8 flex flex-1 w-full place-self-center mb-10">
          <div className="bg-card-bg border border-gray-800 place-items-center text-white p-6 rounded-2xl flex-1">
            <div className="pb-2">
              <h1>{i18n.t('enterAndVerifiyEmail')}</h1>
              <input
                onChange={(e) => {
                  setEmailInput(e.target.value);
                }}
                value={emailInput}
                className="rounded w-full text-black my-2 px-2"
              />
              <Button
                className="h-8 px-4 w-full"
                disabled={loading}
                onClick={async () => {
                  try {
                    if (!user?.access_token || !user?.id_token) return;
                    setLoading(true);
                    const confirm = window.confirm(
                      i18n.t('confirmEmail') + '\n' + emailInput
                    );
                    if (!confirm) return;
                    await postDocumentData({
                      url: `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}/user/email`,
                      data: { email: emailInput },
                      accessToken: user?.access_token,
                      idToken: user?.id_token
                    });
                    dispatch(signOut());
                    alert(i18n.t('verifyEmailToUse'));
                    logout(signoutSilent, logoutURL);
                  } catch (e: any) {
                    if (
                      e.response.data.error ===
                      'Email already linked to another account'
                    ) {
                      alert(i18n.t('emailAlreadyInUse'));
                      setEmailInput('');
                    }
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                <span className="font-bold text-base">
                  {loading ? i18n.t('loading') : i18n.t('send')}
                </span>
              </Button>
            </div>
            <Button
              className="h-8 px-4  w-full"
              disabled={loading}
              onClick={async () => {
                dispatch(signOut());
                logout(signoutSilent, window.location.origin);
              }}
            >
              <span className="font-bold text-base">{i18n.t('logout')}</span>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
