import React, { Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import i18n from '../../config/languageInternationalization';

const Modalbalances = ({
  setIsOpen,
  isOpen
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto pl-8 top-[-300px] sm:top-0">
            <div className="ml-2 mr-11 flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="h-[324px] w-[454px] rounded-2xl border-card-stroke bg-card-bg backdrop-blur-[40px] sm:backdrop-blur-[190px]">
                  <div className="flex justify-between">
                    <div className="mt-[40px] text-lg px-10 text-start text-white text-[24px] font-sans">
                      {i18n.t('cashout')}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="text-lg text-white mt-[39px] px-12"
                        onClick={() => setIsOpen(false)}
                      >
                        X
                      </button>
                    </div>
                  </div>
                  <div className="text-whitelight text-[16px] text-start mt-10 px-10 font-sans">
                    {i18n.t('sendFundToAnotherWallet')}
                  </div>
                  <button
                    type="button"
                    className="text-sm h-[48px] w-[374px] text-black mt-16 bg-[#7ACBFF] hover:bg-electric-blue rounded-md"
                    onClick={() => setIsOpen(false)}
                  >
                    OK
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default Modalbalances;
