import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import i18n from '../config/languageInternationalization';
import Sidebar from './Sidebar';
import { useAppSelector } from '../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { createCharge } from '../utils/createCharge';
import { chainOptions } from '../utils/chains';
import Select from './ThemedComponents/Select';
import { SupportedTestChains, chargeOptions } from '../utils/types';
import { toastError } from '../utils/toastify';
import { PaymentLink } from './invoices/CreateChargeModal';
import { PrimaryButton } from './ThemedComponents/Buttons/primary-button';
import TopBar from '../layout/TopBar';

const initialState = {
  symbol: 'MATIC',
  chain: 'POLYGON',
  tokenAddress: '0x0000000000000000000000000000000000000000',
  successUrl: '',
  cancelUrl: '',
  items: [
    {
      amount: '19.99',
      image:
        'https://images.esellerpro.com/3698/I/25/0008557_wenaas-chef-t-shirt-crew-neck-cotton-white.jpeg',
      name: 'Shirt',
      quantity: 1
    }
  ],
  amount: '19.99'
};

export const CreateCharge = () => {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const { testApiKey, testEnvironment: testEnviroment } = useAppSelector(
    (state) => state.user
  );

  const [paymentLink, setPaymentLink] = React.useState('');

  const testSupported = chainOptions.filter((chain) =>
    (Object.values(SupportedTestChains) as string[]).includes(chain.value)
  );

  const navigate = useNavigate();

  const onChangeText = (text: string, name: chargeOptions) => {
    if (name !== chargeOptions.AMOUNT) {
      setState({
        ...state,
        [name]: text
      });
    } else {
      const newState = { ...state };
      newState.items[0].amount = text;
      setState(newState);
    }
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!testApiKey) return toastError(i18n.t('createApiKeyAlert'));
      const chargeData: any = state;
      if (isNaN(Number(chargeData.items[0].amount))) {
        return toastError(i18n.t('createChargeAmountNumberError'));
      }
      // for testing purpouses it just one field to add the amount of the product
      // so it is replicated in both field
      chargeData.items[0].amount = Number(chargeData.items[0].amount);
      chargeData.amount = Number(chargeData.items[0].amount);
      await createCharge(testApiKey, true, state)
        .then((paymentUrl) => {
          setPaymentLink(paymentUrl);
        })
        .catch((error: any) => {
          return toastError(error?.message);
        });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!testEnviroment) {
      navigate('/overview');
    }
  }, [testEnviroment]);

  return (
    <>
      <section className="flex h-screen pr-8 pb-8">
        <div className="flex min-h-screen">
          <TopBar />
          <Sidebar />
        </div>
        <div className="flex flex-col w-full h-screen ml-64 justify-center">
          <div className="overflow-auto text-white">
            {/* Payment Link */}
            {paymentLink ? (
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-900 bg-opacity-30">
                <div className="bg-card-bg rounded-md flex flex-col p-8">
                  <PaymentLink paymentLink={paymentLink} />
                  <div className="flex w-1/2 mt-4">
                    <PrimaryButton
                      title={i18n.t('close')}
                      onClick={() => setPaymentLink('')}
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex w-1/2 m-auto place-self-center p-6 border bg-card-bg rounded-md border-card-stroke">
                <form
                  autoComplete="false"
                  onSubmit={onSubmit}
                  className="flex flex-1 md:w-1/2 flex-col justify-around bg-cyan-20"
                >
                  <p className="bg-red-800 font-bold rounded-md p-2 text-sm text-center">
                    {i18n.t('testMode')}
                  </p>
                  <p className="text-white text-sm mt-2 text-center">
                    {i18n.t('createChargeTestSubtitle')}
                  </p>
                  {!testApiKey && (
                    <>
                      <div className="flex flex-row justify-between">
                        <p className="text-white text-sm">
                          {i18n.t('createApiKeyAlert')}
                        </p>
                      </div>
                    </>
                  )}
                  <label
                    htmlFor="symbol"
                    className="font-normal text-gray-100 text-base tracking-[1%] my-2"
                  >
                    {i18n.t('chain')}
                  </label>
                  <Select
                    id="CreateCharge-Chain"
                    className="text-white bg-card-stroke border border-gray-700 h-14 px-2 rounded-md text-left flex items-center"
                    onChange={(v: ChangeEvent<HTMLInputElement>) => {
                      const chain = chainOptions.find(
                        (c) => c.value === v.target.value
                      );
                      setState({
                        ...state,
                        chain: chain?.value as string,
                        symbol: chain?.nativeToken as string,
                        tokenAddress: chain?.nativeAddress as string
                      });
                    }}
                    disabled={false}
                    defaultValue={state.chain}
                  >
                    {testSupported?.map((option, index) => {
                      return (
                        <option
                          className="bg-gray-700 text-sm"
                          value={option.value}
                          key={index}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </Select>
                  <label
                    htmlFor="tokenAddress"
                    className="font-normal text-gray-100 text-base tracking-[1%] my-2"
                  >
                    {i18n.t('tokenAddress')}
                  </label>
                  <p
                    id="tokenAddress"
                    className="select-none text-gray-500 bg-card-stroke border border-gray-700 h-14 px-2 rounded-md text-left flex items-center overflow-hidden"
                  >
                    {state.tokenAddress}
                  </p>
                  <label
                    htmlFor="successUrl"
                    className="font-normal text-gray-100 text-base tracking-[1%] my-2"
                  >
                    {i18n.t('successUrl')}
                  </label>
                  <div className="flex flex-1 justify-end">
                    <input
                      id="successUrl"
                      name="successUrl"
                      required
                      value={state.successUrl}
                      onChange={(v) =>
                        onChangeText(v.target.value, chargeOptions.SUCCESS_URL)
                      }
                      className="flex flex-1 border border-gray-700 bg-card-stroke rounded-md px-2 text-left h-14"
                    />
                  </div>
                  <label
                    htmlFor="cancelUrl"
                    className="font-normal text-gray-100 text-base tracking-[1%] my-2"
                  >
                    {i18n.t('cancelUrl')}
                  </label>
                  <div className="flex flex-1 justify-end">
                    <input
                      id="cancelUrl"
                      name="cancelUrl"
                      required
                      value={state.cancelUrl}
                      onChange={(v) =>
                        onChangeText(v.target.value, chargeOptions.CANCEL_URL)
                      }
                      className="flex flex-1 border border-gray-700 bg-card-stroke rounded-md px-2 text-left h-14"
                    />
                  </div>
                  <label
                    htmlFor="amount"
                    className="font-normal text-gray-100 text-base tracking-[1%] my-2"
                  >
                    {i18n.t('amount')}
                  </label>
                  <div className="flex flex-1 justify-end">
                    <input
                      id="amount"
                      name="amount"
                      required
                      value={state.items[0].amount}
                      onChange={(v) =>
                        onChangeText(v.target.value, chargeOptions.AMOUNT)
                      }
                      className="flex flex-1 border border-gray-700 bg-card-stroke rounded-md px-2 text-left h-14"
                    />
                  </div>
                  <div className="flex w-1/2 mt-6">
                    <PrimaryButton
                      title={i18n.t('createCharge')}
                      type="submit"
                      loading={loading}
                      disabled={loading}
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
