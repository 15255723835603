import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  addMember,
  getMembers,
  removeMember,
  updateMember
} from '../../../redux/slices/userThunk';
import { useAuth0 } from '@auth0/auth0-react';
import { MerchantRoles } from '../../../utils/types';
import i18n from '../../../config/languageInternationalization';
import { AddMemberComponent } from './add-member';
import { MembersTable } from './members-table';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';
import { PlusIcon } from '@heroicons/react/20/solid';
import { toastError, toastSuccess } from '../../../utils/toastify';

const Members: React.FunctionComponent = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const email = user?.email;
  const {
    members,
    role,
    email: userEmail
  } = useAppSelector((state) => state.user);
  React.useEffect(() => {
    if (!members) {
      dispatch(getMembers());
    }
  }, []);

  const [showAddMember, setShowAddMember] = React.useState<boolean>(false);
  const [memberToAdd, setMemberToAdd] = React.useState<string>('');
  const emptyRole = '--';
  const [roleToAdd, setRoleToAdd] = React.useState<string>(emptyRole);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');

  const resetForm = () => {
    setMemberToAdd('');
    setRoleToAdd(emptyRole);
    setShowAddMember(false);
    setError('');
  };
  const handleOnSubmit = async () => {
    setLoading(true);
    const response = await dispatch(
      addMember({ email: memberToAdd, role: roleToAdd })
    );
    if (response.type.includes('rejected')) {
      setError(
        typeof response.payload === 'string'
          ? response.payload
          : i18n.t('somethingWentWrongAddingMember')
      );
    } else {
      resetForm();
      toastSuccess(i18n.t('memberAddedSuccessfully'));
    }
    setLoading(false);
  };

  const handleDeleteMember = async (email: string) => {
    setLoading(true);
    setShowAddMember(false);
    if (email === userEmail) {
      toastError(i18n.t('cantDeleteYourself'));
      return;
    }
    const response = await dispatch(removeMember({ email }));
    if (response.type.includes('rejected')) {
      setError(
        typeof response.payload === 'string'
          ? response.payload
          : i18n.t('somethingWentWrongRemovingMember')
      );
    } else {
      resetForm();
      toastSuccess(i18n.t('memberRemovedSuccessfully'));
    }
    setLoading(false);
  };

  const handleUpdateMember = async ({
    email,
    role,
    event
  }: {
    email: string;
    role: MerchantRoles;
    event: React.ChangeEvent<HTMLSelectElement>;
  }) => {
    setLoading(true);
    setShowAddMember(false);
    if (email === userEmail) {
      toastError(i18n.t('cantChangeYourOwnRole'));
      event.target.value = role.toUpperCase();
      return;
    }
    const response = await dispatch(updateMember({ email, role }));
    if (response.type.includes('rejected')) {
      event.target.value = role.toUpperCase();
      setError(
        typeof response.payload === 'string'
          ? response.payload
          : i18n.t('somethingWentWrongUpdatingMember')
      );
    } else {
      resetForm();
      toastSuccess(i18n.t('memberUpdatedSuccessfully'));
    }
    setLoading(false);
  };

  return (
    <>
      <div className="my-8">
        <div className="flex justify-end mb-8">
          <div className="flex items-center justify-center">
            <PrimaryButton
              icon={<PlusIcon className="flex w-4 h-4" />}
              onClick={() => setShowAddMember(true)}
              title={i18n.t('addMember')}
            />
          </div>
        </div>
        {role !== MerchantRoles.MEMBER && (
          <div>
            {showAddMember && (
              <AddMemberComponent
                showAddMember={showAddMember}
                setShowAddMember={setShowAddMember}
                memberToAdd={memberToAdd}
                setMemberToAdd={setMemberToAdd}
                roleToAdd={roleToAdd}
                setRoleToAdd={setRoleToAdd}
                loading={loading}
                handleOnSubmit={handleOnSubmit}
                error={error}
                emptyRole={emptyRole}
                setError={setError}
              />
            )}
          </div>
        )}
        <MembersTable
          members={members}
          role={role}
          email={email}
          handleUpdateMember={handleUpdateMember}
          handleDeleteMember={handleDeleteMember}
          loading={loading}
        />
      </div>
    </>
  );
};

export default Members;
