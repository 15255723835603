import React from 'react';
import { useAppSelector } from '../../redux/hooks';
import { processColor } from '../../utils/parseColor';

const useCustomTheme = () => {
  const { theme } = useAppSelector((state) => state.user);

  function isColorLight(color?: string) {
    if (!color) return false;
    // Convert color string to RGB components
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);

    // Calculate brightness using formula
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Return true if color is light, false otherwise
    return brightness > 125; // You can adjust this threshold as needed
  }

  const isPrimaryColorLight =
    theme && theme.primaryColor ? isColorLight(theme.primaryColor) : false;

  const getActiveBackgroundColor = () => {
    if (theme && theme.primaryColor) {
      return processColor(theme.primaryColor, 0.4, true);
    } else {
      return 'rgb(100 116 139 / var(--tw-bg-opacity))';
    }
  };

  const getActiveColor = () => {
    return theme && theme.primaryColor
      ? processColor(theme.primaryColor, 0.6, !isPrimaryColorLight)
      : '#ffffff';
  };

  const getTextColor = () => {
    return theme && theme.primaryColor
      ? processColor(theme.primaryColor, 0.6, !isPrimaryColorLight)
      : '#f3f4f6';
  };

  const getActiveTextColor = () => {
    return theme && theme.primaryColor
      ? processColor(theme.primaryColor, 0.6, !isPrimaryColorLight)
      : '#ffffff';
  };

  const getButtonColor = () => {
    return theme && theme.primaryColor
      ? {
          backgroundColor: theme.primaryColor,
          color: processColor(theme.primaryColor, 0.5, !isPrimaryColorLight)
        }
      : {
          backgroundColor: '#1d4ed8',
          color: '#9CA3AF'
        };
  };

  const getButtonHoverColor = () => {
    return theme && theme.primaryColor
      ? {
          backgroundColor: processColor(
            theme.primaryColor,
            0.4,
            !isPrimaryColorLight
          ),
          color: processColor(theme.primaryColor, 0.7, !isPrimaryColorLight)
        }
      : {
          backgroundColor: '#2563EB',
          color: '#ffffff'
        };
  };

  const getTableHeadStyle = () => {
    return theme && theme.primaryColor
      ? {
          backgroundColor: theme?.primaryColor,
          color: processColor(theme?.primaryColor, 0.6, !isPrimaryColorLight)
        }
      : {
          backgroundColor: '#374151',
          color: '#ffffff'
        };
  };

  const getTableBodyStyle = () => {
    return {
      backgroundColor:
        theme && theme.primaryColor
          ? processColor(theme.primaryColor, 0.2, !isPrimaryColorLight)
          : '#1F2A37',
      color: '#ffffff'
    };
  };

  return {
    getTableHeadStyle,
    getTableBodyStyle,
    getActiveColor,
    getActiveBackgroundColor,
    getActiveTextColor,
    getTextColor,
    getButtonColor,
    getButtonHoverColor
  };
};

export default useCustomTheme;
