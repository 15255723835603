import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Loading from '../components/Loading';
import { useAppSelector } from '../redux/hooks';
import { useAuthStatus } from '../hooks/useAuthStatus';
import { useAuth } from 'react-oidc-context';
import { logout } from '../components/Sidebar/Account/LogOut';

const ProtectedRoute = () => {
  const user = useAppSelector((state) => state.user);
  const auth = useAuth();
  const { isAuthLoading, isAuthenticated } = useAuthStatus();
  if (auth.error) {
    logout(auth.signoutSilent);
  }

  const isAllowed = () => {
    if (auth.isAuthenticated && auth.user?.profile.email_verified) return true;
    return user.isAuthenticated;
  };

  if (isAuthLoading || (isAuthenticated && user.loading)) {
    return (
      <div className="h-screen w-screen flex justify-center items-center bg-card-bg">
        <Loading />
      </div>
    );
  } else if (isAllowed()) {
    return (
      <div>
        <Outlet />
      </div>
    );
  } else if (!auth.user?.profile.email) {
    return <Navigate to={'/addEmail'} />;
  } else {
    return <Navigate to={'/'} />;
  }
};

export default ProtectedRoute;
