import parsePhoneNumber from 'libphonenumber-js';

/**
 * Returns a formatted number depending on the country used.
 */

/**
 * Formats a phone number into international format.
 * @param {string} phone - String containing the phone number.
 * @returns {string} The formatted phone number in international format.
 */
const handlePhoneNumberChange = (phone: string): string => {
  let formattedPhoneNumber = phone;

  const parsedPhoneNumber = parsePhoneNumber(phone);
  if (parsedPhoneNumber) {
    formattedPhoneNumber = parsedPhoneNumber.formatInternational();
  }
  return formattedPhoneNumber;
};

export default handlePhoneNumberChange;
