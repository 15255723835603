import React, { ChangeEvent, useState } from 'react';
import { TagIcon } from '@heroicons/react/20/solid';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateMerchantData } from '../../../redux/slices/userThunk';
import i18n from '../../../config/languageInternationalization';
import { toastError, toastSuccess } from '../../../utils/toastify';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

const FeeSelector = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fee, setFee] = useState<number>(0);
  const { merchantFee } = useAppSelector((state) => state.user);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numericValue = Number(e.target.value);

    if (!isNaN(numericValue)) {
      setFee(numericValue);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (fee === merchantFee) {
      setShowModal(false);
      setLoading(false);
      return;
    }

    if (fee <= 50 && fee >= 0) {
      try {
        await dispatch(
          updateMerchantData({
            url: `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}/merchant`,
            updateData: { merchantFee: fee }
          })
        );

        toastSuccess(i18n.t('merchantFeeUpdatedSuccessfully'));
        setShowModal(false);
        setLoading(false);
      } catch (error: any) {
        toastError(`${i18n.t('merchantFeeError')}${error}`);
        setLoading(false);
      }
    } else {
      toastError(i18n.t('merchantFeeValueError'));
      setLoading(false);
    }
  };

  return (
    <div>
      <PrimaryButton
        title={i18n.t('setMerchantFee') + ` (${merchantFee || 0}%)`}
        icon={<TagIcon className="w-4 h-4" />}
        onClick={() => setShowModal(true)}
        disabled={loading}
      />

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-900 bg-opacity-30">
            <div className="relative w-80 max-w-3xl">
              {/*BOX*/}
              <div className="rounded-lg relative flex gap-5 flex-col w-full bg-gray-800 p-8">
                {/*Header*/}
                <h3 className="text-xl font-medium text-gray-50">
                  {i18n.t('configuration')}
                </h3>

                {/*Body*/}
                <div className="flex flex-col justify-between gap-5">
                  <div className="flex flex-col gap-2">
                    <h4 className="text-white">{i18n.t('merchantFee')}</h4>
                    <div className="flex">
                      <input
                        className="flex w-full justify-between items-center bg-gray-700 border border-gray-600 rounded-lg text-gray-300 py-[10px] px-4 text-sm focus:ring-1 focus:ring-blue-100"
                        onChange={handleChange}
                        name={'fee'}
                        value={fee || 0}
                        minLength={1}
                        maxLength={2}
                        type="number"
                        max={50}
                        min={0}
                      />
                    </div>
                  </div>

                  {/*Buttons*/}
                  <div className="flex gap-4 h-10">
                    <PrimaryButton
                      title={i18n.t('close')}
                      onClick={() => setShowModal(false)}
                      loading={loading}
                    />

                    <PrimaryButton
                      onClick={handleSubmit}
                      loading={loading}
                      title={i18n.t('send')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default FeeSelector;
