import React from 'react';
import i18n from '../config/languageInternationalization';
import { useAppSelector } from '../redux/hooks';
import images from '../assets/images/brand';
import BellIcon from '../assets/icons/bellIcon';
import { caseName } from '../utils/caseName';
import useCustomTheme from '../components/ThemedComponents/use-custom-theme';

const TopBar = () => {
  const { theme, testEnvironment, profilePicture, name, isSidebarOpen } =
    useAppSelector((state) => state.user);
  const { getTextColor } = useCustomTheme();

  const textColor = getTextColor();

  return (
    <div
      className="fixed top-0 z-50 w-full py-3 px-5 border-b border-b-gray-700 gap-10 flex"
      style={{
        backgroundColor: theme?.primaryColor ?? '#1f2937'
      }}
    >
      <div className="flex flex-1 justify-start items-center">
        <img
          src={theme?.logo ?? images?.LogoWhite}
          alt="SphereOne Logo"
          className="h-8 w-8 mr-1.5 ml-4"
        />
        {isSidebarOpen && (
          <p
            style={{ color: textColor }}
            className={`text-lg font-normal tracking-wider mr-6`}
          >
            SphereOne
          </p>
        )}

        {/* TODO: ENG-2191 */}
        {/* <SearchInput /> */}
      </div>

      <div className="mx-auto justify-center flex flex-1 items-center">
        <p className="flex items-center text-sm font-normal ">
          {testEnvironment && (
            <span className="[&_p]:inline bg-red-800 text-white text-xs md:text-sm font-extralight md:font-medium p-2 rounded-sm">
              {i18n.t('testModeBanner')}
            </span>
          )}
        </p>
      </div>

      {/* Avatar and notification */}
      <div className="flex flex-1  justify-center items-center">
        <div className="flex flex-1 justify-end items-center pr-5 space-x-4">
          <BellIcon />
          {profilePicture ? (
            <img
              src={profilePicture}
              alt="Avatar"
              className="h-8 w-8 rounded-full "
            />
          ) : (
            <div
              className={`text-blue-300 bg-gray-800 border h-8 w-8 rounded-full flex justify-center items-center`}
            >
              <span>{caseName(name)}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
