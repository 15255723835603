export function processColor(
  color: string,
  percent: number,
  lighten: boolean
): string {
  if (!color) return color;

  let r: number, g: number, b: number;
  const isHex = color.startsWith('#');

  if (isHex) {
    // Extract RGB from hex
    if (color.length === 7) {
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
    } else if (color.length === 4) {
      r = parseInt(color[1] + color[1], 16);
      g = parseInt(color[2] + color[2], 16);
      b = parseInt(color[3] + color[3], 16);
    } else {
      throw new Error('Invalid hex color format.');
    }
  } else if (color.startsWith('rgb')) {
    // Extract RGB from rgb string
    const parts = color.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
    if (!parts) {
      throw new Error('Invalid RGB color format.');
    }
    r = parseInt(parts[1]);
    g = parseInt(parts[2]);
    b = parseInt(parts[3]);
  } else {
    throw new Error(
      'Unsupported color format. Only hex and RGB are supported.'
    );
  }

  // Modify brightness
  const adjust = lighten
    ? (val: number) => Math.min(255, val + Math.round(255 * percent))
    : (val: number) => Math.max(0, val - Math.round(255 * percent));
  r = adjust(r);
  g = adjust(g);
  b = adjust(b);

  // Return in the same format as input
  return isHex
    ? `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
        .toString(16)
        .padStart(2, '0')}`
    : `rgb(${r}, ${g}, ${b})`;
}

function lightenColor(color: string, percent: number): string {
  return processColor(color, percent, true);
}

function darkenColor(color: string, percent: number): string {
  return processColor(color, percent, false);
}

// // Example usage
// console.log(lightenColor('#ff5733', 0.2)); // Lighter hex color
// console.log(darkenColor('rgb(255, 87, 51)', 0.2)); // Darker RGB color
