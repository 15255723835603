import React, { useState, useEffect } from 'react';
import BalancesTable from '../../components/BalancesSection/BalancesTable';
import ModalBalances from './Modalbalances';
import i18n from '../../config/languageInternationalization';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import BalancesHeader from '../../components/BalancesSection/BalancesHeader';
import { SidebarLayout } from '../../layout/Sidebar';
import Loading from '../../components/Loading';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { getUserBalances } from '../../redux/slices/userThunk';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MerchantRoles } from '../../utils/types';
import { PrimaryButton } from '../../components/ThemedComponents/Buttons/primary-button';

export default function Balances() {
  const [isCashoutModalOpen, setIsCashoutModalOpen] = useState(false);
  const {
    balanceTokens,
    totalBalance,
    testEnvironment,
    totalTestBalance,
    balanceTokensTestnet,
    isCountrySupportedForOfframp,
    role
  } = useAppSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (balanceTokens !== undefined && totalBalance !== undefined)
      setIsLoading(false);
    if (
      testEnvironment &&
      totalTestBalance !== undefined &&
      balanceTokensTestnet !== undefined
    ) {
      setIsLoading(false);
    }
  }, [balanceTokens, totalBalance, totalTestBalance, balanceTokensTestnet]);

  const fetchBalances = async () => {
    setIsLoading(true);
    if (testEnvironment) {
      await dispatch(getUserBalances({ refreshCache: true, testnet: true }));
    } else {
      await dispatch(
        getUserBalances({ refreshCache: true, testnet: false })
      ).unwrap();
    }
    setIsLoading(false);
  };

  const handleCashout = async () => {
    if (!isCountrySupportedForOfframp || role !== MerchantRoles.OWNER) {
      setIsCashoutModalOpen(true);
    } else {
      navigate('/balances/cashout');
    }
  };

  if (location.pathname === '/balances/cashout') {
    return <Outlet />;
  }

  return (
    <SidebarLayout>
      <ModalBalances
        setIsOpen={setIsCashoutModalOpen}
        isOpen={isCashoutModalOpen}
      />

      {/* Header */}
      <div className="flex items-center justify-between mb-4 min-h-[40px]">
        {/* Title */}
        <div className="flex gap-x-2">
          <h1 className="text-gray-400 font-semibold text-2xl">
            {i18n.t('balances')}
          </h1>
          <ArrowPathIcon
            className="text-white h-8 hover:cursor-pointer hover:text-gray-300"
            onClick={fetchBalances}
          />
        </div>

        {/* Cash button */}
        <div className="flex min-w-[100px] relative">
          <PrimaryButton
            onClick={handleCashout}
            title={i18n.t('cashout')}
            disabled={role !== MerchantRoles.OWNER}
            loading={isCashoutModalOpen}
            className="text-sm"
            autoFocus={true}
          />
        </div>
      </div>

      {!isLoading ? (
        <>
          <BalancesHeader
            totalBalance={!testEnvironment ? totalBalance : totalTestBalance}
          />
          <div>
            <BalancesTable
              balanceTokens={
                !testEnvironment ? balanceTokens : balanceTokensTestnet
              }
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col h-[80%] rounded-md p-20 items-center justify-between bg-card-bg">
            <Loading />
          </div>
        </>
      )}
    </SidebarLayout>
  );
}
