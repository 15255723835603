import React, { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { toastError, toastSuccess } from '../../../utils/toastify';
import i18n from '../../../config/languageInternationalization';
import { postDocumentData, serverEndpoint } from '../../../utils/server';
import { updateApiKey } from '../../../redux/slices/user';
import { ApiKeyEnvironments } from './generate-apikey';
import { MerchantRoles } from '../../../utils/types';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

export const CreateApiKey = ({
  onClose,
  isLoading,
  setIsLoading
}: {
  onClose: () => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { uid, accessToken, idToken, isVerified, role } = useAppSelector(
    (state) => state.user
  );
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [confirmModal, setConfirmModal] = useState(false);
  const [keyType, setKeyType] = useState<ApiKeyEnvironments>(
    ApiKeyEnvironments.TEST
  );

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!isVerified && keyType === ApiKeyEnvironments.PROD) {
      return toastError(i18n.t('notVerifiedAlert'));
    }

    if (role === MerchantRoles.MEMBER) {
      return toastError(i18n.t('notAllowedMember'));
    }

    if (keyType === ApiKeyEnvironments.PROD && role !== MerchantRoles.OWNER) {
      return toastError(i18n.t('notOwner'));
    }

    setConfirmModal(true);
  };

  const generateApiKey = async ({ isTest }: { isTest: boolean }) => {
    if (!uid || !accessToken || !idToken) {
      return;
    }

    if (!auth.user?.profile.email_verified) {
      return toastError(i18n.t('verifyEmail'));
    }

    setIsLoading(true);
    try {
      const response = await postDocumentData({
        url: `${serverEndpoint}/generateApiKey`,
        data: { isTest },
        accessToken,
        idToken
      });
      const { data, error } = response;
      if (!error) {
        toastSuccess(i18n.t('generateApiKeySuccess'));
        dispatch(updateApiKey({ apiKey: data, isTest }));
      } else {
        throw new Error(i18n.t('generateApiKeyUnableToGenerate'));
      }
    } catch (error: any) {
      toastError((error.response?.data?.error || error.message) as string);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col justify-between items-start px-4 pt-4 pb-8 bg-gray-800 rounded-lg border border-solid border-gray-700"
      >
        <div className="flex justify-between self-stretch text-lg font-medium leading-7 text-gray-200 max-md:max-w-full">
          <h3>{i18n.t('createAPIKey')}</h3>
          <button onClick={onClose}>
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        <div className="mt-6 text-sm font-medium leading-5 text-white whitespace-nowrap">
          <h4>{i18n.t('keyType')}</h4>
        </div>
        <div className="flex gap-4 items-stretch mt-2">
          <label className="flex items-center" htmlFor="testApiKey">
            <input
              type="radio"
              id="testApiKey"
              value={ApiKeyEnvironments.TEST}
              checked={keyType === ApiKeyEnvironments.TEST}
              onChange={(e) => setKeyType(e.target.value as ApiKeyEnvironments)}
              className="form-radio h-4 w-4 text-blue-500 rounded-md border-gray-300"
            />
            <span className="ml-2 text-sm leading-4 text-gray-400">
              {i18n.t('test')}
            </span>
          </label>
          <label className="flex items-center" htmlFor="prodApiKey">
            <input
              type="radio"
              id="prodApiKey"
              value={ApiKeyEnvironments.PROD}
              checked={keyType === ApiKeyEnvironments.PROD}
              onChange={(e) => setKeyType(e.target.value as ApiKeyEnvironments)}
              className="form-radio h-4 w-4 text-blue-500 rounded-md border-gray-300"
            />
            <span className="ml-2 text-sm leading-4 text-gray-400">
              {i18n.t('production')}
            </span>
          </label>
        </div>
        <div className="flex w-1/4 min-w-[300px] gap-4 items-stretch mt-6">
          <PrimaryButton
            title={i18n.t('cancel')}
            loading={isLoading}
            onClick={onClose}
          />
          <PrimaryButton
            onClick={() => setConfirmModal(true)}
            disabled={
              isLoading ||
              !keyType ||
              role === MerchantRoles.MEMBER ||
              (keyType === ApiKeyEnvironments.PROD &&
                role !== MerchantRoles.OWNER)
            }
            loading={isLoading}
            title={i18n.t('createAPIKey')}
            autoFocus={true}
          />
        </div>
      </form>
      {confirmModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-8">
          <div className="flex flex-col justify-between items-start bg-gray-800 rounded-lg border border-solid border-gray-700 p-8">
            <h3 className="text-gray-50 text-xl font-medium leading-6 mb-1">
              {i18n.t('generateApiKeyConfirmNewApiKey')}
            </h3>
            <p className="text-gray-400 text-sm font-normal leading-6">
              {i18n.t('generateApiKeyConfirmNewApiKeySubTitle')}
            </p>

            <div className="flex w-1/2 gap-4 items-stretch mt-5">
              <PrimaryButton
                title={i18n.t('close')}
                loading={isLoading}
                onClick={() => setConfirmModal(false)}
              />
              <PrimaryButton
                onClick={() =>
                  generateApiKey({
                    isTest: keyType === ApiKeyEnvironments.TEST
                  })
                }
                loading={isLoading}
                title={i18n.t('confirm')}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateApiKey;
