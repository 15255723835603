import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';

const oidcConfig = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY as string,
  client_id: process.env.REACT_APP_MERCHANT_AUTH_CLIENT_ID as string,
  redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI as string,
  response_type: 'code',
  scope: 'openid profile offline_access',
  userStore: new WebStorageStateStore({
    store: window.localStorage
  }),
  automaticSilentRenew: true
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
