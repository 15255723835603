import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Button from '../../ThemedComponents/Buttons/button';
import i18n from '../../../config/languageInternationalization';
import { isSafeUrl } from '../../../utils/validation';
import { setWebhookUrl } from '../../../redux/slices/userThunk';
import { toastError, toastSuccess } from '../../../utils/toastify';
import { Tooltip } from '../../ThemedComponents/Tooltip';
import { MerchantRoles } from '../../../utils/types';
import axios from 'axios';
import {
  callFunction,
  getDocumentData,
  merchantDataEndpoint,
  postDocumentData
} from '../../../utils/server';
import { DocumentDuplicateIcon } from '@heroicons/react/20/solid';
import { copyToClipBoard } from '../apikey-tab/generate-apikey';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

const MerchantWebhook = () => {
  const [isLoadingWebhookUrl, setIsLoadingWebhookUrl] = useState(false);
  const [isLoadingWebhookSecret, setIsLoadingWebhookSecret] = useState(false);
  const { merchantEndpoint, loading, role, accessToken, idToken } =
    useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [newEndpoint, setNewEndpoint] = useState(merchantEndpoint);

  const onChangeText = (e: any) => {
    setNewEndpoint(e.target.value);
  };

  const handleSetWebhookUrl = async (e: React.FormEvent) => {
    e.preventDefault();
    if (role === MerchantRoles.MEMBER) return;
    if (!newEndpoint) return;
    if (!isSafeUrl(newEndpoint)) return toastError(`${i18n.t('invalidUrl')}`);
    setIsLoadingWebhookUrl(true);

    try {
      const res = await axios.post(newEndpoint, { data: { isTest: true } });
      if (res.status !== 200) throw new Error('Invalid response');
    } catch (e) {
      setIsLoadingWebhookUrl(false);
      return toastError('Invalid response');
    }

    try {
      await dispatch(setWebhookUrl(newEndpoint));
      toastSuccess(`${i18n.t('webhookUpdatedSuccessfully')}`);
    } catch (error: any) {
      alert(error);
    } finally {
      setIsLoadingWebhookUrl(false);
    }
  };

  const handleUpdateWebhookSecret = async () => {
    if (role === MerchantRoles.MEMBER) return;
    setIsLoadingWebhookSecret(true);
    try {
      const response = await postDocumentData({
        url: `${merchantDataEndpoint}/webhook/secret`,
        data: {},
        accessToken: accessToken as string,
        idToken: idToken as string
      });
      const webhookSecret = response.data;
      await copyToClipBoard(
        webhookSecret as string,
        i18n.t('webhookSecretCopiedError')
      );
      toastSuccess(`${i18n.t('webhookSecretUpdatedAndCopied')}`);
    } catch (error: any) {
      toastError(i18n.t('webhookSecretUpdateError'));
    } finally {
      setIsLoadingWebhookSecret(false);
    }
  };

  const handleCopySecret = async () => {
    try {
      const response = await getDocumentData({
        url: `${merchantDataEndpoint}/webhook/secret`,
        accessToken,
        idToken
      });
      const webhookSecret = response.data;
      await copyToClipBoard(
        webhookSecret as string,
        i18n.t('webhookSecretCopiedError')
      );
      toastSuccess(`${i18n.t('webhookSecretCopied')}`);
    } catch (error: any) {
      toastError(i18n.t('webhookSecretGetError'));
    }
  };

  return (
    <>
      <div className="bg-card-bg flex flex-col p-4 rounded-md">
        <div className="flex flex-col mb-2">
          <div className="flex items-center gap-4">
            <h3 className="text-gray-200 text-lg font-medium">
              Listen to SphereOne events
            </h3>
            <Tooltip>
              <div className="flex flex-col justify-center items-center">
                {i18n.t('webhookInfoTooltip')}
                <a
                  href="https://docs.sphereone.xyz/docs/webhooks"
                  className="text-left bg-blue-100 p-1 rounded-sm my-2 max-w-fit"
                  target="_blank"
                  rel="noreferrer"
                >
                  {i18n.t('webhookMoreInfo')}
                </a>
              </div>
            </Tooltip>
          </div>
          <p className="text-gray-400 text-sm">
            Set up your webhook endpoint to receive live events from SphereOne
          </p>
        </div>

        <div>
          <p className="mt-7 mb-2 text-white text-sm font-medium">
            Endpoint URL
          </p>
          <form
            className="flex flex-row gap-4"
            onSubmit={(e) => handleSetWebhookUrl(e)}
          >
            <input
              name="webhookUrl"
              className="flex max-w-md justify-between items-center bg-card-bg border border-card-stroke rounded-lg text-gray-300 py-3 px-4 text-base focus:ring-1 focus:ring-blue-100 w-full"
              placeholder={
                !merchantEndpoint
                  ? i18n.t('setYourEndpoint')
                  : `${i18n.t('yourEndpointIs')} ${merchantEndpoint}`
              }
              defaultValue={merchantEndpoint ?? ''}
              onChange={onChangeText}
              readOnly={role === MerchantRoles.MEMBER}
            />
            {role !== MerchantRoles.MEMBER && (
              <div className="flex min-w-[150px]">
                <PrimaryButton
                  loading={isLoadingWebhookUrl || loading}
                  type="submit"
                  title={i18n.t('saveChanges')}
                />
              </div>
            )}
          </form>
          <p className="text-gray-400 mt-2 ml-1 text-sm italic">
            Server must respond with status 200
          </p>
        </div>

        {/* Webhook Secret */}
        <div>
          <p className="mt-7 mb-2 text-white text-sm font-medium">
            Webhook Secret
          </p>
          <div className="flex flex-row gap-4">
            <label className="flex max-w-md justify-between items-center bg-card-bg border border-card-stroke rounded-lg text-gray-300 py-3 px-4 text-base focus:ring-1 focus:ring-blue-100 w-full">
              <span>{'**************************'}</span>
              <DocumentDuplicateIcon
                width={20}
                height={20}
                className="text-blue-400 cursor-pointer transform hover:scale-125 active:scale-100"
                onClick={handleCopySecret}
              />
            </label>

            {role !== MerchantRoles.MEMBER && (
              <div className="flex min-w-[150px]">
                <PrimaryButton
                  autoFocus={true}
                  loading={isLoadingWebhookSecret}
                  onClick={handleUpdateWebhookSecret}
                  title={i18n.t('webhookSecretUpdate')}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantWebhook;
