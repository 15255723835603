export enum TxStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  CANCELED = 'CANCELED'
}
export interface TransactionHistoryType {
  dateCreated: {
    _seconds: Date | number;
    _nanoseconds: Date | number;
  };
  toAddress: string;
  uid: string;
  status: TxStatus;
  total: number;
  totalUSD?: number;
  toAddressLabel?: string;
  toAddressUser?: boolean;
  merchantUid?: string;
  name: string;
  email: string;
  address: string[];
  amount: number;
  symbol: string;
  invoiceId: string;
  transactionDate: number;
}

export interface TokenMetadata {
  symbol: string;
  name: string;
  decimals?: number;
  address: string;
  logoURI?: string;
  chain?: string;
}

export interface Balance {
  address: string;
  amount: string;
  price: string;
  chain: SupportedChains;
  tokenMetadata: TokenMetadata;
}

export const tokensInitialState = {
  ETHEREUM: [],
  POLYGON: [],
  ARBITRUM: [],
  SOLANA: [],
  OPTIMISM: [],
  AVALANCHE: [],
  BINANCE: [],
  IMMUTABLE: [],
  EOSEVM: [],
  BASE: [],
  FLOW: [],
  KLAYTN: [],
  DFK: [],
  BITCOIN: [],
  DOS: [],
  SUI: [],
  SKALE_EUROPA: [],
  SKALE_NEBULA: []
};

export enum SupportedChains {
  ETHEREUM = 'ETHEREUM',
  POLYGON = 'POLYGON',
  ARBITRUM = 'ARBITRUM',
  SOLANA = 'SOLANA',
  OPTIMISM = 'OPTIMISM',
  AVALANCHE = 'AVALANCHE',
  BINANCE = 'BINANCE',
  IMMUTABLE = 'IMMUTABLE',
  EOSEVM = 'EOSEVM',
  BASE = 'BASE',
  FLOW = 'FLOW',
  KLAYTN = 'KLAYTN',
  DFK = 'DFK',
  DOS = 'DOS',
  SUI = 'SUI',
  SKALE_EUROPA = 'SKALE_EUROPA',
  SKALE_NEBULA = 'SKALE_NEBULA',
  BITCOIN = 'BITCOIN'
}

export enum SupportedTestChains {
  ETHEREUM = 'ETHEREUM',
  POLYGON = 'POLYGON',
  ARBITRUM = 'ARBITRUM',
  SOLANA = 'SOLANA',
  OPTIMISM = 'OPTIMISM',
  AVALANCHE = 'AVALANCHE',
  BINANCE = 'BINANCE',
  EOSEVM = 'EOSEVM'
}

export type supportedTokens = {
  [key in SupportedChains]: TokenMetadata[];
};

export interface Graph {
  title: string;
  dataKey: string;
  data: WeeklyData[];
}

export interface WeeklyData {
  date?: string;
  income?: number;
  gross: number;
  customers: number;
  payments: number;
}

export enum weeklyDataProps {
  INCOME = 'income',
  GROSS = 'gross',
  CUSTOMERS = 'customers',
  PAYMENTS = 'payments'
}

export interface CustomerData {
  customerUid?: string;
  lastTransaction: number;
  profilePicture?: string;
  email?: string;
  name?: string;
  registered?: FirestoreDate;
  // walletAddress?: string[];
  lastLogin?: FirestoreDate;
}

export interface TransactionData {
  customerData: CustomerData;
  amount: string;
  invoiceId: string;
  status: string;
  symbol: string;
}

export enum PageDirection {
  Forward = 'forward',
  Backward = 'backward',
  Actual = 'actual'
}

export interface ThemeData {
  primaryColor: string;
  logo: string;
}

export interface LinkedAccountWallet {
  id: string;
  type: 'wallet';
  address: string;
  chains: SupportedChains[];
  isImported?: boolean;
  label?: string;
  readOnly?: boolean;
  missingStarkKey: boolean;
}

export enum chargeOptions {
  SUCCESS_URL = 'successUrl',
  CANCEL_URL = 'cancelUrl',
  AMOUNT = 'amount',
  CHAIN = 'chain'
}

export enum MerchantRoles {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER'
}

export interface MerchantMembers {
  role: MerchantRoles;
  email: string;
  createdAt?: number;
  name?: string;
  profileImageURL?: string;
  lastActive?: number;
}

export interface FirestoreDate {
  _nanoseconds: number;
  _seconds: number;
}
