import { XCircleIcon } from '@heroicons/react/20/solid';
import { Modal } from 'flowbite-react';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import i18n from '../../config/languageInternationalization';
import { getTokenPrice } from './CreateChargeModal';
import Button from '../ThemedComponents/Buttons/button';

interface TxDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  transaction: any;
}

const TxDetailModal: React.FC<TxDetailModalProps> = ({
  isOpen,
  onClose,
  transaction
}: TxDetailModalProps): React.ReactElement => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });

  const [totalUsd, setTotalUsd] = React.useState(0);
  useEffect(() => {
    getTokenPrice(
      transaction.chain,
      transaction.tokenAddress,
      transaction.total,
      transaction.symbol
    ).then((price) => {
      setTotalUsd(price);
    });
  }, []);
  return (
    <Modal
      dismissible
      popup
      show={isOpen}
      onClose={onClose}
      className="dark mt-64 md:mt-0"
      size={isMobile ? 'sm' : 'md'}
    >
      <div className="bg-gray-800 rounded-lg relative md:p-4 py-6 mx-[-40px] md:mx-[-100px]">
        <XCircleIcon
          className="text-red-500 h-5 absolute right-4 top-4 hover:cursor-pointer hover:text-red-300"
          onClick={onClose}
        />
        <h1 className="text-white text-lg ml-10">Invoice details</h1>
        <ul>
          <TxProperty title={'Charge #'} value={transaction.uid} />
          <TxProperty title={'Symbol'} value={transaction.symbol} />
          <TxProperty title={'Chain'} value={transaction.chain} />
          <TxProperty title={'Token amount'} value={transaction.total} />
          <TxProperty title={'Status'} value={transaction.status} />
          <TxProperty title={'Total usd'} value={totalUsd} />
        </ul>
        <div className="flex justify-end mr-8">
          <Button onClick={onClose}>{i18n.t('close')}</Button>
        </div>
      </div>
    </Modal>
  );
};

const TxProperty = ({ title, value }: { title: string; value: any }) => {
  return (
    <li className="px-10 py-4">
      <div className="flex flex-row justify-between border-b border-gray-100 py-2">
        <h3 className="text-gray-100">{title}</h3>
        <h3 className="text-white">{value}</h3>
      </div>
    </li>
  );
};

export default TxDetailModal;
