import React, { useState } from 'react';
import { Button, Label, Modal, Textarea } from 'flowbite-react';
import i18n from '../../../config/languageInternationalization';
import { toastError, toastSuccess } from '../../../utils/toastify';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  isBtcAddress,
  isEvmPrivateKeyLength,
  isMnemonic,
  isMnemonicValid,
  isSolPrivateKeyLength
} from '../../../utils/web3';
import { callFunction, formatErrorMessage } from '../../../utils/server';
import InsertPinCode from '../../InsertPinCode';
import {
  AddIMXStarkPrivateKey,
  ModalStateEnumIMX
} from './add-imx-stark-privatekey';
import { getLinkedAccounts } from '../../../redux/slices/userThunk';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

export enum TargetOperation {
  ADD_WALLET = 'ADD_WALLET',
  GET_PRIVATE_KEY = 'GET_PRIVATE_KEY',
  CHECK_STARK_PRIVATE_KEY = 'CHECK_STARK_PRIVATE_KEY'
}

export function RecoverWallet({
  recoverWalletModal,
  setRecoverWalletModal
}: {
  recoverWalletModal: boolean;
  setRecoverWalletModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { accessToken } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [recovery, setRecovery] = React.useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [modalPinCode, setModalPinCode] = useState(false);
  const [wrappedDek, setWrappedDek] = React.useState('');

  // IMX States
  const [immutableModal, setImmutableModal] = React.useState(false);
  const [
    paramsToAddImutableStarkPrivateKey,
    setParamsToAddImutableStarkPrivateKey
  ] = React.useState<{
    accessToken: string;
    publicKey: string;
    recoveryKey: { mnemonic: string } | { privateKey: string };
  }>({ accessToken: '', publicKey: '', recoveryKey: { privateKey: '' } });
  const [modal, setModal] = React.useState(ModalStateEnumIMX.INIT);
  const [starkPK, setStarkPK] = React.useState('');
  const [starkPKError, setStarkPKError] = React.useState('');

  const onSubmit = async () => {
    setError('');
    try {
      if (!accessToken) throw new Error(i18n.t('accessTokenError'));

      if (isMnemonic(recovery) && !isMnemonicValid(recovery)) {
        setError(i18n.t('recoverWalletScreenMnemonicInvalidLengthError'));
      } else if (
        !isBtcAddress(recovery) &&
        !isMnemonic(recovery) &&
        !isSolPrivateKeyLength(recovery) &&
        !isEvmPrivateKeyLength(recovery)
      ) {
        setError(i18n.t('recoverWalletScreenPkInvalidLengthError'));
      } else {
        setLoading(true);
        setModalPinCode(true);
      }
    } catch (e: any) {
      setLoading(false);
      toastError(i18n.t('errorImportingWallet'));
    }
  };

  const callback = async (wrappedDek: string) => {
    if (!accessToken) return;
    setWrappedDek(wrappedDek);
    const reqParams: any = { DEK: wrappedDek };
    if (isMnemonic(recovery)) reqParams.mnemonic = recovery;
    else reqParams.privateKey = recovery;
    const { data, error } = await callFunction({
      url: '/importWallet',
      method: 'POST',
      data: { ...reqParams },
      accessToken
    });
    if (error) throw new Error(error);
    if (data) {
      setRecovery('');
      const recoveryKey = {
        mnemonic: reqParams?.mnemonic,
        privateKey: reqParams?.privateKey
      };
      if (data.isRegisteredOnImmutable) {
        setParamsToAddImutableStarkPrivateKey({
          accessToken,
          recoveryKey,
          publicKey: data.wallets[0].address
        });
        setImmutableModal(true);
      } else {
        dispatch(getLinkedAccounts());
        setModalPinCode(false);
        setRecoverWalletModal(false);
      }
    } else {
      toastError(i18n.t('errorImportingWallet'));
    }
    toastSuccess(i18n.t('addIMXStarkPrivateKeyWalletSuccessfullyImported'));
    setModalPinCode(false);
    setLoading(false);
  };

  const handlerError = async (error: any) => {
    toastError(
      `${i18n.t('errorImportingWallet')} ${formatErrorMessage(
        error,
        'Recover wallet'
      )}`
    );
  };

  return (
    <>
      <Modal
        show={recoverWalletModal}
        size="md"
        popup
        onClose={() => setRecoverWalletModal(false)}
        className="bg-black bg-opacity-50"
      >
        <Modal.Header className="bg-card-bg border-gray-800" />
        <Modal.Body className="bg-card-bg border-gray-800">
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-50 dark:text-white">
              {i18n.t('recoverWallet')}
            </h3>
            <p className="text-gray-400">
              {i18n.t('recoverWalletScreenRecoverYourWallet')}
            </p>
            {error && (
              <p className="text-red-500 text-sm font-medium">{error}</p>
            )}
            <div>
              <div className="mb-2 block">
                <Label
                  className="text-gray-200"
                  htmlFor="recoverWallet"
                  value={i18n.t('recoveryPhraseOrPrivateKey')}
                />
              </div>

              <textarea
                id="recoverWallet"
                required
                onChange={(event) => setRecovery(event.target.value)}
                value={recovery}
                rows={6}
                className="block p-2.5 w-full text-sm text-gray-50 bg-gray-700 rounded-lg border border-gray-600 focus:ring-gray-500 focus:border-gray-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              ></textarea>
            </div>

            <div className="flex justify-center w-1/3">
              <PrimaryButton
                disabled={recovery.length === 0 || loading}
                onClick={onSubmit}
                title={i18n.t('continue')}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="absolute top-1/2 left-1/2 z-[1000] transform -translate-x-1/2 -translate-y-1/2">
        {immutableModal &&
          AddIMXStarkPrivateKey({
            ...paramsToAddImutableStarkPrivateKey,
            closeModal: () => {
              setImmutableModal(false);
              setStarkPK('');
              setModal(ModalStateEnumIMX.INIT);
            },
            modal,
            setModal,
            starkPK,
            setStarkPK,
            starkPKError,
            setStarkPKError,
            loading,
            setLoading,
            wrappedDek,
            setRecoverWalletModal
          })}
      </div>

      {/* PIN-CODE */}
      {modalPinCode && (
        <InsertPinCode
          callback={callback}
          errorHandler={handlerError}
          target={TargetOperation.ADD_WALLET}
          closeModal={() => setModalPinCode(false)}
          text="import a wallet"
        />
      )}
    </>
  );
}
