import { XMarkIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';
import i18n from '../../config/languageInternationalization';

export const CallbackFailureBanner = () => {
  const [isBannerDismiss, setBannerDismiss] = useState(false);
  return (
    <>
      {!isBannerDismiss && (
        <div className="bg-red-500 flex flex-1 flex-row absolute w-3/4  justify-center items-center py-1 px-4 opacity-75">
          <p className="text-center text-base font-normal tracking-wide text-white">
            {i18n.t('callbackFailureBanner')}
          </p>
          <XMarkIcon
            color="white"
            width={20}
            height={20}
            className="bg-black ml-2"
            onClick={() => {
              setBannerDismiss(true);
            }}
          />
        </div>
      )}
    </>
  );
};

export default CallbackFailureBanner;
