import React from 'react';
import { SidebarLayout } from '../../layout/Sidebar';
import { postDocumentData, serverEndpoint } from '../../utils/server';
import InsertPinCode from '../../components/InsertPinCode';
import { useAppSelector } from '../../redux/hooks';
import { Transaction as SolanaTransaction, PublicKey } from '@solana/web3.js';
import { CoinflowWithdraw } from '@coinflowlabs/react';
import { MerchantRoles, SupportedChains } from '../../utils/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'flowbite-react';

export default function CashOut() {
  const { isCountrySupportedForOfframp, role } = useAppSelector(
    (state) => state.user
  );
  const location = useLocation();
  const navigate = useNavigate();

  if (
    location.pathname === '/balances/cashout' &&
    (!isCountrySupportedForOfframp || role !== MerchantRoles.OWNER)
  ) {
    navigate('/balances');
    return null;
  }

  return (
    <div className="flex-col bg-gray-900 min-h-screen">
      <SidebarLayout>
        <CashOutBreadcrumb />
        <CoinflowCashOut />
      </SidebarLayout>
    </div>
  );
}

function CashOutBreadcrumb() {
  const navigate = useNavigate();
  return (
    <Breadcrumb aria-label="Balances" className="dark text-sm font-medium">
      <Breadcrumb.Item onClick={() => navigate('/balances')}>
        <span className="cursor-pointer">Balances</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span className="text-white"> Cashout</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

function CoinflowCashOut({
  chain = SupportedChains.SOLANA
}: {
  chain?: string;
}) {
  const { linkedAccounts, accessToken, idToken } = useAppSelector(
    (state) => state.user
  );
  const [showPinCodeModal, setShowPinCodeModal] = React.useState(false);
  const wrappedDekRef = React.useRef<string | undefined>(undefined);
  const [pinCodeResolver, setPinCodeResolver] = React.useState<
    ((value: string) => void) | null
  >(null);
  const [pinCodeRejecter, setPinCodeRejecter] = React.useState<
    ((reason?: any) => void) | null
  >(null);

  const env = process.env.REACT_APP_COINFLOW_ORIGIN?.includes('sandbox')
    ? 'sandbox'
    : 'prod';
  const wallet = linkedAccounts?.find(
    (account) =>
      account.chains.includes(SupportedChains.SOLANA) &&
      account.isImported === false
  );
  async function sendTransaction(transaction: SolanaTransaction) {
    try {
      if (!wrappedDekRef.current) {
        // Create an instance of the async generator function
        const generator = waitForWrappedDek();

        // Show the pin code modal
        setShowPinCodeModal(true);

        // Wait for the wrappedDek ref to be set in the callback function
        // This will pause the execution of sendTransaction until the promise inside waitForDek is resolved
        // If an error occurs in the InsertPinCode component or the user press the back button, the promise will be rejected and an error will be thrown. The error message will be displayed inside of the Coinflow iframe
        await generator.next();
      }

      // Send the transaction data to the server and return the transaction signature
      const response = await postDocumentData({
        url: `${serverEndpoint}/offramp/coinflow/merchant/transaction`,
        accessToken: accessToken as string,
        idToken: idToken as string,
        data: {
          chain: chain,
          transactionData: transaction,
          wrappedDek: wrappedDekRef.current
        }
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error.message ?? error);
    }
  }

  async function pinCodeCallback(wrappedDek: string) {
    wrappedDekRef.current = wrappedDek;
    if (pinCodeResolver) {
      pinCodeResolver(wrappedDek);
    }
  }

  function pinCodeErrorHandler(error: string) {
    if (pinCodeRejecter) {
      pinCodeRejecter(new Error(error));
    }
  }

  // Create an async generator function that waits for the wrapped DEK to be set and resolves the promise or rejects it if an error occurs
  async function* waitForWrappedDek() {
    while (!wrappedDekRef.current) {
      yield new Promise<string>((resolve, reject) => {
        setPinCodeResolver(() => resolve);
        setPinCodeRejecter(() => reject);
      });
    }
  }

  return (
    <div className="h-screen justify-start items-start rounded-lg pt-8">
      {wallet && (
        <CoinflowWithdraw
          wallet={createCoinflowSolanaWallet(wallet.address, sendTransaction)}
          merchantId={process.env.REACT_APP_COINFLOW_MERCHANT_ID as string}
          env={env}
          blockchain={'solana'}
          connection={{} as any}
          supportsVersionedTransactions={false}
        />
      )}
      {showPinCodeModal && (
        <InsertPinCode
          callback={pinCodeCallback}
          target={'GET_PRIVATE_KEY'}
          closeModal={() => setShowPinCodeModal(false)}
          errorHandler={pinCodeErrorHandler}
          onBackPress={() =>
            pinCodeErrorHandler(
              'Authorization to perform offramp operation cancelled'
            )
          }
          text="authorize an offramp operation"
        />
      )}
    </div>
  );
}

const createCoinflowSolanaWallet = (
  address: string,
  sendTransaction: (transaction: SolanaTransaction) => Promise<string>
) => {
  return {
    publicKey: new PublicKey(address),
    sendTransaction: sendTransaction
  };
};
