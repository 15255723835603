import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from './ThemedComponents/Buttons/button';
import { callFunction, getUserDek } from '../utils/server';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { GreenCheckCircleIcon } from '../components/InsertPinCode';
import { Form, PasswordInput, Title } from './ThemedComponents/Flowbite/Form';
import {
  setIsPhoneAdded,
  setIsPinCodeSetup,
  signOut
} from '../redux/slices/user';
import { logout } from './Sidebar/Account/LogOut';
import { useAuth } from 'react-oidc-context';
import i18n from '../config/languageInternationalization';
import { validatePinCode } from '../utils/validation';
import handlePhoneNumberChange from '../utils/formatePhoneNumber';
import InsertPhone from './InsertPhone';

export enum PinCodeFlow {
  SETUP_START,
  CONFIRM_PIN,
  SETUP_COMPLETE,
  INSERT_PHONE,
  VERIFY_SMS,
  SUCCESS_SMS
}

export const PinCodeSubtitle = ({ text }: { text: string }) => {
  return (
    <h3 className="text-sm font-medium leading-tight tracking-tight md:text-base text-gray-400 text-left w-full break-words">
      {text}
    </h3>
  );
};

export const PinCodePrimaryButton = ({
  text,
  handleOnClick,
  disabled,
  isLoading,
  ...props
}: {
  text: string;
  handleOnClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}) => {
  return (
    <Button
      style={{
        borderRadius: 8,
        width: '100%',
        padding: 8
      }}
      onClick={handleOnClick}
      disabled={disabled}
      isLoading={isLoading}
      {...props}
    >
      {!isLoading && text}
    </Button>
  );
};
export const PinCodeSecondaryButton = ({
  text,
  isDisabled,
  handleOnClick
}: {
  text: string;
  isDisabled?: boolean;
  handleOnClick: () => void;
}) => {
  return (
    <Button
      style={{
        borderRadius: 8,
        width: '100%',
        padding: 8
      }}
      className="bg-gray-600 hover:bg-gray-700"
      onClick={handleOnClick}
      isDisabled={isDisabled}
    >
      {text}
    </Button>
  );
};

function AddPinCodeScreen({
  isRecoveryFlow,
  callback,
  target,
  closeModal,
  errorHandler
}: {
  isRecoveryFlow?: boolean;
  callback?: (wrappedDek: string) => Promise<void>;
  target?: string;
  closeModal?: () => void;
  errorHandler?: (error: string) => void;
}) {
  const [pinCode, setPinCode] = React.useState('');
  const [verifyPin, setVerifyPin] = React.useState('');
  const [error, setError] = React.useState('');
  const [pinCodeFlow, setPinCodeFlow] = React.useState(PinCodeFlow.SETUP_START);
  const [loading, setLoading] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState<any>('');
  const { accessToken, isPinCodeSetup, isPhoneAdded } = useAppSelector(
    (state) => state.user
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { signoutSilent } = useAuth();

  const handleLogout = async () => {
    try {
      dispatch(signOut());
      return logout(signoutSilent);
    } catch (error) {
      alert(i18n.t('somethingWentWrong'));
    }
  };

  useEffect(() => {
    if (isPinCodeSetup && !isRecoveryFlow) navigate('/overview');
  }, []);

  const handleSendPhoneNumber = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const phoneFormatted = handlePhoneNumberChange(phoneNumber);
    setPhoneNumber(phoneFormatted);
    await callFunction({
      url: '/otpgenerator',
      method: 'POST',
      accessToken: accessToken as string,
      data: { phone: phoneFormatted }
    });
    setPinCodeFlow(PinCodeFlow.VERIFY_SMS);
  };

  if (pinCodeFlow === PinCodeFlow.SETUP_START)
    return (
      <Form
        title=""
        style={{ gap: 24 }}
        handleSubmit={(e) => {
          e.preventDefault();
          if (!pinCode || pinCode.length !== 4) return;
          setPinCodeFlow(PinCodeFlow.CONFIRM_PIN);
        }}
      >
        <Title text={i18n.t('addPinCodeTitle')} />
        <PinCodeSubtitle text={i18n.t('addPinCodeSubtitle')} />
        <PasswordInput
          value={pinCode ?? ''}
          onChange={(e) => {
            if (
              e?.target?.value?.length <= 4 &&
              !isNaN(Number(e?.target?.value))
            ) {
              setError('');
              setPinCode(e.target.value);
            }
          }}
        />
        {error && <span className="text-red-600">{error}</span>}
        <PinCodePrimaryButton text="Continue" />
        <PinCodeSecondaryButton
          text={i18n.t('logout')}
          handleOnClick={() => {
            handleLogout();
          }}
        />
      </Form>
    );

  if (pinCodeFlow === PinCodeFlow.CONFIRM_PIN) {
    return (
      <Form
        title={i18n.t('addPinCodeConfirmTitle')}
        handleSubmit={async (e) => {
          try {
            e.preventDefault();
            setLoading(true);
            if (pinCode === verifyPin) {
              if (!accessToken) throw new Error('No access token');

              await callFunction({
                url: '/user/pincode',
                accessToken,
                method: 'POST',
                data: { pinCode }
              });
              setPinCodeFlow(PinCodeFlow.SETUP_COMPLETE);
            } else setError(i18n.t('addPinCodeError'));
          } catch (e: any) {
            setError(e.message);
            setPinCode('');
            setPinCodeFlow(PinCodeFlow.SETUP_START);
          } finally {
            setVerifyPin('');
            setLoading(false);
          }
        }}
      >
        <PinCodeSubtitle text={i18n.t('addPinCodeConfirmSubtitle')} />
        <PasswordInput
          value={verifyPin ?? ''}
          onChange={(e) => {
            if (
              e?.target?.value?.length <= 4 &&
              !isNaN(Number(e.target.value))
            ) {
              setVerifyPin(e.target.value);
            }
          }}
        />

        {error && <h4 className="text-red-600">{error}</h4>}
        <PinCodePrimaryButton
          text={i18n.t('continue')}
          disabled={loading}
          isLoading={loading}
        />
        <PinCodeSecondaryButton
          text={i18n.t('back')}
          handleOnClick={() => {
            setPinCode('');
            setVerifyPin('');
            setError('');
            setPinCodeFlow(PinCodeFlow.SETUP_START);
          }}
        />
      </Form>
    );
  }
  if (pinCodeFlow === PinCodeFlow.SETUP_COMPLETE) {
    return (
      <Form
        style={{ gap: 24 }}
        handleSubmit={async (e) => {
          e.preventDefault();
          setLoading(true);
          try {
            dispatch(setIsPinCodeSetup(true));
            if (!isRecoveryFlow) {
              setPinCodeFlow(PinCodeFlow.INSERT_PHONE);
            } else {
              validatePinCode(pinCode);
              if (accessToken) {
                const wrappedDek = await getUserDek(accessToken as string, {
                  target: target as string,
                  pinCode: pinCode
                });
                if (isPhoneAdded) {
                  closeModal && closeModal();
                  callback && (await callback(wrappedDek));
                } else {
                  closeModal && closeModal();
                }
              }
            }
          } catch (e: any) {
            errorHandler && errorHandler(e?.message || 'An error occurred');
          } finally {
            setPinCode('');
            setLoading(false);
          }
        }}
        title=""
        className="text-left"
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <GreenCheckCircleIcon />
        </div>
        <Title text={i18n.t('congratulations')} />
        <PinCodeSubtitle text={i18n.t('addPinCodeSuccess')} />
        <PinCodePrimaryButton text={i18n.t('continue')} isLoading={loading} />
      </Form>
    );
  }
  if (pinCodeFlow === PinCodeFlow.INSERT_PHONE) {
    return (
      <InsertPhone
        error={error}
        handleSendPhoneNumber={handleSendPhoneNumber}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
        loading={loading}
        navigate={navigate}
      />
    );
  }
  if (pinCodeFlow === PinCodeFlow.VERIFY_SMS) {
    return (
      <Form
        title={i18n.t('confirmSMS')}
        handleSubmit={async (e) => {
          e.preventDefault();
          try {
            setLoading(true);
            const phoneFormatted = handlePhoneNumberChange(phoneNumber);
            await callFunction({
              url: '/optverifier',
              method: 'POST',
              accessToken: accessToken as string,
              data: { phone: phoneFormatted, otpCode: pinCode }
            });
            setPinCodeFlow(PinCodeFlow.SUCCESS_SMS);
            dispatch(setIsPhoneAdded());
          } catch (error: any) {
            setError(error);
          } finally {
            setLoading(false);
          }
        }}
      >
        <PinCodeSubtitle text={i18n.t('insertOtpCodeText')} />
        <PasswordInput
          value={pinCode ?? ''}
          onChange={(e) => {
            e.preventDefault();
            if (
              e?.target?.value?.length <= 6 &&
              !isNaN(Number(e?.target?.value))
            ) {
              setPinCode(e.target.value);
            }
          }}
        />
        <PinCodePrimaryButton
          text={i18n.t('continue')}
          disabled={pinCode.length !== 6 || loading}
          isLoading={loading}
        />
        <PinCodeSecondaryButton
          text={i18n.t('back')}
          handleOnClick={() => {
            setPinCodeFlow(PinCodeFlow.VERIFY_SMS);
          }}
        />
      </Form>
    );
  }

  if (pinCodeFlow === PinCodeFlow.SUCCESS_SMS) {
    return (
      <Form
        style={{ gap: 24 }}
        handleSubmit={(e) => {
          e.preventDefault();
          navigate('/overview');
        }}
        title=""
        className="text-left"
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <GreenCheckCircleIcon />
        </div>
        <Title text={i18n.t('pinCodeInsertedOK')} />
        <PinCodePrimaryButton text={i18n.t('continue')} />
      </Form>
    );
  }
  return <></>;
}

export default AddPinCodeScreen;
