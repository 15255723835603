import React from 'react';
import { Alert } from 'flowbite-react';
import OAuthClientsTable from './oauth-table';
import { Button } from '../../ThemedComponents/Flowbite/Button';
import i18n from '../../../config/languageInternationalization';
import { HiExclamationCircle } from 'react-icons/hi';
import { Icons } from '../../../assets/icons';

export const OAuth = () => (
  <div className="flex flex-col gap-8">
    <div className="flex flex-col items-center gap-5 mt-11">
      <div className="flex flex-col justify-center items-center">
        <div className="text-gray-200 text-2xl font-semibold leading-normal mb-2">
          {i18n.t('oAuth2Title')}
        </div>
        <div className="w-96 text-gray-400 text-center text-sm leading-normal">
          {i18n.t('oAuth2Description')}
        </div>
      </div>
      <AlertInfo />
    </div>
    <OAuthClientsTable />
  </div>
);

function AlertInfo() {
  return (
    <Alert
      additionalContent={
        <Button
          className="h-8"
          onClick={() =>
            window.open(
              'https://docs.sphereone.xyz/docs/client-onboarding#3-create-an-oauth-20-client',
              '_blank'
            )
          }
        >
          <Icons.eyeIcon />
          <span className="pl-2">{i18n.t('seeDocumentation')}</span>
        </Button>
      }
      rounded
      className="dark border border-blue-900 bg-gray-800 text-blue-400 gap-3"
    >
      <div className="pr-4">
        <div className="font-semibold flex items-center">
          <HiExclamationCircle size={16} />
          <h1 className="pl-2">{i18n.t('oAuth2InfoAlertTitle')}</h1>
        </div>
        <div className="pt-1.5 text-sm font-normal  leading-normal">
          <p>1. {i18n.t('oAuth2InfoAlertStep1')}</p>
          <p>2. {i18n.t('oAuth2InfoAlertStep2')}</p>
          <br />
          <p>{i18n.t('oAuth2InfoAlertSeeDocs')}</p>
        </div>
      </div>
    </Alert>
  );
}
