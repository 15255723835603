import React from 'react';
import i18n from '../../../config/languageInternationalization';
import {
  PlusIcon,
  ShoppingCartIcon,
  WalletIcon
} from '@heroicons/react/20/solid';
import { Tooltip } from 'flowbite-react';
import { MerchantRoles } from '../../../utils/types';
import { useAppSelector } from '../../../redux/hooks';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';

export const ButtonsRow = ({
  setRecoverWalletModal
}: {
  setRecoverWalletModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { role } = useAppSelector((state) => state.user);
  return (
    <div className="flex gap-4">
      <PrimaryButton
        className="text-xs"
        title={i18n.t('linkNewAccount')}
        icon={<PlusIcon className="w-4 h-4" />}
        onClick={() => {}}
        disabled={true} // TODO: Enable when feature is ready
      />

      <Tooltip
        content={
          role !== MerchantRoles.OWNER
            ? i18n.t('notOwner')
            : i18n.t('recoverWallet')
        }
      >
        <PrimaryButton
          className="text-xs"
          disabled={role !== MerchantRoles.OWNER}
          title={i18n.t('recoverWallet')}
          icon={<WalletIcon className="w-4 h-4" />}
          onClick={() =>
            role === MerchantRoles.OWNER && setRecoverWalletModal(true)
          }
        />
      </Tooltip>

      <PrimaryButton
        className="text-xs"
        title={i18n.t('buyCrypto')}
        icon={<ShoppingCartIcon className="w-4 h-4" />}
        onClick={() =>
          window.open(process.env.REACT_APP_CHECKOUT_URL, '_blank')
        }
      />
    </div>
  );
};

export default ButtonsRow;
