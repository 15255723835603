import React, { useEffect } from 'react';
import i18n from '../config/languageInternationalization';
import { caseName } from '../utils/caseName';
import { CustomerData } from '../utils/types';
import { PrimaryButton } from './ThemedComponents/Buttons/primary-button';
import useCustomTheme from './ThemedComponents/use-custom-theme';
import { Table } from 'flowbite-react';
import { LoadingTableRow } from './ThemedComponents/Flowbite/loading-table-row';
const CustomersTable = ({
  customerData,
  loading
}: {
  customerData: CustomerData[] | [];
  loading: boolean;
}) => {
  const [currentData, setCurrentData] = React.useState<CustomerData[] | []>();
  const [currentPage, setCurrentPage] = React.useState(0);
  const totalPages = Math.ceil(customerData.length / 10);

  useEffect(() => {
    if (customerData && customerData.length > 10) {
      const paginatedData = customerData?.slice(
        currentPage * 10,
        (currentPage + 1) * 10
      );
      setCurrentData(paginatedData);
    } else {
      setCurrentData(customerData);
    }
  }, [currentPage]);
  const { getTableHeadStyle, getTableBodyStyle, getTextColor } =
    useCustomTheme();

  const tableBodyColor = getTableBodyStyle();
  const tableHeadColor = getTableHeadStyle();
  const textColor = getTextColor();

  return (
    <div
      className="flex flex-1 h-full flex-col rounded-md bg-card-bg"
      style={{
        backgroundColor: tableBodyColor.backgroundColor,
        color: textColor,
        borderRadius: 6
      }}
    >
      <div className="flex-grow overflow-auto rounded-md scrollbar-thin scrollbar-thumb-card-stroke scrollbar-track-card-bg scrollbar-track-rounded-full">
        {!loading ? (
          <>
            <Table>
              <Table.Head>
                <Table.HeadCell style={tableHeadColor}>
                  {i18n.t('customer')}
                </Table.HeadCell>
                <Table.HeadCell style={tableHeadColor}>
                  {i18n.t('dateAdded')}
                </Table.HeadCell>
                <Table.HeadCell style={tableHeadColor}>
                  {i18n.t('lastActive')}
                </Table.HeadCell>
                <Table.HeadCell style={tableHeadColor}>
                  {i18n.t('lastTransaction')}
                </Table.HeadCell>
              </Table.Head>

              <Table.Body className="text-left font-normal text-sm">
                {currentData && currentData.length ? (
                  currentData?.map((customer: CustomerData, i: number) => {
                    const transactionDate = Number(customer.lastTransaction)
                      ? new Date(
                          Number(customer.lastTransaction)
                        ).toDateString()
                      : 'None transactions yet';
                    return (
                      <>
                        <Table.Row
                          key={`${customer.name}-${i}`}
                          className="align-middle items-center"
                          style={{
                            backgroundColor: tableBodyColor.backgroundColor,
                            color: textColor,
                            fontSize: 14,
                            fontWeight: 400,
                            borderColor: textColor
                          }}
                        >
                          <Table.Cell className="px-6">
                            <div className="mr-4 flex justify-start">
                              {customer.profilePicture ? (
                                <img
                                  alt="Img customer"
                                  src={customer.profilePicture}
                                  className="h-10 w-10 rounded-full"
                                />
                              ) : (
                                <div className="text-itemHover border border-itemHover bg-card-bg-no-opacity h-10 w-10 rounded-full flex justify-center items-center">
                                  <span>{caseName(customer.name)}</span>
                                </div>
                              )}
                              <div className="flex flex-col ml-2">
                                <h2 className="">{customer.name}</h2>
                                <h4 className="">{customer.email}</h4>
                              </div>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="px-6 text-white font-sm">
                            {customer.registered
                              ? new Date(
                                  customer.registered._seconds * 1000 +
                                    customer.registered._nanoseconds / 1000000
                                ).toDateString()
                              : 'No Data'}
                          </Table.Cell>
                          <Table.Cell className="px-6 text-white font-sm">
                            {customer.lastLogin
                              ? new Date(
                                  customer.lastLogin._seconds * 1000 +
                                    customer.lastLogin._nanoseconds / 1000000
                                ).toDateString()
                              : 'No Data'}
                          </Table.Cell>
                          <Table.Cell className="py-6 px-6 text-white">
                            {transactionDate}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Cell colSpan={4} className="px-4 pr-8">
                          <div
                            className={`${
                              i >= customerData.length - 1
                                ? 'h-[1px] border-none'
                                : 'h-[1px] border-b border-card-stroke'
                            }`}
                          ></div>
                        </Table.Cell>
                      </>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={4}>
                      <h2 className="text-base font-bold p-8">
                        {i18n.t('youHaveNoCustomers')}
                      </h2>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <div className="flex items-center justify-end pr-4 gap-2 border-t border-gray-700 w-full py-4">
              <div className="flex w-1/4 min-w-[300px]">
                <PrimaryButton
                  title="Prev"
                  loading={loading}
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage == 0}
                />

                <p className="text-white font-semibold px-4">
                  {currentPage + 1}
                </p>
                <PrimaryButton
                  onClick={() => setCurrentPage(currentPage + 1)}
                  title="Next"
                  loading={loading}
                  disabled={totalPages == currentPage + 1}
                />
              </div>
            </div>
          </>
        ) : (
          <LoadingTableRow
            tableBodyColor={tableBodyColor.backgroundColor}
            activeColor={textColor}
            cellsToComplete={4}
          />
        )}
      </div>
    </div>
  );
};

export default CustomersTable;
