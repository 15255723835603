import { Modal } from 'flowbite-react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  XCircleIcon,
  PlusCircleIcon,
  TrashIcon,
  DocumentDuplicateIcon
} from '@heroicons/react/20/solid';
import { useAppSelector } from '../../redux/hooks';
import { LinkedAccountWallet, SupportedChains } from '../../utils/types';
import ModalRow from '../SettingsSection/ModalRow';
import { chainOptions } from '../../utils/chains';
import i18n from '../../config/languageInternationalization';
import { createCharge } from '../../utils/createCharge';
import { toastError, toastSuccess } from '../../utils/toastify';
import { isEmail } from '../../utils/validation';
import axios from 'axios';
import { copyToClipBoard } from '../SettingsSection/apikey-tab/generate-apikey';
import Button from '../ThemedComponents/Buttons/button';
import SelectTokenModal from '../SettingsSection/tokens-tab/select-token-modal';
import { PrimaryButton } from '../ThemedComponents/Buttons/primary-button';
import useCustomTheme from '../ThemedComponents/use-custom-theme';

interface CreateInvoiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  isCharge: boolean;
}

interface Product {
  name: string;
  amount: string;
  quantity: string;
  totalUsd: number;
}

interface Data {
  email: string;
  chain: SupportedChains;
  symbol: string;
  tokenAddress: string;
  tokenName: string;
  items: Product[];
}

interface EmailAndTokenFormProps {
  setProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  setTotalUsd: React.Dispatch<React.SetStateAction<number>>;
  data: {
    email: string;
    chain: SupportedChains;
    symbol: string;
    tokenAddress: string;
    tokenName: string;
    items: Product[];
  };
  setData: React.Dispatch<
    React.SetStateAction<{
      email: string;
      chain: SupportedChains;
      symbol: string;
      tokenAddress: string;
      tokenName: string;
      items: Product[];
    }>
  >;
  supportedTokens: any;
  isCharge: boolean;
}

interface ItemsFormProps {
  products: Product[];
  setProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  addProduct: (product: Product) => void;
  removeProduct: (index: number) => Promise<void>;
  data: Data;
  error: { step1: string | null; step2: string | null };
  setErrors: React.Dispatch<
    React.SetStateAction<{ step1: string | null; step2: string | null }>
  >;
  setTotalUsd: React.Dispatch<React.SetStateAction<number>>;
}

interface PaymentLinkProps {
  paymentLink: string;
}

const findUserWalletByChain = (
  linkedAccounts: LinkedAccountWallet[],
  chain: SupportedChains
) => {
  const userWallet = linkedAccounts.find(
    (wallet) => wallet.chains.includes(chain) && wallet.isImported === false
  );

  return userWallet;
};

const CreateChargeModal: React.FC<CreateInvoiceModalProps> = ({
  isOpen,
  onClose,
  isCharge
}: CreateInvoiceModalProps): React.ReactElement => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const {
    supportedTokens,
    preferredChain,
    preferredToken,
    linkedAccounts,
    apiKey
  } = useAppSelector((state) => state.user);

  const [data, setData] = React.useState<Data>({
    email: '',
    chain: (preferredChain as SupportedChains) ?? SupportedChains.POLYGON,
    symbol: preferredToken?.symbol ?? 'USDC',
    tokenAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    tokenName: preferredToken?.name ?? 'USD Coin',
    items: []
  });

  const [errors, setErrors] = React.useState<{
    step1: null | string;
    step2: null | string;
  }>({
    step1: null,
    step2: null
  });

  const [products, setProducts] = React.useState<Product[]>([
    { name: '', quantity: '', amount: '', totalUsd: 0 }
  ]);

  const [currenStep, setCurrentStep] = React.useState(1);

  const [paymentLink, setPaymentLink] = React.useState('');

  const [totalUsd, setTotalUsd] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const addProduct = ({ name, quantity, amount, totalUsd }: Product) => {
    setData({
      ...data,
      items: [...data.items, { name, quantity, amount, totalUsd }]
    });
  };

  const removeProduct = async (index: number) => {
    const itemValue = data.items[index].totalUsd;
    const newProducts = [...products];
    const newDataProducts = [...data.items];
    const filteredProducts = newProducts.filter((p, i) => i !== index);
    const fileredDataProducts = newDataProducts.filter((p, i) => i !== index);

    setProducts(filteredProducts);
    setData({ ...data, items: fileredDataProducts });
    if (fileredDataProducts.length === 0) {
      setTotalUsd(0);
    } else {
      setTotalUsd((prev) => prev - itemValue);
    }
  };

  const handleSubmit = async () => {
    if (errors.step2 || errors.step1) return;
    try {
      setLoading(true);
      const dateCreated: Date = new Date();
      const expirationDateCharge: Date = new Date();
      expirationDateCharge.setDate(dateCreated.getDate() + 30);

      const result = await createCharge(
        apiKey!,
        false,
        {
          ...data,

          toAddress: findUserWalletByChain(
            linkedAccounts as LinkedAccountWallet[],
            data.chain
          )?.address
        } as any,
        isCharge ? undefined : expirationDateCharge
      );
      setPaymentLink(result);
      setCurrentStep(3);
    } catch (error: any) {
      return toastError(error.message || error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-900 bg-opacity-30">
        <div className="bg-card-bg rounded-lg flex flex-col p-8">
          <h3 className="text-3xl font-semibold text-gray-300">
            {i18n.t('createNewCharge')}
          </h3>
          {currenStep == 1 && (
            <div className="flex flex-col gap-5">
              <EmailAndTokenForm
                data={data}
                setData={setData}
                supportedTokens={supportedTokens}
                isCharge={isCharge}
                setProducts={setProducts}
                setTotalUsd={setTotalUsd}
              />
              {errors.step1 && <p className="text-red-500">{errors.step1}</p>}
              {/* Buttons */}
              <div className="flex w-1/2 gap-4">
                <PrimaryButton
                  title={i18n.t('close')}
                  onClick={onClose}
                  loading={loading}
                  className="text-xs"
                />
                <PrimaryButton
                  title={i18n.t('nextStep')}
                  className="text-xs"
                  onClick={() => {
                    if (data.email == '' && !isCharge) {
                      setErrors({
                        ...errors,
                        step1: 'Email receiver is needed'
                      });
                      return;
                    } else if (!isEmail(data.email) && !isCharge) {
                      setErrors({
                        ...errors,
                        step1: 'Please insert a valid email'
                      });
                      return;
                    } else {
                      setErrors({ ...errors, step1: null });
                    }
                    setCurrentStep(2);
                  }}
                  loading={loading}
                />
              </div>
            </div>
          )}

          {currenStep == 2 && (
            <div className="flex flex-col gap-5">
              <ItemsForm
                data={data}
                products={products}
                setProducts={setProducts}
                addProduct={addProduct}
                removeProduct={removeProduct}
                error={errors}
                setErrors={setErrors}
                setTotalUsd={setTotalUsd}
              />
              <p className="text-white">Total usd: {totalUsd}</p>

              {/* Buttons */}
              <div className="flex w-1/2 gap-4">
                <PrimaryButton
                  title={i18n.t('prevStep')}
                  onClick={() => setCurrentStep(1)}
                  loading={loading}
                  className="text-xs"
                />
                <PrimaryButton
                  title={
                    isCharge
                      ? i18n.t('createNewCharge')
                      : i18n.t('createInvoice')
                  }
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={loading || !data.items.length}
                  className="text-xs"
                />
              </div>
            </div>
          )}

          {currenStep == 3 && (
            <div className="flex flex-col gap-5 mt-5">
              <PaymentLink paymentLink={paymentLink} />
              {!isCharge ? (
                <SendEmailButton email={data.email} />
              ) : (
                <PrimaryButton
                  title={i18n.t('close')}
                  onClick={onClose}
                  loading={loading}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="opacity-30 fixed inset-0 z-40 bg-black" />
    </>
  );
};

const SendEmailButton: React.FC<{ email: string }> = ({ email }) => {
  const sendEmail = () => {
    const subject = encodeURIComponent('SphereOne invoice');
    const body = encodeURIComponent(
      `<!DOCTYPE html> <html>  <head>     <meta name='viewport' content='width=device-width, initial-scale=1.0'>     <meta http-equiv='Content-Type' content='text/html; charset=UTF-8'>     <title>SphereOne new user</title> </head>  <body style='margin:0;padding:0;word-spacing:normal;font-family:sans-serif;'>     <div style='padding:1rem;'>         <img height='30px' src='https://sphereone.xyz/static/media/Sphere-Icon-Logo.6d3df5ef0534521ca994.png'  />         <h1 style='font-size:2rem;'>New waitlist business signup!🚀 <h1>         <p style='font-size:1rem;font-weight:500;'>Hey! {{newWaitlistSignupEmail}} just signup to the waitlist.<p>         <p style='font-size:1rem;font-weight:500;' style='font-size:1rem;font-weight:500;'><a href='mailto:{{newWaitlistSignupEmail}}'>Contact them</a></p>     </div> </body>  </html>`
    );

    return `mailto:${email}?subject=${subject}&body=${body}`;
  };

  return (
    <Button className="mt-4">
      <a href={sendEmail()} rel="noreferrer" target="_blank">
        {i18n.t('sendEmail')}
      </a>
    </Button>
  );
};

const EmailAndTokenForm: React.FC<EmailAndTokenFormProps> = ({
  data,
  setData,
  supportedTokens,
  isCharge,
  setProducts,
  setTotalUsd
}: EmailAndTokenFormProps) => {
  return (
    <div className="flex flex-col">
      {!isCharge && (
        <div className="flex w-full">
          <ModalRow
            label="Email"
            input={true}
            name={'email'}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
        </div>
      )}
      <div className="flex w-full">
        <ModalRow
          label="Chain"
          input={false}
          selectOptions={chainOptions}
          defaultValue={data.chain}
          disabled={false}
          name="chain"
          onChange={(e) => {
            setData({
              ...data,
              chain: e.target.value as SupportedChains,
              items: []
            });
            setProducts([]);
            setTotalUsd(0);
          }}
        />
      </div>
      <div className="flex mt-5 mb-2">
        <span className="text-gray-300">Token:</span>
      </div>

      <SelectTokenModal
        tokens={supportedTokens[data.chain]}
        selectedCurrency={{
          symbol: data.symbol,
          address: data.tokenAddress,
          name: data.tokenName
        }}
        setSelectedCurrency={(e) => {
          setData({
            ...data,
            tokenName: e.name,
            symbol: e.symbol,
            tokenAddress: e.address,
            items: []
          });
          setProducts([]);
          setTotalUsd(0);
        }}
        transferModal={true}
      />
    </div>
  );
};

const ItemsForm: React.FC<ItemsFormProps> = ({
  products,
  setProducts,
  addProduct,
  data,
  removeProduct,
  error,
  setErrors,
  setTotalUsd
}: ItemsFormProps): React.ReactElement => {
  const { getTextColor } = useCustomTheme();

  const [loading, setLoading] = React.useState(false);
  const textColor = getTextColor();

  React.useEffect(() => {
    if (!products.length) {
      setProducts([{ name: '', amount: '', quantity: '', totalUsd: 0 }]);
    }
  }, [products]);

  return (
    <div className="mt-5">
      <div className="flex flex-col">
        <h3 className="text-white">Products</h3>
        {products.map((product, index) => (
          <div key={index} className="flex w-full gap-2 mt-2">
            <input
              type="text"
              className={`h-8 flex bg-card-bg border rounded-md border-card-stroke text-gray-300 px-4 text-sm focus:ring-1 focus:ring-blue-100`}
              placeholder="Quantity"
              onChange={(e) => {
                const newProducts = [...products];
                newProducts[index].amount = e.target.value;
                setProducts(newProducts);
              }}
              value={product.amount}
            />
            <input
              type="text"
              className={`h-8 flex justify-between items-center bg-card-bg border rounded-lg border-card-stroke text-gray-300 py-[10px] px-4 text-sm focus:ring-1 focus:ring-blue-100`}
              placeholder={`Amount in ${data.symbol}`}
              onChange={(e) => {
                const newProducts = [...products];
                newProducts[index].quantity = e.target.value;
                setProducts(newProducts);
              }}
              value={product.quantity}
            />
            <input
              type="text"
              className={`w-30 h-8 flex justify-between items-center bg-card-bg border rounded-lg border-card-stroke text-gray-300 py-[10px] px-4 text-sm focus:ring-1 focus:ring-blue-100`}
              placeholder="Name"
              onChange={(e) => {
                const newProducts = [...products];
                newProducts[index].name = e.target.value;
                setProducts(newProducts);
              }}
              value={product.name}
            />
            {data.items.find((p) => p.name === product.name) ? (
              <>
                <TrashIcon
                  className="text-red-500 h-6 mt-1"
                  onClick={async () => {
                    await removeProduct(index);
                  }}
                />
              </>
            ) : loading && !error.step2 ? (
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-7 w-7 border-b-2 border-blue-600"></div>
              </div>
            ) : (
              <PlusCircleIcon
                color={textColor}
                className="h-6 mt-1 cursor-pointer"
                onClick={async () => {
                  setLoading(true);
                  if (products[index].name === '') {
                    setErrors({
                      ...error,
                      step2: 'Name is required'
                    });
                    return;
                  } else if (!products[index].amount) {
                    setErrors({
                      ...error,
                      step2: 'The amount is required'
                    });
                    return;
                  } else if (Number(products[index].quantity) <= 0) {
                    setErrors({
                      ...error,
                      step2: 'Quantity must be greater than 0'
                    });
                    return;
                  } else if (!Number(products[index].quantity)) {
                    setErrors({
                      ...error,
                      step2: 'Quantity must be a number'
                    });
                    return;
                  } else if (!Number(products[index].amount)) {
                    setErrors({
                      ...error,
                      step2: 'Amount must be a number'
                    });
                    return;
                  } else {
                    setErrors({
                      step1: null,
                      step2: null
                    });
                  }
                  const price = await getTokenPrice(
                    data.chain,
                    data.tokenAddress,
                    Number(products[index].amount),
                    data.symbol
                  );

                  setTotalUsd(
                    (prev) => prev + price * Number(products[index].quantity)
                  );

                  addProduct({
                    ...products[index],
                    totalUsd: price * Number(products[index].quantity)
                  });
                  setProducts([
                    ...products,
                    { name: '', amount: '', quantity: '', totalUsd: 0 }
                  ]);
                  setLoading(false);
                }}
              />
            )}
          </div>
        ))}
      </div>
      {error && <p className="text-red-500 mt-4">{error.step2}</p>}
    </div>
  );
};

export const PaymentLink: React.FC<PaymentLinkProps> = ({
  paymentLink
}: PaymentLinkProps): React.ReactElement => {
  return (
    <div>
      <h3 className="text-white">Payment link</h3>
      <button
        className="cursor-pointer"
        onClick={() => {
          copyToClipBoard(paymentLink, i18n.t('errorCopyPaymentLink'));
          toastSuccess(`${i18n.t('paymentLinkCopiedToast')}`);
        }}
      >
        <label className="cursor-pointer flex justify-between items-center border border-card-stroke rounded-lg text-gray-300 py-3 px-4 text-sm focus:ring-1 focus:ring-blue-100 w-full mt-4">
          <span>{paymentLink}</span>
          <DocumentDuplicateIcon
            width={20}
            height={20}
            className="text-blue-400 cursor-pointer transform hover:scale-125 active:scale-100 ml-2"
          />
        </label>
      </button>
    </div>
  );
};
export async function getTokenPrice(
  chain: SupportedChains,
  tokenAddress: string,
  tokenAmount: number,
  tokenSymbol: string
) {
  const price = await axios.get(
    `${process.env.REACT_APP_REACT_CLOUD_FUNCTIONS_ENDPOINT}/onramp/custom/price?chain=${chain}&tokenAddress=${tokenAddress}&tokenAmount=${tokenAmount}&tokenSymbol=${tokenSymbol}`
  );
  return price.data.data;
}
export default CreateChargeModal;
