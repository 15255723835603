import React from 'react';

import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import termsAcceptedPDF from '../assets/pdf/SphereOneMerchantAgreement.pdf';
import { Document, Page, pdfjs } from 'react-pdf';
import { logout } from './Sidebar/Account/LogOut';
import { signOut } from '../redux/slices/user';
import { useAuth } from 'react-oidc-context';
import i18n from '../config/languageInternationalization';
import { acceptTerms } from '../redux/slices/userThunk';
import { MerchantRoles } from '../utils/types';
import { toastError } from '../utils/toastify';

// this is required for react-pdf to work
// https://github.com/wojtekmaj/react-pdf
// Additional note: https://github.com/wojtekmaj/react-pdf/issues/321#issuecomment-1850918917
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function TermsOfService() {
  const [openModal, setOpenModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const { role } = useAppSelector((state) => state.user);

  enum MerchantAgreementSteps {
    DECIDE,
    ADVERT_LOGOUT,
    OWNER_MUST_ACCEPT
  }
  const [step, setStep] = useState(
    role === MerchantRoles.OWNER
      ? MerchantAgreementSteps.DECIDE
      : MerchantAgreementSteps.OWNER_MUST_ACCEPT
  );
  const [pages, setPages] = useState<number | null>(null);

  const dispatch = useAppDispatch();
  const { signoutSilent } = useAuth();

  return (
    <div className="absolute left-0 top-0 w-full">
      <Modal
        show={openModal}
        onClose={() =>
          role === MerchantRoles.OWNER
            ? setStep(MerchantAgreementSteps.ADVERT_LOGOUT)
            : null
        }
        className="dark bg-black backdrop-blur-sm bg-opacity-60"
      >
        <h2 className="text-white p-4 text-xl bg-gray-900 font-medium">
          {i18n.t('termsOfService')}
        </h2>
        {step === MerchantAgreementSteps.DECIDE && (
          <>
            <Modal.Body className="bg-gray-900">
              <div className="space-y-6 text-white">
                <Document
                  file={termsAcceptedPDF}
                  onLoadSuccess={({ numPages }) => {
                    !pages && setPages(numPages);
                  }}
                >
                  {pages &&
                    // generate an array from the number given (pages.current)
                    Array.from(new Array(pages)).map((_, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                      />
                    ))}
                </Document>
                <div id="list"></div>
              </div>
            </Modal.Body>
            <Modal.Footer className="border-t-0 bg-gray-900 justify-end">
              <Button
                color="gray"
                disabled={loading}
                onClick={() => setStep(MerchantAgreementSteps.ADVERT_LOGOUT)}
              >
                {i18n.t('cancel')}
              </Button>
              <Button
                disabled={loading}
                color="blue"
                onClick={async () => {
                  try {
                    setLoading(true);
                    await dispatch(acceptTerms()).unwrap();
                    setOpenModal(false);
                  } catch (error: any) {
                    toastError(
                      i18n.t('somethingWentWrong') +
                        ' ' +
                        (error.message || error)
                    );
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                {i18n.t('agreeTerms')}
              </Button>
            </Modal.Footer>
          </>
        )}
        {step === MerchantAgreementSteps.ADVERT_LOGOUT && (
          <>
            <Modal.Body className=" bg-gray-900">
              <div className="space-y-6">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  {i18n.t('logoutWarningTerms')}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="border-t-0 bg-gray-900">
              <Button
                color="gray"
                disabled={loading}
                onClick={() => {
                  try {
                    dispatch(signOut());
                    return logout(signoutSilent);
                  } catch (error) {
                    alert(i18n.t('somethingWentWrong'));
                  }
                }}
              >
                {i18n.t('logout')}
              </Button>
              <Button
                color="blue"
                disabled={loading}
                onClick={() => setStep(MerchantAgreementSteps.DECIDE)}
              >
                {i18n.t('goBack')}
              </Button>
            </Modal.Footer>
          </>
        )}
        {step === MerchantAgreementSteps.OWNER_MUST_ACCEPT && (
          <>
            <Modal.Body className=" bg-gray-900">
              <div className="space-y-6">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  {i18n.t('ownerMustAcceptTerms')}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="border-t-0 bg-gray-900">
              <Button
                color="gray"
                disabled={loading}
                onClick={() => {
                  try {
                    dispatch(signOut());
                    return logout(signoutSilent);
                  } catch (error) {
                    alert(i18n.t('somethingWentWrong'));
                  }
                }}
              >
                {i18n.t('logout')}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
}
