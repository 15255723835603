import * as React from 'react';
import { Table } from 'flowbite-react';

import { MerchantMembers, MerchantRoles } from '../../../utils/types';
import { TrashIcon } from '@heroicons/react/20/solid';
import i18n from '../../../config/languageInternationalization';
import { LoadingTableRow } from '../../ThemedComponents/Flowbite/loading-table-row';
import useCustomTheme from '../../ThemedComponents/use-custom-theme';

interface MembersTableProps {
  members?: MerchantMembers[];
  role: MerchantRoles | undefined;
  email: string | undefined;
  loading: boolean;
  handleUpdateMember: ({
    email,
    role,
    event
  }: {
    email: string;
    role: MerchantRoles;
    event: React.ChangeEvent<HTMLSelectElement>;
  }) => Promise<void>;

  handleDeleteMember: (email: string) => Promise<void>;
}

export const formattedDate = (timestamp: number | undefined) => {
  if (!timestamp) return 'N/A';
  const date = new Date(timestamp);

  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  };

  return date.toLocaleDateString('en-US', options);
};

export const MembersTable: React.FC<MembersTableProps> = ({
  members,
  role,
  email,
  loading,
  handleUpdateMember,
  handleDeleteMember
}) => {
  const ref = React.useRef<string>('');

  const { getTableHeadStyle, getTableBodyStyle, getActiveColor, getTextColor } =
    useCustomTheme();

  const tableHeadColor = getTableHeadStyle();
  const activeColor = getActiveColor();
  const tableBodyColor = getTableBodyStyle();
  const textColor = getTextColor();

  return (
    <div
      style={{
        backgroundColor: tableBodyColor.backgroundColor,
        color: activeColor,
        borderRadius: 6
      }}
      className="overflow-x-auto my-8"
    >
      <Table hoverable>
        <Table.Head>
          <Table.HeadCell style={tableHeadColor}>Member</Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}>DATE ADDED</Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}>LAST ACTIVE</Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}>ROLE</Table.HeadCell>
          <Table.HeadCell style={tableHeadColor}>
            <span className="sr-only">Edit</span>
          </Table.HeadCell>
        </Table.Head>
        {/* BODY */}
        <Table.Body className="divide-y">
          {members ? (
            members?.map((e, i) => {
              if (e.email.toLowerCase() == email?.toLowerCase()) return;
              return (
                <Table.Row
                  key={e.email}
                  className="align-middle items-center"
                  style={{
                    backgroundColor: tableBodyColor.backgroundColor,
                    color: textColor,
                    fontSize: 14,
                    fontWeight: 400,
                    borderColor: textColor
                  }}
                >
                  <Table.Cell className="whitespace-nowrap font-medium flex flex-1 rounded-lg">
                    {e.profileImageURL ? (
                      <img
                        src={e.profileImageURL}
                        className="w-10 h-10 rounded-full mr-3"
                      />
                    ) : (
                      <div className="w-10 h-10 rounded-full bg-gray-300 mr-3">
                        <p className="text-center text-gray-500 text-2xl mt-[2px] justify-center align-middle self-center items-center">
                          {e.name ? e.name[0] : e.email[0]}
                        </p>
                      </div>
                    )}
                    <div>
                      <p>{e.name ? e.name : e.email}</p>
                      <p className="text-xs font-light">{e.email}</p>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{formattedDate(e.createdAt)}</Table.Cell>
                  <Table.Cell>{formattedDate(e.lastActive)}</Table.Cell>
                  <Table.Cell>
                    {role === MerchantRoles.MEMBER ||
                    e.role === MerchantRoles.OWNER ? (
                      <span>{e.role}</span>
                    ) : (
                      <>
                        <select
                          style={tableHeadColor}
                          className="rounded-lg"
                          value={e.role}
                          onChange={(event) =>
                            handleUpdateMember({
                              email: e.email,
                              role: event.target.value as MerchantRoles,
                              event
                            })
                          }
                        >
                          <option
                            style={tableHeadColor}
                            value={MerchantRoles.MEMBER}
                          >
                            {MerchantRoles.MEMBER}
                          </option>
                          <option
                            style={tableHeadColor}
                            value={MerchantRoles.ADMIN}
                          >
                            {MerchantRoles.ADMIN}
                          </option>
                        </select>
                      </>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {role === MerchantRoles.MEMBER ||
                    e.role === MerchantRoles.OWNER ? (
                      <></>
                    ) : (
                      <button
                        className="text-gray-500 hover:text-gray-900 dark:text-white dark:hover:text-gray-100"
                        onClick={() => {
                          const confirmed = confirm(
                            i18n.t('deleteMemberAlert', { email: e.email })
                          );
                          if (!confirmed) return;
                          ref.current = e.email;
                          handleDeleteMember(ref.current);
                        }}
                      >
                        <TrashIcon
                          className="w-5 h-5 transform hover:scale-125 active:scale-100"
                          color={tableHeadColor.backgroundColor}
                        />
                      </button>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <LoadingTableRow
              tableBodyColor={tableBodyColor.backgroundColor}
              activeColor={activeColor}
              cellsToComplete={4}
            />
          )}
        </Table.Body>
      </Table>
    </div>
  );
};
