import React from 'react';
import i18n from '../../config/languageInternationalization';
import { Balance } from '../../utils/types';

export function BalanceTokenCard({
  symbol,
  iconSrc,
  amount,
  isLastItem,
  price
}: {
  symbol: string;
  iconSrc?: string;
  amount: string;
  isLastItem: boolean;
  price: string;
}) {
  return (
    <div
      className={`
    ${!isLastItem && 'border-b-card-stroke border-b'}
    flex justify-between items-center py-5  px-8
  `}
    >
      <div className="flex flex-col md:flex-row items-center">
        <img
          src={iconSrc}
          alt="token symbol"
          className="w-6 h-6 md:w-12 md:h-12 rounded-full"
        />
        <p className="ml-4 text-base font-bold text-white">{symbol}</p>
      </div>
      <div className="flex-col">
        <p className="text-xs md:text-base text-right text-white">
          {amount} {symbol}
        </p>
        <p className="text-base text-right text-gray-200">${price}</p>
      </div>
    </div>
  );
}

export default function BalancesTable({
  balanceTokens
}: {
  balanceTokens: Balance[] | undefined;
}) {
  const [componentHeight, setComponentHeight] = React.useState(
    window.innerHeight
  );

  React.useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="flex flex-1 flex-col rounded-b-md bg-card-bg"
      style={{ maxHeight: componentHeight * 0.6 }}
    >
      <div className="flex flex-1 h-full flex-col flex-grow overflow-y-auto bg-transparent rounded-2xl scrollbar-thin scrollbar-thumb-card-stroke scrollbar-track-card-bg scrollbar-track-rounded-full">
        {balanceTokens?.length ? (
          <div>
            {balanceTokens.map((t: Balance, i: any) => (
              <BalanceTokenCard
                key={i}
                amount={t.amount}
                price={t.price}
                symbol={t.tokenMetadata.symbol}
                iconSrc={t.tokenMetadata.logoURI}
                isLastItem={i === balanceTokens.length - 1}
              />
            ))}
          </div>
        ) : (
          <p className="p-8 text-white">{i18n.t('noResults')}</p>
        )}
      </div>
    </div>
  );
}
