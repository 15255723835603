import { getDocumentData, merchantDataEndpoint } from './server';

export const getCustomers = async (
  uid: string,
  accessToken: string,
  idToken: string
) => {
  const response = await getDocumentData({
    url: `${merchantDataEndpoint}/analytics/customers`,
    accessToken,
    idToken
  });

  return response.data;
};
