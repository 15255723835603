import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';

import Balances from './views/Balances/Balances';
import Payments from './views/Payments';
import Home from './views/Home';
import Customers from './views/Customers';
import NotFound from './components/NotFound';
import { LoginScreen } from './views/Onboarding/LoginScreen';
import ProtectedRoutes from './routes/ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CreateCharge } from './components/CreateCharge';
import SetThemeSection from './views/SetThemeSection';
import SettingsScreen from './components/SettingsSection';
import { AddEmailScreen } from './components/AddEmail';
import AddPinCodeScreen from './components/AddPinCode';
import { logout } from './components/Sidebar/Account/LogOut';
import { useAuth } from 'react-oidc-context';
import CashOut from './views/Balances/Cashout';
import TermsOfService from './components/TermsModal';
import { useAppSelector } from './redux/hooks';

function App() {
  const { signoutSilent } = useAuth();

  axios.interceptors.response.use(
    (r) => r,
    (error) => {
      if (error.response.status === 401) {
        logout(signoutSilent);
      }
      return Promise.reject(error);
    }
  );

  const { termsAccepted } = useAppSelector((state) => state.user);
  const { user } = useAuth();
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/overview" element={<Home />} />
          <Route path="/balances" element={<Balances />}>
            <Route path="cashout" element={<CashOut />} />
          </Route>
          <Route path="/payments" element={<Payments />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/settings" element={<SettingsScreen />} />
          <Route path="/createCharge" element={<CreateCharge />} />
          <Route path="/theme" element={<SetThemeSection />} />
        </Route>
        <Route path="/user/pincode" element={<AddPinCodeScreen />} />
        <Route path="/" element={<LoginScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/addEmail" element={<AddEmailScreen />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      {user && termsAccepted === false && <TermsOfService />}
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
