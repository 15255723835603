import React, { useState } from 'react';
import i18n from '../../../config/languageInternationalization';
import ContractsTable from './contracts-table';
import { useAppSelector } from '../../../redux/hooks';
import { MerchantRoles } from '../../../utils/types';
import { PrimaryButton } from '../../ThemedComponents/Buttons/primary-button';
import { PlusIcon } from '@heroicons/react/20/solid';
import { toastError } from '../../../utils/toastify';

export const Contracts = () => {
  const { role, isVerified } = useAppSelector((state) => state.user);
  const [openFormModal, setOpenFormModal] = useState(false);
  return (
    <div>
      <div className="flex justify-end mb-8">
        <div className="flex items-center justify-center">
          {role === MerchantRoles.OWNER && (
            <PrimaryButton
              icon={<PlusIcon className="flex w-4 h-4" />}
              onClick={() => {
                if (isVerified) {
                  setOpenFormModal(true);
                } else {
                  toastError(i18n.t('notVerifiedAlert'));
                }
              }}
              title={i18n.t('addContract')}
            />
          )}
        </div>
      </div>

      <div className="overflow-auto">
        <ContractsTable
          setOpenFormModal={setOpenFormModal}
          openFormModal={openFormModal}
        />
      </div>
    </div>
  );
};
