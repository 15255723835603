import React from 'react';
import images from '../../assets/images/brand/index';
import DisplayCards from '../../components/Onboarding/Features/DisplayCards';
import LoginBox from '../../components/Onboarding/LoginBox/LoginBox';
import { useWindowSize } from '../../hooks/useWindowsSize';

export const LoginScreen = () => {
  const [width, height] = useWindowSize();
  const isSmallHeight = height < 800 || (width && width < 800);

  return (
    <section
      className={`h-screen flex flex-1 flex-col px-6 md:px-16 lg:px-28 ${
        isSmallHeight ? 'py-10' : 'py-20'
      }`}
    >
      {/* LOGO CONTAINER */}
      <div className={` ${isSmallHeight ? 'mb-12' : 'mb-24'}`}>
        <img src={images.SphereOneLogoFull} className="w-40" />
      </div>

      {/* CONTENT CONTAINER */}
      <div className="flex flex-1 flex-col">
        {/* LEFT SIDE CARDS */}
        <div className="flex flex-1 flex-col">
          <DisplayCards />
        </div>

        {/* RIGHT SIDE BOX */}
        <div className="flex flex-1 w-full justify-center mb-20">
          <LoginBox />
        </div>
      </div>
    </section>
  );
};
