import React from 'react';
import i18n from '../config/languageInternationalization';

import { SidebarLayout } from '../layout/Sidebar';
import CreateChargeModal from '../components/invoices/CreateChargeModal';
import PaymentsTable from '../components/PaymentsTable';
import { PrimaryButton } from '../components/ThemedComponents/Buttons/primary-button';

export default function Payments() {
  const [createChargeModal, setCreateChargeModal] = React.useState(false);
  const [isCharge, setIsCharge] = React.useState(true);

  const openCreateChargeModal = () => {
    setIsCharge(true);
    setCreateChargeModal(true);
  };

  return (
    <SidebarLayout>
      <>
        <div className="flex items-center justify-between mb-4 min-h-[40px]">
          <h1 className="text-gray-400 font-semibold text-2xl">
            {i18n.t('payments')}
          </h1>
          <div className="flex min-w-[150px]">
            {/* This button will be disabled until we found a way to send the email with the invoice */}
            {/* <Button
              onClick={() => {
                setIsCharge(false);
                setCreateChargeModal(true);
              }}
              className="h-10 mr-2 bg-gray-800 rounded-lg border border-gray-600 text-gray-400 hover:bg-gray-900 hover:text-gray-50"
            >
              {i18n.t('createInvoice')}
            </Button> */}
            <PrimaryButton
              onClick={openCreateChargeModal}
              title={i18n.t('createNewCharge')}
              loading={createChargeModal}
              className="text-sm"
              autoFocus={true}
            />
          </div>
        </div>

        <>
          <PaymentsTable />
        </>
        {createChargeModal && (
          <CreateChargeModal
            isOpen={createChargeModal}
            onClose={() => setCreateChargeModal(false)}
            isCharge={isCharge}
          />
        )}
      </>
    </SidebarLayout>
  );
}
