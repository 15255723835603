import React, { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { XMarkIcon } from '@heroicons/react/20/solid';

import { useAppSelector } from '../redux/hooks';
import { callFunction, getUserDek } from '../utils/server';
import { Form, PasswordInput, Title } from './ThemedComponents/Flowbite/Form';
import {
  PinCodePrimaryButton,
  PinCodeSecondaryButton,
  PinCodeSubtitle
} from './AddPinCode';
import { validatePinCode } from '../utils/validation';
import i18n from '../config/languageInternationalization';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import handlePhoneNumberChange from '../utils/formatePhoneNumber';
import RecoveryFlowPinCode from './RecoveryFlowPinCode';
import { MerchantRoles } from '../utils/types';

export enum PinCodeState {
  INSERT,
  SUCCESS,
  RECOVERY,
  INSERT_CODE
}

export const GreenCheckCircleIcon = () => {
  return <CheckCircleIcon style={{ width: 75, height: 75, color: 'green' }} />;
};

function InsertPinCode({
  callback,
  closeModal,
  target,
  errorHandler,
  onBackPress,
  text,
  warning,
  title
}: {
  callback: (wrappedDek: string) => Promise<void>;
  closeModal: () => void;
  target: string;
  errorHandler?: (error: string) => void;
  onBackPress?: () => void;
  text?: string;
  warning?: string;
  title?: string;
}) {
  const [pinCode, setPinCode] = React.useState('');
  const [pinCodeError, setPinCodeError] = React.useState('');
  const [state, setState] = React.useState(PinCodeState.INSERT);
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [loading, setLoading] = React.useState(false);
  const { accessToken, role } = useAppSelector((state) => state.user);

  const [handleWarning, setHandleWarning] = React.useState(warning);

  const handleSendPhoneNumber = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const phoneFormatted = handlePhoneNumberChange(phoneNumber);
    setPhoneNumber(phoneFormatted);
    await callFunction({
      url: '/otpgenerator',
      method: 'POST',
      accessToken: accessToken as string,
      data: { phone: phoneFormatted }
    });
    setState(PinCodeState.INSERT_CODE);
  };

  if (state === PinCodeState.INSERT) {
    return (
      <Form
        title={title ? title : i18n.t('pinCodeEnter')}
        handleSubmit={async (e) => {
          e.preventDefault();
          try {
            if (!pinCode || !accessToken) return;
            setLoading(true);
            setPinCodeError('');
            validatePinCode(pinCode);
            const wrappedDek = await getUserDek(accessToken, {
              target,
              pinCode: pinCode
            });
            await callback(wrappedDek);
            setState(PinCodeState.SUCCESS);
          } catch (e: any) {
            if (e?.message?.includes('pin code')) {
              return setPinCodeError(i18n.t('pinCodeIncorrect'));
            } else {
              closeModal();
              errorHandler && errorHandler(e?.message || 'An error occurred');
            }
          } finally {
            setLoading(false);
          }
        }}
      >
        <PinCodeSubtitle
          text={
            text
              ? i18n.t('pinCodeTarget', { text })
              : i18n.t('pinCodeTarget', { text: 'send money' })
          }
        />
        {handleWarning && (
          <div className="justify-center items-stretch border bg-orange-300 bg-opacity-10 flex flex-col p-4 rounded-md border-solid border-orange-300 border-opacity-20">
            <div className="items-center flex gap-2">
              <CheckCircleIcon className="text-orange-300 w-5 h-5" />
              <div className="text-orange-300 text-base font-semibold leading-6 self-stretch grow shrink basis-auto">
                {i18n.t('warning')}
              </div>
              <button onClick={() => setHandleWarning('')}>
                <XMarkIcon className="text-orange-300 w-5 h-5" />
              </button>
            </div>
            <div className="text-orange-300 text-sm leading-5 mt-1.5">
              {warning}
            </div>
          </div>
        )}
        <PasswordInput
          value={pinCode ?? ''}
          onChange={(e) => {
            if (
              e?.target?.value?.length <= 4 &&
              !isNaN(Number(e?.target?.value))
            ) {
              setPinCode(e.target.value);
            }
          }}
        />
        {pinCodeError && <span className="text-red-600">{pinCodeError}</span>}
        <PinCodePrimaryButton
          text={i18n.t('continue')}
          disabled={pinCode.length !== 4 || loading}
          isLoading={loading}
        />
        <PinCodeSecondaryButton
          text={i18n.t('back')}
          handleOnClick={() => {
            closeModal();
            onBackPress && onBackPress();
          }}
        />
        <button
          className="text-sm text-blue-400 hover:text-blue-600"
          onClick={() => {
            setPinCodeError('');
            if (role == MerchantRoles.OWNER) {
              setState(PinCodeState.RECOVERY);
            } else {
              setPinCodeError(i18n.t('notSufficientPermissionForgotPin'));
            }
          }}
        >
          {i18n.t('forgotPinCodeAsk')}
        </button>
      </Form>
    );
  }

  if (state === PinCodeState.RECOVERY) {
    return (
      <Form
        handleSubmit={(e) => handleSendPhoneNumber(e)}
        title={i18n.t('insertPhone')}
      >
        <h2 className="text-gray-500">{i18n.t('recoverySubtitle')}</h2>
        <div className="flex flex-col">
          <PhoneInput
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={(value) => {
              setPhoneNumber(value);
            }}
          />
          {pinCodeError && (
            <span className="text-red-600 text-center">{pinCodeError}</span>
          )}
          <div className="mt-4">
            <PinCodePrimaryButton
              text={i18n.t('continue')}
              disabled={!phoneNumber || loading || pinCodeError.length > 0}
              isLoading={loading}
            />
          </div>
          <div className="mt-2">
            <PinCodeSecondaryButton
              text={i18n.t('back')}
              handleOnClick={() => setState(PinCodeState.INSERT)}
            />
          </div>
        </div>
      </Form>
    );
  }

  if (state === PinCodeState.INSERT_CODE) {
    return (
      <RecoveryFlowPinCode
        phoneNumber={phoneNumber}
        callback={callback}
        target={target}
        closeModal={closeModal}
        errorHandler={errorHandler}
      />
    );
  }

  if (state === PinCodeState.SUCCESS) {
    return (
      <Form
        style={{ gap: 24 }}
        handleSubmit={(e) => {
          e.preventDefault();
          closeModal();
        }}
        title=""
        className="text-left"
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <GreenCheckCircleIcon />
        </div>
        <Title text={i18n.t('pinCodeInsertedOK')} />
        <PinCodePrimaryButton text={i18n.t('continue')} />
      </Form>
    );
  } else return <></>;
}

export default InsertPinCode;
